import { NgModule } from '@angular/core';

import { CookiesManagementComponent } from './cookies-management.component';
import { CommonModule } from '@angular/common';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { ReactiveFormsModule } from '@angular/forms';
import { WebAsyncButtonModule } from '../../../async-button';
import { WebFormInputSwitchModule } from '../../../../form-inputs';

const UI_COMPONENTS = [WebAsyncButtonModule, WebFormInputSwitchModule];

@NgModule({
  imports: [CommonModule, I18nModule.forChild(), ReactiveFormsModule, PipesModule, UI_COMPONENTS],
  declarations: [CookiesManagementComponent],
  exports: [CookiesManagementComponent],
  providers: [],
})
export class CookiesManagementModule {}
