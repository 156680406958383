<div class="wrapper" *ngIf="activatedAccount">
  <div class="header-wrapper">
    <h1>{{ 'ACCOUNT_ACTIVATION_title' | translate | uppercase }}</h1>
    <div class="image-wrapper">
      <img src="assets/images/exito.svg" />
    </div>
  </div>

  <div class="footer-wrapper">
    <p>{{ 'ACCOUNT_ACTIVATION_message' | translate }}</p>
    <button mat-flat-button color="primary" (click)="goToLogin()">{{ 'ACCOUNT_ACTIVATION_button' | translate }}</button>
  </div>
</div>

<div class="wrapper" *ngIf="!activatedAccount">
  <div class="header-no-activation-wrapper">
    <div class="image-no-activation-wrapper">
      <img src="assets/images/activation-last-step.svg" />
    </div>
    <h1>{{ 'ACCOUNT_ACTIVATION_noActivationTitle' | translate | uppercase }}</h1>
  </div>

  <div class="footer-no-activation-wrapper" *ngIf="form">
    <p>{{ 'ACCOUNT_ACTIVATION_noActivationMessage' | translate }}</p>
    <div class="row justify-content-center input-container">
      <ui-form-input-text class="col-10" [label]="'LOGIN_userName' | translate" [form]="form" [controlName]="'userName'"></ui-form-input-text>
    </div>

    <ui-async-button
      componentId="AT_logging_in"
      [inputState]="stateResendButton$"
      [rounded]="true"
      [type]="'submit'"
      [asyncButton]="true"
      [buttonClassByType]="'primary'"
      [size]="'lg'"
      [label]="'ACCOUNT_ACTIVATION_resend' | translate"
      [loadingLabel]="'ACCOUNT_ACTIVATION_resending' | translate"
      [disabled]="!form?.valid"
      (clickEvent)="resendActivation()"
    ></ui-async-button>

    <div class="link-wrapper">
      <a (click)="goToLogin()">{{ 'ACCOUNT_ACTIVATION_goToLogin' | translate }}</a>
    </div>
  </div>
</div>
