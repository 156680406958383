<form id="login_form" class="example-container" [formGroup]="form" (ngSubmit)="login()">
  <a class="logo-container" [routerLink]="['/']">
    <img src="assets/images/tasafy-logo-vert-negro.png" />
  </a>

  <div class="form-container">
    <span>
      <h1>{{ 'LOGIN_signin' | translate }}</h1>
    </span>

    <div class="central-container">
      <div class="input-container">
        <ui-form-input-text
          componentId="AT_loginUserName"
          [label]="'LOGIN_userName' | translate"
          [form]="form"
          [controlName]="'userName'"
          [ngClass]="{ 'hide-validation': hideValidation }"
        ></ui-form-input-text>
      </div>

      <div class="input-container">
        <ui-form-input-password
          componentId="AT_loginPassword"
          [label]="'LOGIN_password' | translate"
          [form]="form"
          [controlName]="'password'"
          [ngClass]="{ 'hide-validation': hideValidation }"
        ></ui-form-input-password>
      </div>

      <div class="forgot-password-container">
        <a id="AT_forgotPassword" [routerLink]="['../require-password-change']">{{ 'LOGIN_forgotPassword' | translate }}</a>
      </div>
    </div>

    <div class="login-input-button">
      <ui-async-button
        componentId="AT_logging_in"
        [inputState]="stateLoginBtn"
        [rounded]="true"
        [type]="'submit'"
        [asyncButton]="true"
        [buttonClassByType]="'primary'"
        [size]="'lg'"
        [label]="'log_in' | translate"
        [loadingLabel]="'logging_in' | translate"
        (clickEvent)="login()"
      ></ui-async-button>
    </div>
  </div>
</form>
