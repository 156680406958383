import { Component, OnInit } from '@angular/core';
import { ChildActivationStart, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { PATHS } from '@foxeet/n-core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tas-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _endLoadingApp = new Subject<boolean>();
  reloading = false;
  private childsToExclude = ['buildingElementTempId'];

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof ChildActivationStart) {
        if (Object.keys(event.snapshot.params).length > 0) {
          Object.keys(event.snapshot.params).forEach((param) => {
            if (!this.childsToExclude.includes(param)) {
              const paramValue = event.snapshot.params[param];
              const valueParsed: number = parseInt(paramValue);
              isNaN(valueParsed) ? this.router.navigateByUrl(PATHS.PAGE_NOT_FOUND) : '';
            }
          });
        }
      }
    });
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this._endLoadingApp)).subscribe((event) => {
      this._navigationInterceptor(event as RouterEvent);
    });
  }

  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      this.isLoading.next(false);
      this._endLoadingApp.complete();
    }
  }
}
