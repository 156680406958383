export const ptLang = {
  languageKey: 'pt',
  translates: {
    MENU_USERS_processManagers: 'Utilizadores gestores',
    MENU_USERS_techManagers: 'Utilizadores validadores',
    PROCESS_MANAGER_USERS: 'Utilizadores gestores',
    PROCESS_MANAGER_USER: 'Utilizador gestor',
    PROCESS_MANAGER_USERS_add_process_manager_user: 'Adicionar gestor',
    TECHNICAL_MANAGER_USERS: 'Utilizadores validadores',
    TECHNICAL_MANAGER_USER: 'Utilizador validador',
    APPRAISALS_processManager: 'Gestor',
    APPRAISALS_techManager: 'Validador',
    APPRAISALS_externalTech: 'Profissional',
    APPRAISALS_saveInfo: '*Ao clicar para salvar, o arquivo será cobrado automaticamente.',
    PROFESSIONALS_NEW_add_professional: 'Adicionar profissional',
    academy: 'Academy',
    'process-manager-user': 'Utilizador gestor',
    'technical-manager-user': 'Utilizador validador',
    process_managers: 'Gestores',
    technical_managers: 'Validadores',
    processDept: 'Gestor',
    externalTech: 'Profissional',
    technicalDept: 'Validador',
    TECHNICAL_MANAGER_USERS_add_technical_manager_user: 'Adicionar validador',
    relatedAppraisals: 'Avaliações relacionadas (não anuladas)',
    ACADEMY_WELCOME_title: 'Bem-vindo à Tasafy Academy!',
    ACADEMY_WELCOME_subtitle: 'Em apenas 4 passos tornar-se-á num avaliador profissional',
    ACADEMY_WELCOME_columnTitle1: 'Curso de iniciação',
    ACADEMY_WELCOME_columnDescription1: 'Entre na secção <b>Academy</b> disponível no menu superior e faça o curso inicial online para aprender os conceitos básicos da Tasafy',
    ACADEMY_WELCOME_columnTitle2: 'Caso prático',
    ACADEMY_WELCOME_columnDescription2: 'No curso encontrará um botão de <b>Iniciar teste</b> que o levará ao seu primeiro relatório onde poderá aplicar todos os conhecimentos que aprendeu',
    ACADEMY_WELCOME_columnTitle3: 'Correção',
    ACADEMY_WELCOME_columnDescription3: 'Quando terminar o seu relatório, será encaminhado para o passo <b>Entregar ao cliente</b>, onde um <b>especialista</b> irá rever o seu exercício deixando os seus comentários na secção <b>Mural</b>',
    ACADEMY_WELCOME_columnTitle4: 'Qualificação',
    ACADEMY_WELCOME_columnDescription4: 'Pode corrigir a sua avaliação de acordo com os comentários que recebeu e convencer o nosso especialista a obter a sua certificação Tasafy como avaliador imobiliário.',
    ACADEMY_WELCOME_startButton: 'Começar!',
    ACADEMY_COURSES_progress: 'Em curso',
    ACADEMY_COURSES_pending: 'Teste não iniciado',
    ACADEMY_CURSES_title: 'Os meus cursos',
    ACADEMY_COURSES_cancelled: 'Cancelado',
    ACADEMY_COURSES_finished: 'Concluído',
    ACADEMY_COURSES_start_test: 'Iniciar o teste',
    ACADEMY_COURSES_continue_test: 'Continuar o teste',
    ACADEMY_COURSES_go_to_detail: 'Detalhe do teste',
    ACCOUNT_ACTIVATION_title: 'obrigado',
    ACCOUNT_ACTIVATION_message: 'A sua conta não foi ativada.',
    ACCOUNT_ACTIVATION_button: 'Ir para a Tasafy',
    WELCOME_STEP1_title: 'Olá!',
    WELCOME_STEP1_paragraph1: 'Parabéns! Já faz parte do universo <b>Tasafy</b>, o software para gerir e executar avaliações.',
    WELCOME_STEP1_paragraph2: 'Tudo o que precisa num único espaço concebido para ser eficiente e eficaz.',
    WELCOME_STEP1_paragraph3: '<b>O que acontece agora?</b>',
    WELCOME_STEP2_section1_paragraph1: 'Entre na secção <b>Academy</b> disponível no menu superior.',
    WELCOME_STEP2_section1_paragraph2: 'Nesta secção encontrará todas as informações necessárias para realizar o <b>curso inicial</b> online dividido em dois passos: conceitos básicos da ferramenta e execução da avaliação. Converta-se num especialista!',
    WELCOME_STEP2_section2_paragraph1: 'Se preferir começar a trabalhar diretamente, crie o seu primeiro relatório na secção das <b>Avaliações</b>. No menu lateral encontrará o botão <b>Adicionar</b>.',
    WELCOME_STEP3_paragraph1: 'Além disso, pode sempre aceder ao manual Tasafy no menu superior direito <b>W</b>, onde encontrará todas as informações necessárias para utilizar a ferramenta.',
    WELCOME_STEP3_paragraph2: 'Lembre-se que na secção das definições do seu perfil pode rever o seu plano e ajustá-lo às suas necessidades.',
    WELCOME_STEP3_button1: 'Comecemos!',
    WELCOME_STEP4_title: 'Você tem alguma dúvida?',
    WELCOME_STEP4_paragraph1: '<b>TAFY</b> está aqui para orientá-lo na conclusão dos arquivos.',
    WELCOME_STEP4_paragraph2: 'Você pode encontrá-lo no topo de cada detalhe de revisão.',
    PLANS_BASIC_CHANGE_title1: 'Continuamos a trabalhar para melhorar a sua experiência Tasafy',
    PPLANS_BASIC_CHANGE_title2: 'Melhoramos sua licença!',
    PLANS_BASIC_CHANGE_title3: 'Ou suba de nível e torne-se PREMIUM',
    PLANS_BASIC_CHANGE_paragraph1: 'Apresentamos as últimas novidades em nossos planos de assinatura, onde introduzimos novas funcionalidades e vantagens que melhoram o seu trabalho como avaliador.',
    PLANS_BASIC_CHANGE_paragraph2: 'Para que você possa desfrutar de uma <b>melhor experiência</b> com nossa ferramenta, <b>transformamos</b> seu atual plano BASIC, agregando <b>novas funcionalidades</b> e vantagens, com o nome <b>COMFORT</b>.',
    PLANS_BASIC_CHANGE_paragraph3: 'Se você não o conhece, o que está esperando? <bt>Inclui acesso a testemunhas do <b>Idealista</b>.',
    PLANS_BASIC_CHANGE_paragraphButton1: 'DESCUBRA O PLANO DE CONFORTO',
    PLANS_BASIC_CHANGE_paragraphButton2: 'DESCUBRA O PLANO DE PREMIUM',
    PLANS_EXPERT_CHANGE_title1: 'Seu plano atual agora é CONFORTO!',
    PLANS_EXPERT_CHANGE_title2: 'Ou suba de nível e torne-se PREMIUM',
    PLANS_EXPERT_CHANGE_title3: 'ÚLTIMAS NOTÍCIAS',
    PLANS_EXPERT_CHANGE_subTitle1: 'PLAN COMFORT',
    PLANS_EXPERT_CHANGE_paragraph1: 'Não te preocupes!',
    PLANS_EXPERT_CHANGE_paragraph2: 'É apenas uma mudança no nome do seu plano de assinatura, você continuará mantendo as mesmas funcionalidades pelo mesmo preço de até agora, sob o nome:',
    PLANS_EXPERT_CHANGE_paragraph3: 'Se você não o conhece, o que está esperando?',
    PLANS_EXPERT_CHANGE_paragraph4: 'Inclui acesso a testemunhas <b>Idealista</b>.',
    PLANS_CHANGE_paragraphButton2: '¡Sim, gostaria de ver os novos planos!',
    PLANS_INFO_CURRENT_PLAN: 'Seu plano atual é...',
    PLANS_INFO_CURRENT_PLAN_button: 'Continuar',
    PLANS_NOT_AVAILABLE_title: 'Seu plano não está mais disponível',
    PLANS_NOT_AVAILABLE_paragraph: 'Aproveite para encontrar o plano que melhor se adapta às suas necessidades',
    PLANS_NOT_AVAILABLE_button: 'Revise novos planos',
    PLANS_BASIC_NOT_AVAILABLE_paragraph: 'O plano que você estava usando não está mais disponível',
    PLANS_BASIC_NOT_AVAILABLE_title: '¡Escolha seu novo plano!',
    PLANS_NOT_AVAILABLE: 'Para manter a sua conta Tasafy ativa, selecione um novo plano',
    PLANS_Basic_info1: 'Selecione já o seu novo plano Tasafy!',
    PLANS_CHOOSE_NEW_PLAN: 'Selecione seu novo plano e comece a precificar!',
    PLANS_COMFORT_info1: '<p><b>Geração automática de relatórios</b> e certificados PDF ajustados às normas regulatórias (ECO, RICS OU IVS)</p>',
    PLANS_COMFORT_info2: '<p><b>Todos os métodos de avaliação: </b>acabados, inacabados, terrenos e participações económicas.</p>',
    PLANS_COMFORT_info3: '<p><b>Coleta de dados durante a visita</b>, a partir de qualquer dispositivo, <b>sincronizado com relatório de avaliação</b></p>',
    PLANS_COMFORT_info4: '<p><b>Painel de Controle</b> para monitoramento em tempo real do seu trabalho</p>',
    PLANS_COMFORT_info5: '<p><b>Geração de relatório de dados oficiais (Relatórios para o Banco de Espanha)</b></p>',
    PLANS_COMFORT_info6: '<p><b>Personalização do relatório:</b> logotipo, rodapé e assinaturas</p>',
    PLANS_COMFORT_info7: '<p>Bancos de dados com <b>informações automáticas sobre localização, ambiente e mercado</b></p>',
    PLANS_COMFORT_info8: '<p>Armazenamento de <b>banco de dados de testemunhas próprio</b>.</p>',
    PLANS_COMFORT_info9: '<p>Agenda de trabalho para <b>planejamento de visitas e entregas</b>.</p>',
    PLANS_COMFORT_info10: '<p><b>Histórico de versões</b> de relatórios e log de modificações.</p>',
    PLANS_PREMIUM_info1: '<p>Todas as funcionalidades do Plano Conforto</p>',
    PLANS_PREMIUM_info2: '<span> Também inclui:</span><ul><li><b>Módulo testemunha integrado:</b> importação de comparáveis da localização ou referência cadastral.</li><li> <b>Possibilidade de acesso à base de dados de idealista.com</b></li>',
    PLANS_noIVAPlans: '*Os preços indicados não incluem IVA',
    PLANS_pricePerReport: '{{pricePlan}}{{currency}} por relatório',
    appraiser: 'Profissional',
    APPRAISALS_appraiser: 'Profissional',
    APPRAISALS_reasonToChooseTech: 'Diga-nos a razão para a escolha deste profissional.',
    APPRAISAL_DASHBOARD_resolvedByAppraiser: 'resolvidas pelo profissional',
    PROFESSIONALS_appraisers: 'Profissionais',
    PROFFESIONALS_aditionalInfoTooltip: 'O código externo é utilizado para preservar alguma numeração própria. As observações podem ser lidas pelo profissional na aplicação. Embora as observações internas só possam ser lidas neste painel de administração, o profissional não poderá lê-las na aplicação.',
    QUALITY_appraiser: 'Profissional',
    INVOICES_technical_mannager_users: 'Utilizadores validadores',
    APPRAISALS_isExecutedByTasafy: 'É executado com Tasafy',
    APPRAISALS_selectManager: 'Selecionar gestor',
    APPRAISALS_selectProfessional: 'Selecionar profissional',
    APPRAISALS_selectValidator: 'Selecionar validador',
    TRIALPERIOD: '<p>Você pode continuar desfrutando do universo Tasafy gratuitamente com um de nossos planos. Se você tiver alguma dúvida, ligue para <a href="tel:+34603718313">603 718 313</a></p>',
    TRIALPERIOD_activeFreeTrial: 'Período de teste ativo',
    TRIALPERIOD_expiredFreeTrial: 'Período de teste expirou',
    TRIALPERIOD_upTo: 'Até',
    TRIALPERIOD_btn: 'Subscreva a Tasafy',
    TRIALPERIOD_nonAdminUser: 'Fale com o seu administrador para subscrever o plano.',
    ProcessManager: 'Gestores',
    TechnicalManager: 'Validadores',
    CommercialManager: 'Responsáveis comerciais',

    log_in: 'Iniciar sessão',
    logging_in: 'A iniciar sessão...',
    log_out: 'Fechar Sessão',
    logout: 'Fechar Sessão',
    search: 'Buscar',
    generate_password: 'Gerar senha',

    // language selector:
    english: 'Inglês',
    spanish: 'Espanhol',
    portuguese: 'Português',

    // TRANSLATES RELATED DIRECTLY WITH URL PATH, DON'T TOUCH THEM.
    dashboard: 'Dashboard',
    profile: 'Perfil',
    my_profile: 'My Profile',
    detail: 'Detail',
    edit: 'Edit',
    'billing-info': 'Dados para faturação',
    'requester-companies': 'Gestores do processo',
    'supplier-companies': 'Empresas de avaliação',
    list: 'Lista',
    entities: 'Entidades',
    // END - TRANSLATES RELATED DIRECTLY WITH URL PATH, DON'T TOUCH THEM.

    // ERRORS
    required: 'Required field',
    not_valid_email: 'Not a valid email',
    // END - ERRORS

    MENU_dashboard: 'Painel de controle',
    MENU_ORDERS_requests: 'Solicitações',
    MENU_ORDERS_list: 'Lista',
    MENU_ASSETS_assets: 'Ativos',
    MENU_ASSETS_add: 'Adicionar',
    MENU_ASSETS_list: 'Lista',
    MENU_ASSESSMENTS: 'Avaliações',
    MENU_ASSESSMENTS_add: 'Adicionar Avaliação',
    MENU_ASSESSMENTS_massive_load: 'Carregamento em massa',
    MENU_ASSESSMENTS_portfolios: 'Portfolios',
    MENU_ASSESSMENTS_orders: 'Pedidos',
    MENU_ASSESSMENTS_orders_all: 'All',
    MENU_ASSESSMENTS_orders_pendingToAssignManager: 'Atribuir responsável pendente',
    MENU_ASSESSMENTS_orders_toAssign: 'Atribuição técnica pendente',
    MENU_ASSESSMENTS_orders_pendingToSchedule: 'Pending to Schedule',
    MENU_ASSESSMENTS_orders_scheduledByTec: 'Programado pelo técnico',
    MENU_ASSESSMENTS_orders_inProcess: 'Em processo',
    MENU_ASSESSMENTS_orders_deliveredByAppraiser: 'Entregue pelo técnico',
    MENU_ASSESSMENTS_orders_validationProcess: 'Validação em andamento',
    MENU_ASSESSMENTS_orders_validated: 'Validado',
    MENU_ASSESSMENTS_orders_deliveredToClient: 'Entregue ao cliente',
    MENU_ASSESSMENTS_orders_alive: 'Avaliações ativas',
    MENU_ASSESSMENTS_orders_cancelled: 'Cancelado',
    MENU_ASSESSMENTS_incidents: 'Incidentes',
    MENU_ASSESSMENTS_claims: 'Reivindicações',
    MENU_QUALITYCONTROL: 'Controle de qualidade',
    MENU_QUALITYCONTROL_createOrder: 'Criar pedido',
    MENU_QUALITYCONTROL_orders: 'Pedidos',
    MENU_QUALITYCONTROL_orders_all: 'All',
    MENU_QUALITYCONTROL_orders_pendingToAssignProcessManager: 'Pendente para atribuir o gerenciador de processos',
    MENU_QUALITYCONTROL_orders_pendingToAssignTechnicalManager: 'Pendente para atribuir gerente técnico',
    MENU_QUALITYCONTROL_orders_pending2AssignTechnical: 'Pending to Assign Technical',
    MENU_QUALITYCONTROL_orders_pendingToStartValidation: 'Pendente para iniciar a validação',
    MENU_QUALITYCONTROL_orders_validating: 'Validação em andamento',
    MENU_QUALITYCONTROL_orders_validationFinished: 'Validação concluída',
    MENU_QUALITYCONTROL_orders_deliveredToClient: 'Delivered to Client',
    MENU_QUALITYCONTROL_orders_alive: 'Avaliações ativas',
    MENU_QUALITYCONTROL_orders_cancelled: 'Cancelado',
    MENU_CLAIMS: 'Reivindicações',
    MENU_ENTITIES: 'Entidades',
    MENU_ENTITIES_prescribers: 'Prescribers',
    MENU_ENTITIES_customers: 'Clientes',
    MENU_ENTITIES_appraisal_companies: 'Empresas avaliadoras',
    MENU_ENTITIES_users: 'Usuários',
    MENU_CLIENTS: 'Clientes',
    MENU_USERS: 'Usuários',
    MENU_USERS_professionals: 'Profissionais',
    MENU_USERS_processManagers_list: 'Lista',
    MENU_USERS_processManagers_statistics: 'Estatísticas',
    MENU_USERS_techManagers_list: 'Lista',
    FINANCIAL_MENU: 'Financeiro',
    MENU_FINANCIAL_paymentSummary: 'Resumo de pagamento',
    MENU_FINANCIAL_paymentSummary_allInvoices: 'Todas as faturas',
    MENU_FINANCIAL_paymentSummary_pendingToSendToInvoicer: 'Pedido de confirmação pendente',
    MENU_FINANCIAL_paymentSummary_sendToRevisionToInvoicer: 'Confirmação enviada',
    MENU_FINANCIAL_paymentSummary_confirmedByInvoicer: 'Confirmado',
    MENU_FINANCIAL_paymentSummary_rejectedByInvoicer: 'Rejeitado',

    myComparables: 'Próprios comparáveis',

    Code: 'Código',
    code: 'Código',
    PrescriptorType: 'Tipo de prescriptor',

    Name: 'Nome',
    name: 'Nome',
    add_prescriber: 'Adicionar Gestores do processo',
    prescribers: 'Gestores do processo',
    appraisals: 'Avaliações',
    users: 'Usuarios',
    IsActive: 'Utilizador ativo',
    isActiveLicense: 'Licença Ativa',
    FirstName: 'Nome',
    LastName: 'Apelido',
    Requester: 'Gestor do processo',
    Email: 'Email',
    email: 'Email',
    deliverables: 'Entregas',
    billing_info: 'Dados para faturação',
    typology: 'Tipologia',
    cif_nif: 'NIF/NIE/Passport/CIF',
    cif: 'CIF',
    nif: 'NIF/NIE/Passport',
    phone: 'telefone',
    description: 'descrição',
    observations: 'observações',
    IrpfPercentage: 'Porcentaje de Retención de IRPF (%)',
    BillingTaxType: 'Tipo do imposto',
    tax: 'Imposto',

    bank_account_number_for_payment: 'Número da conta bancária para pagamento',

    name_or_business_name: 'Nome ou firma',
    withholding: 'Retenção de IRS',
    tax_type: 'Tipo de imposto',

    restart: 'Reiniciar',
    filter: 'Filtrar',
    cancel: 'Cancelar',
    save: 'Salvar',
    close: 'Close',
    confirm: 'Confirmar',

    RecordId: 'número de registro',
    GestasaId: 'No. Gestasa',
    ExternalRecordId: 'No. de referencia externa',
    Address: 'Dirección',
    address: 'Dirección',
    Locality: 'Localidad',
    Province: 'Provincia',
    AddedDateTime: 'Fecha de creación',
    CurrentWorkflowStep: 'Estado',
    ProcessManagerUser: 'Gestor de procesos',
    TechnicalManagerUser: 'Gestor de técnicos',
    Customer: 'Cliente',
    customer: 'Clientes',

    postalCode: 'Código Postal',
    gate: 'Porta',
    floor: 'Andar',
    stairs: 'Escadas',
    entrance: 'Entrada',
    number: 'Número',
    streetName: 'Rua',
    roadType: 'Tipo de rua',
    change_picture: 'Alterar imagem',

    AppraisalStatus_1: '1 - INSCRIÇÃO',
    AppraisalStatus_2: '2 – RESPONSÁVEL ATRIBUÍDO',
    AppraisalStatus_3: '3 – TÉCNICO ATRIBUÍDO',
    AppraisalStatus_4: '4 – VISITA AGENDADA',
    AppraisalStatus_5: '5 – VISITA INICIADA',
    AppraisalStatus_6: '6 - VISITA CONCLUÍDA',
    AppraisalStatus_7: '7 - RELATÓRIO ENTREGUE',
    AppraisalStatus_8: '8 – AVALIADOR ATRIBUÍDO',
    AppraisalStatus_9: '9 – VALIDAÇÃO INICIADA',
    AppraisalStatus_10: '10 – VALIDAÇÃO CORRETA',
    AppraisalStatus_11: '11 - ENTREGUE AO CLIENTE',

    DELIVERABLE_REPOSITORY_addRow: '+ Adicionar novo produto',
    DELIVERABLE_REPOSITORY_deliverable: 'Entregável',
    DELIVERABLE_REPOSITORY_orderType: 'Tipo de pedido',
    DELIVERABLE_REPOSITORY_prupose: 'Finalidade',
    DELIVERABLE_REPOSITORY_source: 'Origem',

    DELIVERABLE_MODAL_deliverable: 'Entregável',
    DELIVERABLE_MODAL_documentName: 'Nome do documento',
    DELIVERABLE_MODAL_generationDate: 'Data da geração',
    DELIVERABLE_MODAL_emissionDate: 'Data de emissão',
    DELIVERABLE_MODAL_expirationDate: 'Data de validade',
    DELIVERABLE_MODAL_documentVersion: 'Versão do documento',
    DELIVERABLE_MODAL_downloadAll: 'Baixar tudo',
    DELIVERABLE_MODAL_downloading: 'Download',
    DELIVERABLE_MODAL_internal: 'Interno',
    DELIVERABLE_MODAL_external: 'Externo',

    add_new_prescriptor: '+ Adicionar novo gestor do processo',

    an_error_has_ocurred: 'Ocorreu um erro',

    the_action_was_successful: 'A ação foi bem sucedida',
    successfully_completed: 'Executado com sucesso',
    account_number_for_payments: 'Número da conta bancária para pagamento',

    PROPERTY_DATA_TABLE_CadastralReference: 'Referência cadastral',
    PROPERTY_DATA_TABLE_RegistryInfoRegister: 'Registro de propriedade',
    PROPERTY_DATA_TABLE_RegistryInfoRegisterNumber: 'Número da propriedade do registro',
    PROPERTY_DATA_TABLE_RegistryInfoIdufir: 'C.R.U. (IDUFIR)',
    PROPERTY_DATA_TABLE_RegistryInfoVolume: 'Tomo',
    PROPERTY_DATA_TABLE_RegistryInfoBook: 'Livro',
    PROPERTY_DATA_TABLE_RegistryInfoSection: 'Folio',
    PROPERTY_DATA_TABLE_RegistryInfoInscription: 'Inscription',
    PROPERTY_DATA_TABLE_RegistryInfoRealEstateNumber: 'Propriedade de registro',
    PROPERTY_DATA_TABLE_adress: 'Endereço',
    PROPERTY_DATA_TABLE_refCatastral: 'Ref. catastral',
    PROPERTY_DATA_TABLE_tipologyAndUse: 'Uso e tipologia',
    PROPERTY_DATA_TABLE_surface: 'Superfície',
    PROPERTY_DATA_TABLE_antiquity: 'Antiguidade',
    PROPERTY_DATA_TABLE_estimatedValueBand: 'Intervalo de valor estimado',
    PROPERTY_DATA_TABLE_total: 'Total:',
    PROPERTY_DATA_TABLE_prupsedProperty: 'Taxa proposta',
    ORDER_REQUEST_DETAIL_registerData: 'dados do registro',
    ORDER_REQUEST_DETAIL_euro: 'EUROS (sem IVA).',

    ADD_NEW_ASSET_FORM_location: 'Location',
    ADD_NEW_ASSET_FORM_addManualy: 'Adicionar manualmente',
    ADD_NEW_ASSET_FORM_province: 'Province',
    ADD_NEW_ASSET_FORM_municipality: 'Município',
    ADD_NEW_ASSET_FORM_streetName: 'Nome do caminho',
    ADD_NEW_ASSET_FORM_number: 'Number',
    ADD_NEW_ASSET_FORM_Block: 'Block',
    ADD_NEW_ASSET_FORM_Stairs: 'Escada',
    ADD_NEW_ASSET_FORM_Floor: 'Floor',
    ADD_NEW_ASSET_FORM_Gate: 'Door',
    ADD_NEW_ASSET_FORM_Use: 'Use',
    ADD_NEW_ASSET_FORM_Typologies: 'Tipologia',
    ADD_NEW_ASSET_FORM_Surface: 'Surface',
    ADD_NEW_ASSET_FORM_ConstructionYear: 'Idade',
    ADD_NEW_ASSET_FORM_cadastralReference: 'Referência cadastral',
    ADD_NEW_ASSET_FORM_propertyRegister: 'Registro de propriedade',
    ADD_NEW_ASSET_FORM_propertyRegisterNumber: 'Número de propriedade registrado',
    ADD_NEW_ASSET_FORM_idufir: 'C.R.U (IDUFIR)',
    ADD_NEW_ASSET_FORM_volume: 'Tomo',
    ADD_NEW_ASSET_FORM_book: 'Livro',
    ADD_NEW_ASSET_FORM_paper: 'Fólio',
    ADD_NEW_ASSET_FORM_inscription: 'Inscription',
    ADD_NEW_ASSET_FORM_realState: 'Finca',
    ADD_NEW_ASSET_FORM_add: 'Adicionar',
    ORDER_REQUEST_DETAIL_addNewAsset: '+ Adicionar novo ativo',
    ORDER_REQUEST_DETAIL_cancel: '- Cancelar',
    ORDER_REQUEST_DETAIL_showDetail: 'Ver detalhe',

    InvoiceStatus_0: 'Solicitação de confirmação pendente',
    InvoiceStatus_1: 'Confirmação enviada',
    InvoiceStatus_2: 'Confirmado',
    InvoiceStatus_3: 'Rejeitado',

    MonthsEnum_1: 'Janeiro',
    MonthsEnum_2: 'Fevereiro',
    MonthsEnum_3: 'Março',
    MonthsEnum_4: 'Abril',
    MonthsEnum_5: 'Maio',
    MonthsEnum_6: 'Junho',
    MonthsEnum_7: 'Julho',
    MonthsEnum_8: 'Agosto',
    MonthsEnum_9: 'Setembro',
    MonthsEnum_10: 'Outubro',
    MonthsEnum_11: 'Novembro',
    MonthsEnum_12: 'Dezembro',

    financial: 'financeiro',
    'code-management': 'Gerenciamento de código',
    'payment-summary': 'Resumo do pagamento',
    'users-credentials': 'Usuários com credenciais',

    'commercial-manager-user': 'UItilizadores Gestores Comerciales',

    ADD_NEW_ASSET_FORM_postalCode: 'Código postal',
    ADD_NEW_ASSET_FORM_streeDoseNotExist: 'No está en el callejero',

    assessments: 'Avaliações',
    nationalIdentityDocument: 'Documento nacional de identidad',
    tab_information_updated_please_reload_tab: 'La información ha sido actualizada. Recargue la pestaña.',
    no_documents: 'No hay documentos',
    from: 'desde a',
    toDate: 'até',
    cancelledDate: 'Data de cancelamento',
    finishedDate: 'Finished de finalizacion',

    REQUESTS_billing: 'Faturamento',
    REQUESTS_createNewInvoice: 'Criar nova fatura',
    incomeOrExpensesProvision: 'Provisão Renda/Despesas',
    incomeOrExpensesInvoiced: 'Cobrança Renda/Despesas',
    billingType: 'Tipo de cobrança',
    billingStatus: 'Status de cobrança',
    finishedAppraisals: 'Avaliações Concluídas',
    appraisalsWithInvoice: 'Avaliações com fatura',
    amountsSummary: 'Resumo dos montantes',
    faturas: 'Faturas',
    generalData: 'Dados gerais',
    addValorationToRequestInvoice: 'Adicionar avaliação à fatura',
    selectAmountToIncludeInvoice: 'Selecione o valor a incluir na fatura',
    confirmInvoice: 'Confirmar fatura',
    allInvoiced: 'Facturado totalmente',
    partialInvoiced: 'Facturado parcialmente',
    IndividualByAsset: 'Particular por inmuebles',
    GlobalToOrder: 'Global',
    addAppraisalToInvoiceWarningAutoIndividual: 'El importe de la factura será el sumatorio de los importes confirmados de las valoraciones seleccionadas.',
    addAppraisalToInvoiceWarningAutoGlobal: 'El importe de la factura será el indicado después la selección de las valoraciones.',
    addAppraisalToInvoiceWarningManual: 'Los datos de facturación serán incluidos de forma manual después de la selección de las valoraciones',
    notInvoiced: 'No facturado',
    filesVolume: 'Volumen de expedientes',
    filesAverageTime: 'Tiempos medios de expedientes',

    my_work: 'O meu trabalho',

    All: 'Todos',

    Appraiser: 'Profissionais',
    User: 'Usuários',

    entitiesLabel: 'Contas',
    ENTITIES_entityType: 'Tipo de conta',
    ENTITIES_entities: 'Contas',
    ENTITIES_createEntity: 'Criar conta',
    ENTITIES_add_entity: 'Adicionar conta',

    how_does_it_works_side_menu: 'Como funciona?',

    ACCOUNT_ACTIVATION_noActivationTitle: 'Não se esqueça de ativar sua conta!',
    ACCOUNT_ACTIVATION_noActivationMessage: 'Sua conta ainda não foi ativada. Verifique sua caixa de entrada onde você encontrará o e-mail para fazê-lo. Se você não o recebeu ou ultrapassou o tempo de ativação de um dia, pode solicitá-lo novamente aqui.',
    ACCOUNT_ACTIVATION_resend: 'Reenviar',
    ACCOUNT_ACTIVATION_resending: 'Reenviando...',
    ACCOUNT_ACTIVATION_goToLogin: 'Voltar ao login',
    ACCOUNT_ACTIVATION_defaultError: 'Erro ao ativar a conta. Por favor, entre em contato conosco',
    ACCOUNT_ACTIVATION_resendSuccess: 'E-mail reenviado com sucesso',
    ACCOUNT_ACTIVATION_resendError: 'Erro ao reenviar e-mail',
    ACADEMY_COURSES_basicConcepts: 'Conceitos Básicos',
    ACADEMY_COURSES_reportExecution: 'Execução do Relatório',
    MENU_dataReport: 'Relatório de dados',

    SUPPORT_title: 'Você precisa de ajuda?',
    SUPPORT_faq1: 'Você tem alguma dúvida sobre o aplicativo? <br> Confira nossos <b><a href="{{link_1}}" target="_blank"> tutoriais em vídeo</a></b>.',
    SUPPORT_faq2: 'Você se perdeu na execução de um relatório? <br>Revise todos os pontos no <b><a href="{{link_1}}" target="_blank">vídeo explicativo</a></b>.',
    SUPPORT_faq3: 'Gostaria de se inscrever em um de nossos cursos?<br> <b><a href="{{link_1}}" target="_blank">Mais informações</a></b>.',
    SUPPORT_footer: 'Se ainda precisar de ajuda, entre em contato conosco escrevendo um e-mail para <b><a href="{{link_1}}">info@tasafy.com</a></b>.',

    //Overrides
    CUSTOMER_PRESCRIBER_customer_modal_tooltip_message: "Você pode criar um cliente no menu superior 'Contas'",
    CUSTOMER_PRESCRIBER_prescriber_modal_tooltip_message: "Você pode criar um prescritor no menu superior 'Contas'",
    APPRAISAL_OWNER_DATA_customer_tooltip_message: "Você pode criar um cliente no menu superior 'Contas'",
    REQUESTER_COMPANY_prescriber_tooltip_message: "Você pode criar um prescritor no menu superior 'Contas'",
  },
};
