export enum FoxeetItemValidationTypes {
  Error = 0,
  Warning = 1,
  Information = 2,
}

export enum AppraisalAppraiserSentStates {
  Sent = 0,
  Received = 1,
  Read = 2,
}

// export enum AppraisalDocumentTypes {
//   Others = 0,
//   RegistralCertification = 1,
//   SimpleNote = 2,
//   PropertyRegistrationBookPhotocopy = 3,
//   DeedPropertyPhotocopy = 4,
//   OthersLegalRegistralCertification = 5,
//   CadastralDocumentation = 6,
//   DenialSafetyInsurance = 7,
//   ImprovementWorksCertificate = 8,
//   RentalContractOrAcquisitionTitle = 9,
//   UrbanismDocumentation = 10,
//   UrbanPlane = 11,
//   GradingCertificateVpo = 12,
//   InputFileForAutomaticCreation = 13,
//   AppraisalReport = 14,
//   AppraisalInvoice = 15,
//   Json = 16,
//   SituationAndLocation = 17,
//   ComparableLocation = 18,
//   PropertySketch = 19,
//   LicensesAndAuthorizations = 20,
//   DocumentationOnRightsAndLimitations = 21,
//   CertificateReport = 22,
//   CfeSivasa_Certificate = 23,
//   CfeSivasa_Report = 24,
//   CfeSivasa_TerrainFile = 25,
//   CfeSivasa_SimpleNote = 26,
//   CfeSivasa_AnnexedDoc = 27,
//   CfeSivasa_CalculationDoc = 28,
//   CfeSivasa_Cee = 29,
//   EnergeticCertificate = 30,
//   XML = 31,
//   WorkProjectEndorsed = 32,
//   BuildingLicense = 33,
//   CertificationWork = 34,
//   WorkExecutionContract = 35,
// }

export enum AppraisalImplementationFileDocumentTypes {
  Others = 0,
  RegistralCertification = 1,
  SimpleNote = 2,
  PropertyRegistrationBookPhotocopy = 3,
  DeedPropertyPhotocopy = 4,
  OthersLegalRegistralCertification = 5,
  CadastralDocumentation = 6,
  DenialSafetyInsurance = 7,
  ImprovementWorksCertificate = 8,
  RentalContractOrAcquisitionTitle = 9,
  UrbanismDocumentation = 10,
  UrbanPlane = 11,
  GradingCertificateVpo = 12,
  InputFileForAutomaticCreation = 13,
  AppraisalReport = 14,
  AppraisalInvoice = 15,
  Json = 16,
  SituationAndLocation = 17,
  ComparableLocation = 18,
  PropertySketch = 19,
  LicensesAndAuthorizations = 20,
  DocumentationOnRightsAndLimitations = 21,
  CertificateReport = 22,
  CfeSivasa_Certificate = 23,
  CfeSivasa_Report = 24,
  CfeSivasa_TerrainFile = 25,
  CfeSivasa_SimpleNote = 26,
  CfeSivasa_AnnexedDoc = 27,
  CfeSivasa_CalculationDoc = 28,
  CfeSivasa_Cee = 29,
  EnergeticCertificate = 30,
  XML = 31,
  WorkProjectEndorsed = 32,
  BuildingLicense = 33,
  CertificationWork = 34,
  WorkExecutionContract = 35,
  Xml = 36,
  Pdf = 37,
  Excel = 38,
  QualityAssurance = 39,
}

export enum AppraisalDocumentTypologies {
  Blueprints = 0,
  RegistrationDocumentation = 1,
  CadastralDocumentation = 2,
  UrbanDocumentation = 3,
  LicensesAndAuthorizations = 4,
  DocumentationOnRightsAndLimitations = 5,
  WorkDocumentation = 6,
  OccupationDocumentation = 7,
  ProtectionRegimeDocumentation = 8,
  AccountingDocuments = 9,
  OtherDocumentation = 10,
}

export enum AppraisalIncidentCodes {
  Inc1 = 1,
  Inc2 = 2,
  Inc3 = 3,
  Inc4 = 4,
  Inc5 = 5,
  Inc6 = 6,
  Inc7 = 7,
  Inc8 = 8,
  Inc9 = 9,
  Inc10 = 10,
  Inc11 = 11,
  Inc12 = 12,
  Inc13 = 13,
  Inc14 = 14,
  Inc15 = 15,
  Inc16 = 16,
  Inc17 = 17,
  Inc18 = 18,
  Inc19 = 19,
  Inc20 = 20,
  Inc21 = 21,
  Inc22 = 22,
  Inc23 = 23,
  Inc25 = 25,
  Inc26 = 26,
  Inc27 = 27,
  Inc28 = 28,
  Inc29 = 29,
  Inc30 = 30,
  Inc31 = 31,
  Inc32 = 32,
  Inc33 = 33,
  Inc34 = 34,
  Inc35 = 35,
  Inc36 = 36,
  Inc37 = 37,
  Inc38 = 38,
  Inc39 = 39,
  Inc40 = 40,
  Inc41 = 41,
  Inc42 = 42,
  Inc43 = 43,
  Inc44 = 44,
  Inc45 = 45,
  Inc46 = 46,
  Inc47 = 47,
  Inc48 = 48,
  Inc49 = 49,
}

export enum AppraisalNormatives {
  Eivs = 0,
  Rics = 1,
  Eco = 2,
  Evs = 3,
  Others = 4,
}

export enum AppraisalOrderTypes {
  Appraisal = 0,
  IvsValoration = 1,
  RicsValoration = 2,
  DataPick = 3,
  PriceSettingStudy = 4,
  MarketStudy = 5,
  CommercialReport = 6,
  ValorationSheet = 8,
  ProjectMonitoring = 9,
  EnergeticCertification = 10,
  Others = 11,
  ManagementReport = 12,
  QualityAssurance = 13,
  AVM = 14,
  Advice = 15,
  ReportSignificantValueDrops = 16,
}

export enum AppraisalPurposes {
  MortgageSecurity = 0,
  ValuationOfPropertiesOfInsuranceCompaniesForTheEffectOfTheDeterminationOfTheirMarketValue = 1,
  DeterminationOfTheHeritageOfTheCollectiveRealEstateInvestmenteInstitutions = 2,
  DeterminationOfTheRealEstateHeritageOfPensionFunds = 3,
  NationalBankOfSpainCircular = 4,
  MarketValue = 5,
  DationInPayment = 7,
  OtherPurposes = 10,
  MarketRent = 11,
  ReasonableValue = 12,
  InvestmentValue = 13,
  FairValue = 14,
  SynergyValue = 15,
  SpecialValue = 16,
  LiquidationValue = 17,
  RepositionValue = 19,
  CadastralValue = 22,
  ExpropiationValue = 23,
  NationalBankOfSpainCircularMarket = 26,
  NormalMarketValue = 27,
  FairMarketValueUSInternalTaxes = 28,
  AVM = 38,
}

export enum AppraisalVinculations {
  IntermediateWorkEvaluation = 0,
  AppraisalUpdate = 1,
  EntityChange = 2,
  CancelAndReplace = 3,
  ConditionalsRiseUp = 4,
  DataPick = 6,
  CEE = 7,
}

export enum AppraisalVisitTypes {
  Interior = 0,
  Exterior = 1,
  NoApply = 2,
}

export enum BdECategoryOfMunicipalityTypes {
  CapitalsAndSimilarsMoreThan100000 = 1,
  CapitalsAndSimilarsMoreThan50000 = 2,
  MoreThan20000 = 3,
  MoreThan10000 = 4,
  LessThan10000 = 5,
}

export enum BdEExploitingAboveGroundTypes {
  LessThan10000 = 1,
  LessThan50000 = 2,
  LessThan150000 = 3,
  LessThan250000 = 4,
  MoreThan250000 = 5,
}

export enum BdEManagementSystemTypes {
  License = 1,
  UrbanAgentOrConcert = 2,
  Compensation = 3,
  Cooperation = 4,
  ExpropiationOrWhithoutManagement = 5,
}

export enum BdEPercentageOfValuedAreaTypes {
  OneHundredPercent = 1,
  MoreThanEightyPercent = 2,
  MoreThanSixtyPercent = 3,
  MoreThanFiftyPercent = 4,
  LessThanFiftyPercent = 5,
}

export enum BdEPlotSurfaceTypes {
  LessThan10000 = 1,
  LessThan100000 = 2,
  LessThan500000 = 3,
  LessThan1000000 = 4,
  MoreThan1000000 = 5,
}

export enum BdEProductToDevelopTypes {
  VPOorVPPOfFirstResidence = 1,
  ResidentialFreeFirstResidence = 2,
  SecondResidenceOrHotelOrOfficeOrCommercialPremises = 3,
  IndustrialOrLogisticOrGarage = 4,
  DotationalOrRecreationalOrOthers = 5,
}

export enum BdETerrainPlanningDevelopmentTypes {
  UrbanizationFinished = 1,
  ApprovedUrbanizationProject = 2,
  PlanningDevelopmentDefinitivelyApproved = 3,
  PlanningDevelopmentInitiallyApproved = 4,
  PlanningDevelopmentNotRedactedOrApproved = 5,
}

export enum BdETerrainTypes {
  UrbanConsolidated = 1,
  UrbanNotConsolidated = 2,
  DevelopableSectorized = 3,
  DevelopableNotSectorized = 4,
  NotDevelopableOrRusticValorated = 5,
}

export enum BillingDataCopyOptions {
  OwnerData = 0,
  PrescriberData = 1,
  CustomerAdvanced = 2,
}

export enum DeliveryTypes {
  Http = 0,
  File = 1,
}

export enum FeeOptions {
  IndividualByAsset = 0,
  GlobalToOrder = 1,
}

export enum ProcessObjectLogItemSubTypes {
  Documents = 1,
  AssignProcessManager = 2,
  AssignAppraiser = 3,
  VisitScheduled = 4,
  AssignTechnicalManager = 5,
  DataInvoice = 6,
  ValidationAppraisal = 7,
}

export enum ReportTypes {
  FinishedElements = 0,
  UnfinishedElements = 1,
  FinishedBuildings = 2,
  UnfinishedBuildings = 3,
  Terrains = 4,
  RusticEstates = 5,
  EconomicExploitations = 6,
}

export enum SpanishNationalBankPurposes {
  Collateral = 0,
  Awarded = 1,
}

export enum FamilyRelationships {
  Parents = 0,
  Sons = 1,
  Grandchildren = 2,
  Siblings = 3,
  Grandparents = 4,
  PartnerParents = 5,
  PartnerSons = 6,
  PartnerGrandchildren = 7,
  PartnerSiblings = 8,
  PartnerGrandparents = 9,
  Partner = 10,
  SonDaughterInLaw = 11,
  BrotherInLaw = 12,
  ParticipatedEnterpises = 13,
  Myself = 14,
}

export enum IvForcePendingNotificationStates {
  Pending = 0,
  Sending = 1,
  Sent = 2,
  Error = 3,
}

export enum IvWebAssetUses {
  LivingPlace = 0,
  Commercial = 1,
  Office = 2,
  OtherUses = 3,
  Industrial = 4,
  Garage = 5,
  StorageRoom = 6,
  Warehouse = 7,
  RusticEstate = 8,
  Terrain = 9,
  Building = 10,
  BuildingLot = 11,
  EconomicExploitation = 12,
  CommercialBuilding = 13,
}

export enum IvWebOrderTypes {
  AskForMortage = 0,
  PublicAdministrationProcess = 1,
  OperationsBetweenPersonsOrCompanies = 2,
}

export enum PaymentWorkflows {
  IvForce = 0,
  Evo = 1,
  IvWebEcommerce = 2,
}

export enum PriceRanges {
  Until200000 = 0,
  From200001To300000 = 1,
  From300001To500000 = 2,
  From500001To650000 = 3,
  From650001To800000 = 4,
  From800001To1000000 = 5,
  From1000001 = 6,
  Until300000 = 7,
  From300000To600000 = 8,
  From600000To1200000 = 9,
  From1200000To3000000 = 10,
  From3000000To6000000 = 11,
  From6000000To14000000 = 12,
  From14000000To25000000 = 13,
  From25000000To50000000 = 14,
  From50000000 = 15,
  Until30000 = 16,
  From30001To300000 = 17,
  From300001To600000 = 18,
  From600001To1200000 = 19,
  From1200001To3000000 = 20,
  From3000001 = 21,
  Until100000 = 22,
  From100001To200000 = 23,
  From300001To400000 = 24,
  From400001To600000 = 25,
  From600001To800000 = 26,
  From1000001To1250000 = 27,
  From1250001To1500000 = 28,
  From200001To400000 = 29,
  From600001To1000000 = 30,
  From30001To150000 = 31,
  From150000To300000 = 32,
  From3000000 = 33,
  Until150000 = 34,
  NotApply = 35,
}

export enum PushNotificationTypes {
  Appraisal_New = 1,
  Appraisal_RevisionRequired = 2,
  Appraisal_CommentAdded = 3,
  Appraisal_IncidentAdded = 4,
  Appraisal_IncidentSolved = 5,
  Invoice_New = 6,
}

export enum SimpleNoteErrorTypes {
  Others = 0,
  WrongIDUFIR = 1,
  WrongRegistrationData = 2,
}

export enum Trimesters {
  FirstTrimester = 0,
  SecondTrimester = 1,
  ThirdTrimester = 2,
  FourthTrimester = 3,
}

export enum UserFileTypes {
  ProfessionalServicesContract = 0,
  AcceptanceOfInternalCodeOfConduct = 1,
  ProfessionalDeclarations = 2,
  TreatmentManagerContract = 3,
  CV = 4,
  NationalIdentityDocument = 5,
  Degree = 6,
  ScholarshipCertificate = 7,
  Signature = 8,
  EnviromentalRequirementsComunication = 9,
}

// export enum ValidationAppraisalDocumentTypes {
//   Xml = 0,
//   Pdf = 1,
//   Excel = 2,
//   QualityAssurance = 3,
// }

export enum ValidationAppraisalRealestateTypes {
  Others = 0,
  ResidentialMultiFamilyWithAnnexes = 1,
  ResidentialMultiFamilyWithoutAnnexes = 2,
  ResidentialSingleFamily = 3,
  Garage = 4,
  StorageRoom = 5,
  Commercial = 6,
  Office = 7,
  SortingDepot = 8,
  Building = 9,
  Ground = 10,
  RusticGround = 11,
  EconomicExploitation = 12,
}

export enum ValidationAppraisalRegisteredPropertyValidationType {
  Value = 0,
  Identity = 1,
  Method = 2,
  Surface = 3,
  ExecutedWork = 4,
  Anejo = 5,
  Transcription = 6,
  BreackdownValues = 7,
  ValuedUnits = 8,
  PropertyType = 9,
  Purpose = 10,
  Cirbe = 11,
  Documentation = 12,
  Others = 13,
  VPO = 14,
}

export enum AppEnvironments {
  PRO = 0,
  PRE = 1,
  DEV = 2,
}

export enum AssetClusterValidations {
  IsFinishedElements = 0,
  IsTerrain = 1,
  IsEconomicExploit = 2,
  IsRuralProperty = 3,
  IsClustered = 4,
  HasValorations = 5,
  IsStateNull = 6,
  IsStateNotFinished = 7,
  TerrainCompatibleUses = 8,
  RuralPropertiesCompatibleUses = 9,
  OtherCompatibleUses = 10,
  CompatibleStates = 11,
}

export enum AssetDataSourceTypes {
  ReportAssetCommonData = 0,
  EstateAssetCommonData = 1,
  Own = 2,
}

export enum AssetSections {
  Identification = 0,
  Location = 1,
  Typologies = 2,
  CadastralSurfaces = 3,
  RegisteredSurfaces = 4,
  CheckedSurfaces = 5,
  State = 6,
  LocalityEnvironmentInformation = 7,
  BuildingInformation = 8,
  Facilities = 9,
  TenureOcupationInformation = 10,
  UrbanisticSituation = 11,
  Stays = 12,
  Files = 13,
  Checks = 14,
  Cluster = 15,
  Valorations = 16,
  Conditionals = 17,
  Surfaces = 18,
  Warnings = 19,
  Observations = 20,
  Photos = 21,
  VisitTypologyAndState = 22,
  VisitSurfacesFacilitiesStays = 23,
  VisitEnvironmentAndBuildingInformation = 24,
  TerrainInformation = 25,
  BuildingTerrainDescription = 26,
  RusticEstateInformation = 27,
  ExploitationDescription = 28,
}

export enum AssetSurfaceDefaultSelector {
  TotalPlotSurface = 0,
  TotalUsefulSurface = 1,
}

export enum AssetSurfaceGroup {
  Cadastral = 0,
  Registered = 1,
  Checked = 2,
}

export enum AssetSurfaceIds {
  CadastralSurfaces_TotalPlotSurface = 0,
  CadastralSurfaces_TotalUsefulSurface = 1,
  CadastralSurfaces_TotalBuiltSurfaceWithoutCommonSurfaces = 2,
  CadastralSurfaces_TotalBuiltSurfaceWithCommonSurfaces = 3,
  CadastralSurfaces_TotalCoveredTerraceSurface = 4,
  CadastralSurfaces_TotalUncoveredTerraceSurface = 5,
  RegisteredSurfaces_TotalPlotSurface = 6,
  RegisteredSurfaces_TotalUsefulSurface = 7,
  RegisteredSurfaces_TotalBuiltSurfaceWithoutCommonSurfaces = 8,
  RegisteredSurfaces_TotalBuiltSurfaceWithCommonSurfaces = 9,
  RegisteredSurfaces_TotalCoveredTerraceSurface = 10,
  RegisteredSurfaces_TotalUncoveredTerraceSurface = 11,
  CheckedSurfaces_TotalPlotSurface = 12,
  CheckedSurfaces_TotalUsefulSurface = 13,
  CheckedSurfaces_TotalBuiltSurfaceWithoutCommonSurfaces = 14,
  CheckedSurfaces_TotalBuiltSurfaceWithCommonSurfaces = 15,
  CheckedSurfaces_TotalCoveredTerraceSurface = 16,
  CheckedSurfaces_TotalUncoveredTerraceSurface = 17,
  LegalMax_RegisteredSurfaces_TotalUsefulSurface = 18,
  LegalMax_CheckedSurfaces_TotalUsefulSurface = 19,
  LegalMax_QualificationCard_TotalUsefulSurface = 20,
  Cluster_TotalAdoptedSurface = 21,
  CommonWorkPlanState_TotalAdoptedSurface = 22,
}

export enum AssetSurfaceTypes {
  Plot = 0,
  Useful = 1,
  BuiltSurfaceWithoutCommon = 2,
  BuiltSurfaceWithCommon = 3,
  CoveredTerrace = 4,
  UncoveredTerrace = 5,
}

export enum AssetTabs {
  CommonData_LocalityEnvironmentMarket = 0,
  CommonData_BuildingInformation = 1,
  CommonData_TerrainInformation = 2,
  CommonData_UrbanisticSituation = 3,
  CommonData_Checks = 4,
  CommonData_ConditionalsWarningsObservations = 5,
  CommonData_Workstate = 6,
  Report_Identification = 7,
  Report_Localization = 8,
  Report_Typology = 9,
  Report_Surfaces = 10,
  Report_Workstate = 11,
  Report_LocalityEnvironmentMarket = 12,
  Report_TerrainSurfacesAndDescription = 13,
  Report_BuildingInformation = 14,
  Report_StayAndFacilitiesInformation = 15,
  Report_UrbanisticSituation = 16,
  Report_Tenures = 17,
  Report_Checks = 18,
  Report_Valorations = 19,
  Report_ConditionalsWarningsAndObservations = 20,
  Report_Photos = 21,
  Report_Documents = 22,
  Report_Log = 23,
  CommonDataVisit_LocalityEnvironmentMarket = 24,
  CommonDataVisit_BuildingInformation = 25,
  CommonDataVisit_TerrainInformation = 26,
  Visit_Typology = 27,
  Visit_Surfaces = 28,
  Visit_TerrainInformation = 29,
  Visit_BuildingInformation = 30,
  Visit_Photos = 31,
  Visit_Documents = 32,
  Visit_LocalityEnvironmentMarket = 33,
  Report_TerrainInformation = 34,
  Visit_SurfacesAndPropertyFeatures = 35,
  CommonData_TerrainUrbanisticSituation = 36,
  Report_TerrainUrbanisticSituation = 37,
  Cluster_Details = 38,
  Cluster_Valorations = 39,
  CommonData_RusticEstateInformation = 40,
  CommonData_ExploitationDescription = 41,
  Report_RusticEstateInformation = 42,
  Report_ExploitationDescription = 43,
  CommonDataVisit_RusticEstateInformation = 44,
  Visit_RusticEstateInformation = 45,
  KeyValues = 46,
  CommonData_TerrainSurfacesAndDescription = 47,
  CommonData_MarketStudies = 48,
}

export enum AssetTabsToCheck {
  All = 0,
  Identification = 1,
  Location = 2,
  Typologies = 3,
  State = 4,
  CadastralSurfaces = 5,
  RegisteredSurfaces = 6,
  CheckedSurfaces = 7,
  BuildingInformation = 8,
  UrbanisticSituation = 9,
  Facilities = 10,
  Stays = 11,
  Tenure = 12,
  EnvironmentInformation = 13,
  TerrainInformation = 14,
  BuildingTerrainDescription = 15,
  Valorations = 16,
  FutureValorations = 17,
  WorkPlanState = 18,
  VisitForm_TypologyAndState = 19,
  VisitForm_EnvironmentInformation = 20,
  VisitForm_BuildingInformation = 21,
  VisitForm_SurfacesAndFacilitiesAndStays = 22,
  VisitForm_TerrainInformation = 23,
  RusticEstateInformation = 24,
  ExploitationDescription = 25,
  AllSurfaces = 26,
}

export enum AssetWorkStates {
  NewBuildProject = 0,
  UnderConstruction = 1,
  WorkOnHold = 2,
  Rehabilitation = 3,
  RehabilitationProject = 4,
}

export enum BatchStates {
  PendingToProcess = 0,
  InProgress = 1,
  DoneSuccessfully = 2,
  DoneFailed = 3,
}

export enum BillingTaxTypes {
  Iva = 0,
  Igic = 1,
  Ipsi = 2,
}

export enum BorrowersHabitualResidence {
  Yes = 0,
  No = 1,
  DoesNotApply = 2,
}

export enum BuildingElementSurfaceIds {
  TotalUsefulSurface = 0,
  TotalBuiltSurfaceWithoutCommonSurfaces = 1,
  TotalBuiltSurfaceWithCommonSurfaces = 2,
  TotalPlotSurface = 3,
  TotalCoveredTerraceSurface = 4,
  TotalUncoveredTerraceSurface = 5,
}

export enum BuildingInformationDeckTypes {
  FlatRoof = 0,
  NonPassableFlatRoof = 1,
  TiledRoofCeramic = 2,
  SlopedroofSlate = 3,
  MetalPanelSandwich = 4,
  MetalZincOrCopper = 5,
  Others = 6,
}

export enum BuildingInformationEnclosureTypes {
  BrickFactorySeen = 0,
  SilgleLayerMortar = 1,
  BrickAndSingleLayerMortar = 2,
  PlasteredPaintedBrickFactory = 3,
  StoneOrCeramicCladding = 4,
  Ventilated = 5,
  CurtainWall = 6,
  AluminiumPanel = 7,
  PrecastConcreteSlabs = 8,
  Sheet = 9,
  Others = 10,
}

export enum BuildingInformationFoundationTypes {
  ReinforcedConcreteFoundationForIsolatedFootings = 0,
  ReinforcedConcreteFoundationForRunningFootings = 1,
  FoundationByMeansOfReinforcedConcreteSlab = 2,
  FoundationByWells = 3,
  FoundationUsingPrecastConcretePiles = 4,
  FoundationUsingConcretePilesOnSite = 5,
  FoundationUsingSteelPiles = 6,
  FoundationByMicropiles = 7,
  Unknown = 8,
  Others = 9,
}

export enum BuildingInformationStructureTypes {
  ReinforcedConcreteBeamsAndPillarsForgedOneWay = 0,
  ReinforcedConcreteBeamsAndPillarsForgedTwoWay = 1,
  ReinforcedConcreteBeamsAndPillarsForgedConcreteSlab = 2,
  BeamsAndMetalPillarsFramesForgedUnidirectionalReinforcedConcrete = 3,
  BeamsAndMetalPillarsFramesForgedBidirectionalReinforcedConcrete = 4,
  BeamsAndMetalPillarsFramesForgedCollaboratingSheet = 5,
  MixedMetallicAndReinforcedConcrete = 6,
  PrefabricatedBeamsPillarsForjedAlveolarPlate = 7,
  PillarsAndMetalTrussers = 8,
  Unknown = 9,
  WoodenPorticoStructureForgedWoodenJoistsCeramicStudsForgedOneWay = 10,
  StructureloadbearingWallsReinforcedConcreteBeamsForgedReinforcedConcreteJoistsCeramicStudsForgedOneWay = 11,
  StructureLoadbearingWallsReinforcedConcreteBeamsForgedReinforcedConcreteJoistsCeramicVaultsForgedOneWay = 12,
  Others = 13,
}

export enum BuildingQualityTypes {
  VeryHigh = 0,
  High = 1,
  Medium = 2,
  Low = 3,
  Unknown = 4,
}

export enum BuildingStates {
  Closed = 0,
  UnderConstruction = 1,
  UnderProject = 2,
}

export enum BuildingUses {
  Residential = 0,
  Commercial = 1,
  Tertiary = 2,
  Industrial = 3,
  Dotational = 4,
}

export enum CeilingTypes {
  Painting = 0,
  FakeCeilingPlaster = 1,
  FakeCeilingRecordable = 2,
  Other = 3,
  GotelePaint = 4,
  SoundAbsorbingFakeCeiling = 5,
  Raw = 6,
  Unknown = 7,
}

export enum ClusterTypes {
  FinishedElements = 0,
  Terrains = 1,
  EconomicExploitation = 2,
}

export enum ClusterValidationErrors {
  ClusterErrorAnotherCategoty = 0,
  ClusterErrorAlreadyClustered = 1,
  ClusterErrorHasValorations = 2,
  ClusterErrorNullState = 3,
  ClusterErrorNotFinishedState = 4,
  ClusterErrorIncompatibleUses = 5,
  ClusterErrorIncompatibleStates = 6,
}

export enum CommercialConditioningType {
  LocalConditioning = 0,
  RawFinishedPremises = 1,
}

export enum CommercialLocationStreetType {
  Principal = 0,
  Secondary = 1,
}

export enum ComparableDataTypes {
  RecentTransaction = 0,
  OfferData = 1,
}

export enum ConditionalSectionTypes {
  Identification = 1,
  Documentation = 2,
  Comprobation = 3,
  Methodology = 4,
  Surface = 5,
  RegistryInscription = 6,
  RealRights = 7,
  Urbanism = 8,
  Other = 9,
  RegistryDocumentation = 10,
  UseOrExploitationDocumentation = 11,
  WorkplanDocumentation = 12,
  OtherDocumentation = 13,
  Differences = 14,
}

export enum ConditionalTypes {
  CIDE01 = 1,
  CDOC01 = 2,
  CDOC02 = 3,
  CDOC03 = 4,
  CDOC04 = 5,
  CDOC05 = 6,
  CDOC06 = 7,
  CDOC07 = 8,
  CDOC08 = 9,
  CDOC09 = 10,
  CDOC10 = 11,
  CDOC11 = 12,
  CDOC12 = 13,
  CDOC13 = 14,
  CCOM01 = 15,
  CCOM02 = 16,
  CCOM03 = 17,
  CCOM04 = 18,
  CCOM05 = 19,
  CMET01 = 20,
  CSUP01 = 21,
  CREG01 = 22,
  CREG02 = 23,
  CDER01 = 24,
  CDER02 = 25,
  CURB01 = 26,
  COTR = 27,
  AEV_IDD3_C2 = 28,
  AEV_IDD4_C2 = 29,
  AEV_CP2_C2 = 30,
  AEV_CP3_C2 = 31,
  AEV_DR1_C2 = 32,
  AEV_DR2_C2 = 33,
  AEV_DR3_C1 = 34,
  AEV_DR4_C2 = 35,
  AEV_DUE1_C2 = 36,
  AEV_DUE2_C3 = 37,
  AEV_DUE6_C3 = 38,
  AEV_DUE8_C2 = 39,
  AEV_DPO1_C3 = 40,
  AEV_DPO2_C3 = 41,
  AEV_DPO3_C3 = 42,
  AEV_DPO5_C3 = 43,
  AEV_DPO10_C2 = 44,
  AEV_ODC1_C2 = 45,
  AEV_ODC5_C1 = 46,
  AEV_ODC6_C2 = 47,
  AEV_ODC7_C3 = 48,
  AEV_ODC8_C2 = 49,
  AEV_DIF4_C1 = 50,
  AEV_CND50_DPO50 = 51,
  AEV_OTRX_C = 52,
  AEV_CND50_DUE50 = 53,
  AEV_CND50_ODC50 = 54,
  AEV_CND50_DR50 = 55,
  AEV_CND50_URB50 = 56,
  AEV_CND50_OTR50 = 57,
  AEV_OTR2_C3 = 58,
  AEV_CP2_C2_Terrains = 59,
}

export enum CondWarnObserTypes {
  Conditional = 0,
  Warning = 1,
  Observation = 2,
}

export enum ConnectionRejectionReason {}

export enum ConnectionRequestStates {
  PendingToAccept = 0,
  Accepted = 1,
  Rejected = 2,
  Cancelled = 3,
  PendingToCancel = 4,
}

export enum ConnectionRequestTypes {
  RequestedByMe = 0,
  RequestedToMe = 1,
}

export enum ConservationStateTypes {
  New = 0,
  Good = 1,
  Regular = 2,
  Bad = 3,
  NotChecked = 4,
}

export enum CrawledSources {
  Other = 0,
  Internet = 1,
}

export enum CreditCardIndustries {
  Visa = 1,
  Mastercard = 2,
  Diners = 6,
  Private = 7,
  AmericanExpress = 8,
  Jcb = 9,
  Upi = 22,
}

export enum Currencies {
  Dollar = 1,
  Pound = 2,
  Euro = 3,
  SwissFranc = 4,
  ArgentinePeso = 5,
  BolivianPeso = 6,
  DominicanPeso = 7,
  BrazilianReal = 8,
  ColombianPeso = 9,
  CostaRicanColon = 10,
  CubanPeso = 11,
  Queztal = 12,
  MexicanPeso = 13,
  UruguayanPeso = 14,
  Sol = 15,
}

export enum DynamicResidualFreeRateTypes {
  Constant = 0,
  Nominal = 1,
}

export enum EconomicExploitationTypes {
  EconomicExploitation = 0,
  SurfaceRight = 1,
  AdministrativeConcession = 2,
}

export enum EnergyCertificationGrades {
  ACertificate = 0,
  BCertificate = 1,
  CCertificate = 2,
  DCertificate = 3,
  ECertificate = 4,
  FCertificate = 5,
  GCertificate = 6,
}

export enum EntityTypes {
  FinancialEntity = 1,
  Partner = 2,
  CommercialManager = 3,
  Promoter = 4,
  InsuranceCarrier = 5,
  Servicers = 6,
  Individuals = 7,
  Fund = 8,
  PublicAdministration = 9,
  RealEstateAgency = 10,
  Enterprise = 11,
  Consulting = 12,
  FinancialIntermediary = 13,
  Socimi = 15,
  LawFirm = 16,
  IndividualLoanCompany = 17,
  AlternativeBusinessFinancing = 19,
}

export enum EnvironmentOptions {
  Much = 0,
  Medium = 1,
  Little = 2,
  None = 3,
}

export enum EnvironmentSignificance {
  Urban = 0,
  Suburban = 1,
  Rural = 2,
  PredominantlyIndustrialZone = 3,
}

export enum ExpenseTypes {
  ConstructionCosts = 0,
  ConstructionExpenses = 1,
  MarketingCosts = 2,
  FinancialCosts = 3,
  UrbanizationCosts = 4,
  DemolitionCosts = 5,
  OtherCosts = 6,
}

export enum ExploitableIncomeExpensePeriodicities {
  Monthly = 0,
  Yearly = 1,
}

export enum ExternalCommunicationActions {
  NewAppraisal = 0,
  AssignedProcessManager = 1,
  AssignedAppraiser = 2,
  ScheduledVisit = 3,
  StartedVisit = 4,
  FinishedVisit = 5,
  DataSent = 6,
  AssignedTechnicalManager = 7,
  ValidationStarted = 8,
  ValidationFinished = 9,
  DeliveryToCustomer = 10,
  IncidentCreated = 11,
  ResolvedIncident = 12,
  NewOrderRequestReceived = 13,
  NewOrderRequestCreated = 14,
  NewOrderRequestConfirmationReadByBank = 15,
  OrderRequestPendingPayment = 16,
  OrderRequestPaid = 17,
  NewClaimReceived = 18,
  NewClaimCreated = 19,
  ClaimResolved = 20,
  ConditionalsSent = 21,
  ConditionalsReceived = 22,
  ConditionalsReceivedRead = 23,
  CancellationSent = 24,
  CancellationReceived = 25,
  CancellationReceivedRead = 26,
  ClaimNotProceed = 27,
  IncidentReceived = 28,
  QuataxSend = 29,
  QuataxReceived = 30,
  AutorizationReceived = 32,
  ProcessIncomingFiles = 33,
  AutorizationInvalidValidacionSent = 34,
  AutorizationInvalidValidacionReceived = 35,
  AutorizationInvalidValidacionReceivedRead = 36,
  SimpleNotesSent = 37,
  ResultadoDatosTasaReceived = 38,
  DeliveryToCustomerForValidation = 39,
  ResultadoDatosTasaReaded = 40,
  DeliveryOSEToCustomerForValidation = 41,
  ResolvedPaymentIncidents = 42,
  FinSolicitud = 43,
  DriveByFileProcessedSuccessfully = 44,
  AdditionalFilesReceived = 45,
  ErrorInDelivery = 46,
  SendIncidentsAfter24HoursWithoutPayment = 47,
  CommunicationCreated = 48,
  CommunicationReceived = 49,
  SalesforceCommunication = 50,
  ApiValSmartaCommunication = 51,
}

export enum ExternalCommunicationStates {
  Sent = 0,
  ReceivedOk = 1,
  ReceivedKo = 2,
  ProcessedKo = 3,
  ProcessedOk = 4,
  OtherError = 5,
  BlockedByOtherProcess = 6,
  GeneratingSentError = 7,
}

export enum ExternalCommunicationTypes {
  Report = 0,
  Incident = 1,
  Claim = 2,
  OrderRequest = 3,
}

export enum ExternalWoodworksTypes {
  PVCCarpentryDoubleGlazedGlass = 0,
  LaqueredAluminium = 1,
  AnodizedAluminiumGlazedGlass = 2,
  AnodizedAluminiumSimpleGlass = 3,
  IronAndSimpleGlass = 4,
  LacqueredAluminumCarpentrySimpleGlass = 5,
  PaintedWoodworkSimpleGlass = 6,
  PaintedWoodworkSimpleClimalit = 7,
  VarnishedWoodworkSimpleGlass = 8,
  VarnishedWoodworkSimpleClimalit = 9,
  Unknown = 10,
  Others = 11,
}

export enum FileTypes {
  Documents = 0,
  Pictures = 1,
}

export enum GroundLevelTypes {
  Level1 = 1,
  Level2 = 2,
}

export enum GroundRepercussionCalculateMethods {
  Manual = 0,
  StaticAbriddedResidual = 1,
  StaticResidual = 2,
  DynamicResidual = 3,
  ComparationMethod = 4,
}

export enum HeatingTypes {
  IndividualGas = 0,
  IndividualElectric = 1,
  IndividualOthers = 2,
  Community = 3,
  No = 4,
}

export enum HomogeneousCoefficientConcept {
  Situation = 0,
  Orientation = 1,
  State = 2,
  Height = 3,
  Surface = 4,
  Morphology = 5,
  Polyvalence = 6,
  Accessibility = 7,
  Antiquity = 8,
  Others = 9,
  Slope = 10,
}

export enum HomogeneousCoefficientType {
  Global = 0,
  BrokenDown = 1,
}

export enum HotelCategory {
  Stars5 = 0,
  Stars4 = 1,
  Stars3 = 2,
  Stars2 = 3,
  Stars1 = 4,
  Hostel3stars = 5,
  Hostel2stars = 6,
  Hostel1stars = 7,
}

export enum HotWaterTypes {
  IndividualGas = 0,
  IndividualElectric = 1,
  IndividualOthers = 2,
  Community = 3,
  No = 4,
}

export enum IndustrialEnvironments {
  Inside = 0,
  Outside = 1,
  None = 2,
}

export enum IndustrialLocationTypes {
  City = 0,
  IndustrialEstate = 1,
  IsolatedArea = 2,
}

export enum InsuranceValorationTypes {
  CurrentValue = 0,
  HETValue = 1,
}

export enum InteriorExteriorTypes {
  Internal = 0,
  External = 1,
  NoApply = 2,
}

export enum InternalWoodWork {
  VarnishedWoodCarpentry = 1,
  LacqueredDMBoardCarpentry = 2,
  WoodVeneerCarpentry = 3,
  SheetMetalwork = 4,
  Unknown = 5,
  PaintedWoodwork = 6,
  Others = 7,
}

export enum InvoiceRevisionState {
  PendingToSendToInvoicer = 0,
  SentToRevisionToInvoicer = 1,
  ConfirmedByInvoicer = 2,
  RejectedByInvoicer = 3,
}

export enum LegalSituationTypes {
  Free = 1,
  Protected = 2,
  DoesNotApply = 3,
}

export enum LicenseeUserConnectionRequestErrors {
  SharingIdNotFound = 0,
  AlreadyRelated = 1,
  PendingConnectionRequest = 2,
  InvalidSharingId = 3,
}

export enum LicenseTypes {
  WithoutLicense = 0,
  WithOccupancyCertificateOrFirstOccupationLicense = 1,
  WithoutOccupancyCertificateOrFirstOccupationLicense = 2,
  WithOperatingLicense = 3,
  WithoutOperatingLicense = 4,
  WithUnexpiredBuildingLicense = 5,
  WithExpiredBuildingLicense = 6,
}

export enum LocationComparedToSameBuildingElements {
  AboveAverageLevel = 0,
  MediumLevel = 1,
  BelowAverageLevel = 2,
}

export enum LocationComparedToSameMunicipality {
  AboveAverageLevel = 0,
  MediumLevel = 1,
  BelowAverageLevel = 2,
}

export enum LocationComparedToSameZone {
  AboveAverageLevel = 0,
  MediumLevel = 1,
  BelowAverageLevel = 2,
}

export enum LocationConfirmedByTypes {
  ConfirmedByUser = 0,
  ConfirmedByGoogle = 1,
  ConfirmedByCadastral = 2,
}

export enum LoginPlatforms {
  WebAdmin = 0,
  App = 1,
  WebCustomer = 2,
}

export enum LogItemActions {
  Created = 0,
  Updated = 1,
  Deleted = 2,
}

export enum ManagementPhases {
  RegisteredResultingPlots = 0,
  FinalApprovationOfEquidistribution = 1,
  InitialApprovationOfEquidistribution = 2,
  BoardConstitution = 3,
  PendingDelimitationOfExecutionUnit = 4,
  Unknown = 5,
}

export enum ManagementSystemTypes {
  License = 0,
  UrbanAgentOrConcert = 1,
  Compensation = 2,
  Cooperation = 3,
  Expropiation = 4,
  WhithoutManagement = 5,
}

export enum NotificationSubTypes {
  Others = 0,
}

export enum NotificationTypes {
  Others = 0,
}

export enum ObservationSectionTypes {
  General = 1,
  Other = 2,
}

export enum ObservationTypes {
  O_GEN01 = 1,
  O_OTR01 = 2,
  O_GEN02 = 3,
  O_GEN03 = 4,
}

export enum OccupationStates {
  Free = 0,
  InRentOrUnderOtherLegalTitle = 1,
  OccupiedWithoutTitle = 2,
}

export enum OcupationStates {
  Unknown = 0,
  Unoccupied = 1,
  Occupied = 2,
}

export enum OcupationTypes {
  Owner = 0,
  Tenant = 1,
  BeneficialOwnership = 2,
  Precarious = 3,
  TenantFutureOwner = 4,
  Okupa = 5,
}

export enum OfferTypes {
  Trading = 1,
  Rent = 2,
}

export enum OrientationTypes {
  IncreaseValue = 0,
  NoInfluence = 1,
  DecreaseValue = 2,
  NoApply = 3,
}

export enum PavementTypes {
  Parquet = 0,
  Stoneware = 1,
  Pallet = 2,
  Terrazzo = 3,
  NaturalStone = 4,
  Other = 6,
  CeramicTile = 7,
  ArtificialStone = 8,
  ContinuousFloor = 9,
  Microcement = 10,
  PolishedConcrete = 11,
  Carpet = 12,
  Raw = 13,
  Unknown = 14,
  VinylFloor = 15,
}

export enum PaymentMethods {
  CreditCard = 0,
  Bizum = 1,
  BankTransfer = 2,
}

export enum PaymentOptions {
  Direct = 0,
  Indirect = 1,
  Mixed = 2,
}

export enum PaymentStates {
  RequestSuccessful = 0,
  InProcess = 1,
  SuccessfulPayment = 2,
  DeniedPayment = 3,
  CancelledByTimeout = 4,
  CancelledByNewRequest = 5,
  SuccessfulPaymentOnCancelled = 6,
}

export enum PeriodTypes {
  Monthly = 0,
  Biannual = 1,
  Annual = 2,
}

export enum PolivalentUses {
  ExclusiveBuildingInDowntown = 0,
  ExclusiveBuildingNearDowntown = 1,
  ExclusiveBuildingFarFromDowntown = 2,
  MixedBuildingInDowntown = 3,
  MixedBuildingNearDowntown = 4,
  MixedBuildingFarFromDowntown = 5,
}

export enum ProductToDevelopTypes {
  ResidentialProtected = 0,
  ResidentialFree1Residence = 1,
  ResidentialFree2Residence = 2,
  Offices = 3,
  CommercialPremises = 4,
  Industrial = 5,
  Hotel = 6,
  StudentResidenceOrEldery = 7,
  Garage = 8,
  Recreational = 9,
  Other = 10,
}

export enum PropertySizes {
  Suitable = 0,
  HigherThanRequested = 1,
  IndividualOthers = 2,
}

export enum PropertyStates {
  Reformed = 0,
  Origin = 1,
  RehabilitationNeeded = 2,
  NoApply = 3,
}

export enum PublicProtections {
  Vpo = 0,
  WithoutProtection = 1,
}

export enum QuantifyOptions {
  Abundant = 0,
  Medium = 1,
  Low = 2,
  None = 3,
}

export enum ReportActions {
  NoEditable = 0,
  Editable = 1,
}

export enum ResidenceTypes {
  FirstResidence = 1,
  SecondResidence = 2,
}

export enum SmtpDomains {
  IvSolutions = 1,
  Tasafy = 2,
}

export enum SourceTypes {
  Particular = 0,
  EstateAgent = 1,
  Agency = 2,
}

export enum StayTypes {
  Hall = 4,
  LivingRoom = 5,
  DinningRoom = 6,
  Terrace = 7,
  Kitchen = 8,
  Bedroom = 10,
  Bathroom = 11,
  StorageRoom = 13,
  Salon = 14,
  Other = 17,
  Distributor = 18,
  LivingRoomKitchen = 19,
  CleaningRoom = 20,
  Porch = 21,
  ParkingPlace = 22,
  Despacho = 23,
  MultipurposeRoom = 24,
  Local = 25,
  SortingDepot = 26,
  Office = 27,
  Unknow = 28,
}

export enum StreetCategoryTypes {
  MunicipalityArtery = 0,
  ImportantRoad = 1,
  SecondaryRoad = 2,
  ThirdOrderRoad = 3,
}

export enum StreetTypes {
  Mall = 0,
  Street = 1,
  Way = 2,
  Road = 3,
  Roundabout = 4,
  Passage = 5,
  Promenade = 6,
  Square = 7,
  Round = 8,
  Crossing = 9,
  Avenue = 10,
  Field = 11,
  Hill = 12,
  Park = 13,
  Boulevard = 14,
  Kilometer = 15,
  Plot = 16,
  Sector = 17,
  HeadingStreet = 18,
  Disseminated = 19,
  Neighborhood = 20,
  Urbanization = 21,
  IndustrialZone = 22,
  Place = 23,
  BoulevardAvenue = 24,
  Group = 25,
}

export enum SurveillanceTypes {
  Doorman = 0,
  SurveillanceCameras = 1,
  Surveillance24Hour = 2,
  DontHave = 3,
}

export enum SwimmingPoolTypes {
  No = 0,
  Yes = 1,
  Communal = 2,
}

export enum TargetTypes {
  Urban = 0,
  Vacational = 1,
}

export enum TenureTypes {
  FullControl = 0,
  Usufruct = 1,
  BareOwnership = 2,
}

export enum TerrainExpenseCalculationTypes {
  PercentageByIncomes = 0,
  PercentageByConstructionCosts = 1,
  Manual = 2,
}

export enum GroundQualifications {
  Residencial,
  Commercial,
  Tertiary,
  Industrial,
  Dotational,
}

export enum TerrainPlanningDevelopmentTypes {
  UrbanizationReceivedByTownHall = 0,
  FinishedUrbanizationNotReceivedByTownHall = 1,
  ApprovedUrbanizationProject = 2,
  PlanningDevelopmentDefinitivelyApproved = 3,
  PlanningDevelopmentInitiallyApproved = 4,
  PlanningDevelopmentNotRedactedOrApproved = 5,
}

export enum UnitMeasurementTypes {
  Surface = 0,
  Time = 1,
  Length = 2,
}

export enum UrbanCoreProximityTypes {
  UrbanGround = 0,
  UrbanGrounAdjadentToUrban = 1,
  UrbanGrounNotAdjadentToUrbanWithAccess = 2,
  UrbanGrounNotAdjadentToUrbanWithoutAccess = 3,
}

export enum UrbanisticSituations {
  CurrentPlanning = 0,
  ApplicableOrdinance = 1,
}

export enum UrbanizationStates {
  NoProject = 0,
  ProjectNotStarted = 1,
  UrbanizationInExecution = 2,
  CompletedUrbanization = 3,
}

export enum ValidationTypes {
  FoxeetGeneral = 0,
  LiberBank__ECO_805_2003 = 1,
  SIVASA_ECO_805_2003 = 2,
  Caixabank_ECO_805_2003 = 3,
  KutxaBank_ECO_805_2003 = 4,
  ECO_805_2003 = 5,
  EVO_ECO_805_2003 = 6,
  BBVA_ECO_805_2003 = 7,
}

export enum ValorationLegalMaximumOptions {
  UnitValue = 0,
  Value = 1,
  MainAssetPercent = 2,
}

export enum ValorationMethods {
  ComparationMethod = 0,
  CostMethod = 1,
  CrawledMethod = 2,
  DiscountCashflow = 3,
  ContractCashflowMethod = 4,
  LegalMaximumMethod = 5,
  OtherMethod = 6,
  StaticResidual = 7,
  DynamicResidual = 8,
  DynamicResidualRelated = 9,
  EconomicExploitation = 10,
  RusticEstate = 11,
}

export enum ValuableIdTypes {
  Asset = 0,
  Cluster = 1,
  BuildingElement = 2,
}

export enum WallTypes {
  Painting = 0,
  Tilling = 2,
  Wallpaper = 3,
  Paneled = 4,
  Other = 5,
  WoodPaneling = 6,
  GotelePaint = 7,
  StonewareTiling = 8,
  CeramicTile = 9,
  NaturalStoneVeneer = 10,
  ArtificialStoneVeneer = 11,
  Plastering = 12,
  PaintedPlaster = 13,
  ExposedBrick = 14,
  MonolayerMortar = 15,
  TextileCoating = 16,
  Plaster = 17,
  Raw = 18,
  Unknown = 19,
  Mixed = 20,
}

export enum WarningSectionTypes {
  General = 1,
  Purpose = 2,
  Identification = 3,
  Documentation = 4,
  Methodology = 5,
  Surface = 6,
  RegistryInscription = 7,
  Burden = 8,
  RealRights = 9,
  Urbanism = 10,
  Other = 11,
  UseOrExploitationDocumentation = 12,
  WorkplanDocumentation = 13,
  OtherDocumentation = 14,
  Differences = 15,
  MarketSituations = 16,
  SIVASA = 17,
}

export enum WarningTypes {
  AGEN01 = 1,
  AGEN02 = 2,
  AFIN01 = 3,
  AFIN02 = 4,
  AFIN03 = 5,
  AFIN04 = 6,
  AFIN05 = 7,
  AIDE01 = 8,
  AIDE02 = 9,
  AIDE03 = 10,
  ADOC01 = 11,
  ADOC02 = 12,
  ADOC03 = 13,
  ADOC04 = 14,
  ADOC05 = 15,
  ADOC06 = 16,
  ADOC07 = 17,
  ADOC08 = 18,
  ADOC09 = 19,
  ADOC10 = 20,
  ADOC11 = 21,
  ADOC12 = 22,
  ADOC13 = 23,
  ADOC14 = 24,
  ADOC15 = 25,
  ADOC16 = 26,
  ADOC17 = 27,
  ADOC18 = 28,
  ADOC19 = 29,
  ADOC20 = 30,
  ADOC21 = 31,
  ADOC22 = 32,
  ADOC23 = 33,
  ADOC24 = 34,
  ADOC25 = 35,
  ADOC26 = 36,
  ADOC27 = 37,
  AMET01 = 38,
  AMET02 = 39,
  AMET03 = 40,
  AMET04 = 41,
  AMET05 = 42,
  AMET06 = 43,
  AMET07 = 44,
  AMET08 = 45,
  AMET09 = 46,
  AMET10 = 47,
  AMET11 = 48,
  AMET12 = 49,
  AMET13 = 50,
  AMET14 = 51,
  AMET15 = 52,
  AMET16 = 53,
  ASUP01 = 54,
  ASUP02 = 55,
  ASUP03 = 56,
  ASUP04 = 57,
  ASUP05 = 58,
  ASUP06 = 59,
  ASUP07 = 60,
  ASUP08 = 61,
  ASUP09 = 62,
  AREG01 = 63,
  AREG02 = 64,
  AREG03 = 65,
  ACAR01 = 66,
  ACAR02 = 67,
  ACAR03 = 68,
  ADER01 = 69,
  AURB01 = 70,
  AURB02 = 71,
  AURB03 = 72,
  AURB04 = 73,
  AURB05 = 74,
  AURB06 = 75,
  AURB07 = 76,
  AURB08 = 77,
  AOTR = 78,
  AEV_DUE3_A2 = 79,
  AEV_DUE4_A2 = 80,
  AEV_DUE5_A2 = 81,
  AEV_DUE7_A1 = 82,
  AEV_DUE9_A2 = 83,
  AEV_DPO4_A3 = 84,
  AEV_DPO6_A1 = 85,
  AEV_DPO7_A1 = 86,
  AEV_DPO8_A1 = 87,
  AEV_DPO9_A1 = 88,
  AEV_DPO11_A2 = 89,
  AEV_ODC3_A1 = 90,
  AEV_ODC4_A1 = 91,
  AEV_DIF1_A1 = 92,
  AEV_DIF2_A1 = 93,
  AEV_DIF3_A1 = 94,
  AEV_URB1_A2 = 95,
  AEV_URB2_A2 = 96,
  AEV_URB3_A1 = 97,
  AEV_URB4_A3 = 98,
  AEV_OTR1_A1 = 99,
  AEV_OTR2_A3 = 100,
  AEV_OTR3_A1 = 101,
  AEV_OTR4_A1 = 102,
  AEV_OTR5_A1 = 103,
  AEV_OTR6_A1 = 104,
  AEV_OTR7_A1 = 105,
  AEV_OTR8_A1 = 106,
  AEV_MER1_A1 = 107,
  AEV_MER2_A1 = 108,
  AEV_MER3_A1 = 109,
  AEV_ADV51_DPO51 = 110,
  AEV_OTRX_A = 111,
  AEV_ODC2_A1 = 112,
  AEV_ADV51_DUE51 = 113,
  AEV_ADV51_ODC51 = 114,
  AEV_ADV51_DR51 = 115,
  AEV_ADV51_URB51 = 116,
  AEV_ADV51_OTR51 = 117,
  AEV_CP4_A1 = 118,
  A16 = 119,
  A132 = 120,
}

export enum WorkflowItemLogItemActions {
  Create = 0,
  Update = 1,
  Delete = 3,
  StartTask = 4,
  FinishTask = 5,
  Solved = 6,
  Successed = 7,
  Failed = 8,
}

export enum WorkflowItemLogItemTypes {
  Others = 0,
  Layout = 1,
  MainInfoEdited = 2,
  Incident = 3,
  Reclamation = 4,
  Cancelled = 5,
  Validation = 6,
  Files = 7,
  Revision = 8,
}

export enum WorkPlanDocumentationOptions {
  Yes = 0,
  No = 1,
  NotProvided = 2,
}

export enum WorkPlanStateTypes {
  TerrainMovement = 0,
  Sanitation = 1,
  Foundations = 2,
  Structure = 3,
  Brickwork = 4,
  Coatings = 5,
  CoversAndWaterproofing = 6,
  CarpentryAndLocksmithing = 7,
  ElectricityAndTelecomunications = 8,
  PlumberAndSanitations = 9,
  Paint = 10,
  QualityControlAndTests = 11,
  Various = 12,
}

export enum AssetCountry {
  Spain = 1,
  Portugal = 2,
}

export enum AssetRooms {
  T1 = 1,
  T2 = 2,
  T3 = 3,
  T4 = 4,
  T5 = 5,
}

export enum AssetStates {
  Finished = 0,
  Unfinished = 1,
}

export enum AssetSubTypologies {
  Terraced = 1,
  IsolatedHouse = 2,
  Paired = 3,
  NoExteriorAccess = 4,
  ExteriorAccess = 5,
  Flat = 6,
  Duplex = 7,
  Penthouse = 8,
  Undercover = 9,
  Other = 10,
  FoodServices = 11,
  Commercial = 12,
  Services = 13,
  Isolated = 14,
  Annexed = 15,
  Forest = 18,
  Consolidated = 22,
  NotConsolidated = 23,
  Education = 24,
  Sectorized = 25,
  NotSectorized = 26,
  Organized = 66,
  Extensive = 69,
  Intensive = 70,
  SemiIntensive = 71,
  SemiExtensive = 72,
  FruitTrees = 73,
  Herbaceous = 74,
  Cereals = 75,
  Horticultural = 76,
  BigGame = 77,
  SmallGame = 78,
  FishFarm = 79,
  FishingRange = 80,
}

export enum AssetTypologies {
  Others = 0,
  ResidentialMultiFamily = 1,
  ResidentialSingleFamily = 2,
  BusinessPremises = 3,
  CommercialMedian = 4,
  Office = 5,
  SortingDepot = 6,
  Garage = 7,
  StorageRoom = 8,
  FuelStation = 9,
  Hotel = 10,
  Cultural = 11,
  Sanitary = 12,
  Assistance = 13,
  Religious = 14,
  Sports = 15,
  Terrace = 16,
  Unproductive = 18,
  Urban = 20,
  Developable = 21,
  NotDevelopable = 22,
  Warehouse = 23,
  StudentResidence = 24,
  ThirdAgeResidence = 25,
  Educational = 26,
  Parking = 27,
  Dry = 28,
  HuntingAndFishing = 29,
  Livestock = 30,
  Irrigation = 31,
  Aparthotel = 32,
  SocialProperty = 33,
  CinemaTheaterNightclub = 34,
  CountryHouse = 35,
}

export enum AssetTypologyCombination {
  ResidentialMultiFamilyFlat = 0,
  ResidentialMultiFamilyDuplex = 1,
  ResidentialMultiFamilyPenthouse = 2,
  ResidentialMultiFamilyUndercover = 3,
  ResidentialMultiFamilyOther = 4,
  ResidentialSingleFamilyAnnexed = 5,
  ResidentialSingleFamilyPaired = 6,
  ResidentialSingleFamilyIsolated = 7,
  OfficeNoExteriorAccess = 8,
  OfficeExteriorAccess = 9,
  BusinessPremisesFoodServices = 10,
  BusinessPremisesCommercial = 11,
  BusinessPremisesServices = 12,
  BusinessPremisesOther = 13,
}

export enum AssetUses {
  Residential = 0,
  Commercial = 1,
  Tertiary = 2,
  Industrial = 3,
  Annexed = 4,
  RusticEstate = 5,
  Terrain = 6,
  Dotational = 7,
}

export enum IdealistaOperation {
  Sale = 1,
  Rent = 2,
  RentTown = 3,
}

export enum IdealistaBuiltType {
  NewDevelopment = 1,
  SecondHandToRestore = 2,
  SecondHandGoodCondition = 3,
}

export enum AppraisalSteps {
  Created_PendingAssignProcessManager = 1,
  AssignedProcessManager_PendingAssignAppraiser = 2,
  AssignedAppraiser_PendingDateForVisit = 3,
  VisitDated_PendingStartVisit = 4,
  VisitStarted_PendingFinishVisit = 5,
  VisitFinished_PendingSendData = 6,
  DataSent_PendingAssignManagerTechnical = 7,
  AssignedManagerTechnical_PendingStartTechnicalValidation = 8,
  TechnicalValidationStarted_PendingTechnicalValidationFinished = 9,
  TechnicalValidationFinished_PendingEconomicValidation = 10,
  EconomicValidationFinished_Finished = 11,
}

export enum AppraisalCustomerSteps {
  Created_PendingAssignAppraiser = 1,
  AssignedAppraiser_PendingDateForVisit = 2,
  VisitDated_PendingStartVisit = 3,
  VisitStarted_PendingFinishVisit = 4,
  VisitFinished_PendingSendData = 5,
  DataSent_PendingStartValidation = 6,
  ValidationStarted_PendingValidationFinished = 7,
  SentToCustomer = 8,
}

export enum OrderRequestSteps {
  PendingAcceptanceBudget = 1,
  OrderAssigned = 5,
  OrderGenerated = 2,
  ValorationsCreated = 3,
  AllValorationsFinished_Finished = 4,
}

export enum RegistryRequestSteps {
  Created = 1,
  SentToRegister = 2,
  ReceivedFromRegister = 3,
  DeliveredToClient = 4,
}

export enum ValidationAppraisalSteps {
  Created_PendingAssignProcessManager = 1,
  AssignedProcessManager_PendingAssignTechnicalManager = 2,
  AssignedTechnicalManager_PendingAssignAppraiser = 3,
  AssignedAppraiser_PendingStartValidation = 4,
  ValidationStarted_PendingValidationFinished = 5,
  ValidationFinished_PendingSendToCustomer = 6,
  SentToCustomer = 7,
}

export enum LicenseeTypes {
  Customer = 0,
  SupplierCompany = 1,
  RequesterCompany = 2,
}

export enum LicenseeUserRoles {
  ProcessManager = 0,
  TechnicalManager = 1,
  CommercialManager = 2,
  Appraiser = 3,
  CustomerBasic = 4,
  CustomerAdvanced = 5,
  Root = 6,
  External,
}

export enum WorkerRoles {
  ProcessManager = 0,
  TechnicalManager = 1,
  CommercialManager = 2,
  Appraiser = 3,
  Technical = 4,
}

export enum AntecedentTypes {
  SameElement = 0,
  SameBuilding = 1,
  SameStreet = 2,
  SameArea = 3,
}

export enum SituationsWithinArea {
  Good = 0,
  Neutral = 1,
  Bad = 2,
}

export enum SocioEconomicLevels {
  High = 0,
  MediumHigh = 1,
  Medium = 2,
  MediumLow = 4,
  Low = 5,
}

export enum FastTrackProximities {
  Instant = 0,
  Nearby = 1,
  Far = 2,
}

export enum BuildingTypes {
  EE_CN = 0,
  EM_CN = 1,
  EE_near_CN = 2,
  EM_near_CN = 4,
  FarCN = 5,
}
export enum AppraisalExternalCommunicationProcessStates {
  InProcess = 0,
  UserActionRequired = 1,
  FinishedOrReviewed = 2,
}

export enum ContractIds {
  Sivasa = 2,
  CaixaBank = 3,
  EvoBorsan = 4,
  CajaRural = 5,
  ServiHabitat = 6,
  Kutxabank = 7,
  BuildingCenter = 8,
  Unicaja = 9,
  Bbva = 10,
  Deutschebank = 11,
  Salesforce = 12,
  Ibercaja = 13,
  ApiValSmarta = 14,
}

/**
 * @todo added manually, for tasafy
 */
export enum Languages {
  es = 0,
  en = 1,
  pt = 2,
}

export enum ReportLanguages {
  es = 0,
  en = 1,
}

/**
 * @todo added manually, for tasafy
 */
export enum RedirectionComponents {
  PaymentPlan = 0,
  PaymentMethod = 1,
}

export enum AcademyCourseDocumentTypes {
  Others = 0,
}

export enum AcademyCourseDocumentTypologies {
  Blueprints = 0,
}

export enum ConnectionActions {
  Accept = 0,
  Reject = 1,
  Cancel = 2,
}

export enum LicenseeReportCustomizeTypes {
  Logo = 0,
  Signature = 1,
  ImageHeader = 2,
}

export enum LicensePlanChangeTypes {
  Downgrade = 0,
  Same = 1,
  Upgrade = 2,
}

export enum LicensePlans {
  Basic = 1,
  Expert = 2,
  BasicClient = 3,
  Trial = 5,
  Premium = 6,
}

export enum EnergyCertificationNormatives {
  DoesNotApply = 0,
  NBE_CT_79 = 1,
  CTE_HE_2009 = 2,
  CTE_HE_2013 = 3,
  CTE_HE_2019 = 4,
}

export enum YesNoNoApplies {
  Yes = 0,
  No = 1,
  DoesNotApply = 2,
}

export enum EnergyCertificationLetters {
  A,
  B,
  C,
  D,
  E,
  F,
  G,
}

export enum VinculationTypes {
  Internal = 0,
  External = 1,
}

export enum AppraisalMigrationOrigins {
  Borsan = 0,
}

export enum ValorationKeyValueTypes {
  Currency = 0,
  Surface = 1,
  CurrencySurfaceValue = 2,
  Percentage = 3,
  Observation = 4,
  Double = 5,
  Integer = 6,
  None = 7,
  DateTime = 8,
  List = 9,
}

export enum FloorTypes {
  Minus9 = 108,
  Minus8 = 107,
  Minus7 = 106,
  Minus6 = 105,
  Minus5 = 104,
  Minus4 = 103,
  Minus3 = 102,
  Minus2 = 101,
  Minus1 = 100,

  Basement = 0,
  Mezzanine = 109,
  GroundFloor = 110,
  Principal = 111,

  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10,
  Eleven = 11,
  Twelve = 12,
  Thirteen = 13,
  Fourteen = 14,
  Fifteen = 15,
  Sixteen = 16,
  Seventeen = 17,
  Eighteen = 18,
  Nineteen = 19,
  Twenty = 20,
  TwentyOne = 21,
  TwentyTwo = 22,
  TwentyThree = 23,
  TwentyFour = 24,
  TwentyFive = 25,
  TwentySix = 26,
  TwentySeven = 27,
  TwentyEight = 28,
  TwentyNine = 29,
  Thirty = 30,
  ThirtyOne = 31,
  ThirtyTwo = 32,
  ThirtyThree = 33,
  ThirtyFour = 34,
  ThirtyFive = 35,
  ThirtySix = 36,
  ThirtySeven = 37,
  ThirtyEight = 38,
  ThirtyNine = 39,
  Forty = 40,
  FortyOne = 41,
  FortyTwo = 42,
  FortyThree = 43,
  FortyFour = 44,
  FortyFive = 45,
  FortySix = 46,
  FortySeven = 47,
  FortyEight = 48,
  FortyNine = 49,
  Fifty = 50,
  FiftyOne = 51,
  FiftyTwo = 52,
  FiftyThree = 53,
  FiftyFour = 54,
  FiftyFive = 55,
  FiftySix = 56,
  FiftySeven = 57,
  FiftyEight = 58,
  FiftyNine = 59,
  Sixty = 60,
  SixtyOne = 61,
  SixtyTwo = 62,
  SixtyThree = 63,
  SixtyFour = 64,
  SixtyFive = 65,
  SixtySix = 66,
  SixtySeven = 67,
  SixtyEight = 68,
  SixtyNine = 69,
  Seventy = 70,
  SeventyOne = 71,
  SeventyTwo = 72,
  SeventyThree = 73,
  SeventyFour = 74,
  SeventyFive = 75,
  SeventySix = 76,
  SeventySeven = 77,
  SeventyEight = 78,
  SeventyNine = 79,
  Eighty = 80,
  EightyOne = 81,
  EightyTwo = 82,
  EightyThree = 83,
  EightyFour = 84,
  EightyFive = 85,
  EightySix = 86,
  EightySeven = 87,
  EightyEight = 88,
  EightyNine = 89,
  Ninety = 90,
  NinetyOne = 91,
  NinetyTwo = 92,
  NinetyThree = 93,
  NinetyFour = 94,
  NinetyFive = 95,
  NinetySix = 96,
  NinetySeven = 97,
  NinetyEight = 98,
  NinetyNine = 99,
}

export enum InvoiceFlowDays {
  Monthly = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10,
  Eleven = 11,
  Twelve = 12,
  Thirteen = 13,
  Fourteen = 14,
  Fifteen = 15,
  Sixteen = 16,
  Seventeen = 17,
  Eighteen = 18,
  Nineteen = 19,
  Twenty = 20,
  TwentyOne = 21,
  TwentyTwo = 22,
  TwentyThree = 23,
  TwentyFour = 24,
  TwentyFive = 25,
  TwentySix = 26,
  TwentySeven = 27,
  TwentyEight = 28,
  TwentyNine = 29,
  Thirty = 30,
  ThirtyOne = 31,
}

export enum InvoiceFlows {
  Monthly = 0,
  Daily = 1,
}

export enum SuitableTypes {
  NotSuitable = 0,
  Suitable = 1,
}

export enum ComparableSources {
  ManualCreated = 0,
  Idealista = 1,
  OfferAsset = 2,
  WorkflowItemReportAsset = 3,
}

export enum ValuationMassiveStates {
  NoActionAllowed = 0,
  Creatable = 1,
  Editable = 2,
}

export enum CashflowPeriodActionType {
  Added = 0,
  Updated = 1,
  Deleted = 2,
}

export enum CommunicationStates {
  WithoutResponse = 0,
  WithResponse = 1,
}

export enum WallCommentCommunicationTypes {
  Documentation = 1,
  ClarificationsOrder = 2,
  AppraisalSendClaim = 3,
  ActualStateOfOrder = 4,
  Visit = 5,
  Incidents = 6,
  AssetsAndStructure = 7,
  DumpErrors = 8,
  Cancellation = 9,
  DelayAndDeadline = 10,
  PriorityAndUrgency = 11,
  InvoicingAndPayments = 12,
  Tariffs = 13,
  CEE = 14,
  ConditionalsAndWarnings = 15,
  Others = 16,
}

export enum EventType {
  deadLine = 1,
  visit = 2,
  absent = 3,
}

export enum AppraiserVehicleTypes {
  ElecrticVehicle = 0,
  HybridPluggableVehicle = 1,
  HybridVehivle = 2,
  CombustionVehicle = 3,
  PublicTransport = 4,
  BycicleScooterWalking = 5,
}

export enum AppraiserStates {
  Active = 0,
  NotValidated = 1,
  Absent = 2,
  Inactive = 3,
}

export enum AppraisalGradeFilterRanges {
  Grade0 = 0,
  Grade1 = 1,
  Grade2 = 2,
  Grade3 = 3,
  Grade4 = 4,
  Grade5 = 5,
}

export enum AppraisalGradeTypes {
  Elements = 0,
  Buildings = 1,
  Terrains = 2,
  RusticStates = 3,
  EconomicExploitations = 4,
}

export enum AppraisalGradeLevels {
  Level1 = 0,
  Level2 = 1,
  Level3 = 2,
}
export enum WorkflowItemTypes {
  ValidationAppraisal = 3,
  RegistryRequest = 4,
  Appraisal = 1,
  AdditionalOrder = 2,
  OrderRequest = 0,
}

export enum WorkTypes {
  Appraisal = 0,
  QualityControl = 1,
  AdditionalOrders = 2,
  AVM = 3,
}

export enum SupplierCompanies {
  Valum = 0,
  Tinsa = 1,
}

export enum SpanishAppraisalCompanies {
  Valum = 0,
  Tinsa = 1,
  AESVAL = 2,
  AGRUPACION = 3,
  ALIA = 4,
  ARCO = 5,
  ARQUITASA = 6,
  BALKIDE = 7,
  BARNATASA = 8,
  CBRE = 9,
  COLLECTIU = 10,
  COMPANIAHISPANIA = 11,
  EUROVALORACIONES = 12,
  GENERAL = 13,
  GESVALT = 14,
  GLOVAL = 15,
  GRUPOTASVALOR = 16,
  KRATA = 17,
  RISC = 18,
  SAVILLS = 19,
  SERVICIOSVASCOS = 20,
  SOCIEDADDETASACION = 21,
  TASACIONESANDALUZAS = 22,
  TASACIONESHIPOTECARIASRENTA = 23,
  TASACIONESHIPOTECARIAS = 24,
  TASALIASOCIEDAD = 25,
  TASASURSOCIEDAD = 26,
  TASIBERICA = 27,
  TECNITASA = 28,
  UVE = 29,
  VALORACIONESMEDITERRANEO = 30,
  ZEHAZKI = 31,
}
