import { AppId, DevelopEnvironment, Environment, XPlatform } from '@foxeet/domain';
import { VERSION } from './version';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  app: AppId.TASAFY,
  appName: 'TASAFY',
  wikiURL: 'https://wiki.tasafy.app',
  platform: XPlatform.WEB_ADMIN,
  production: false,
  version: VERSION,
  //   defaultEndpoint: 'https://192.168.16.10:5001', // Javi
  //   defaultEndpoint: 'https://localhost:5001', // Javi
  // defaultEndpoint: 'https://192.168.16.25:5001', // Gilbert
  // defaultEndpoint: 'https://192.168.90.24:5001', // Alfredo
  // defaultEndpoint: 'https://192.168.90.80:5001', // Alberto CASA
  // defaultEndpoint: 'https://192.168.16.22:5001', // Alberto OFI
  // defaultEndpoint: 'https://192.168.16.37:5001', // David

  // https://192.168.200.41:5001

  envName: DevelopEnvironment.dev,
  defaultEndpoint: 'https://api-dev.tasafy.app',
  devEndpoint: 'https://api-dev.tasafy.app',
  qaEndpoint: 'https://api-qa.tasafy.app',
  prodEndpoint: 'https://api.tasafy.app',
  defaultMobileEndpoint: 'https://mobile-dev.tasafy.app',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiaW5zdGl0dXRvIiwiYSI6ImNrNmF1bHc5aTAxbHUzbXNicmdpYWFuMGcifQ.oLGQ854cghe8ypDxZ1-3HQ',
  },
  /**
   * Next identifier is unique by environment
   */
  googleAnalyticsTrackId: 'G-MRJLE0DXYS',
  hotjarId: '2788478',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
