import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AccountService } from '@foxeet/data-access';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ASYNC_BUTTON_STATE } from '@foxeet/ui';
import { CUSTOM_EMAIL } from '@foxeet/utils/forms';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { PATHS } from '@foxeet/n-core';

@Component({
  selector: 'tas-tasafy-account-activation',
  templateUrl: './tasafy-account-activation.component.html',
  styleUrls: ['./tasafy-account-activation.component.scss'],
})
export class TasafyAccountActivationComponent extends UnsuscriptionHandler {
  public activatedAccount = false;
  public form: FormGroup;
  public stateResendButton$: BehaviorSubject<ASYNC_BUTTON_STATE> = new BehaviorSubject(ASYNC_BUTTON_STATE.IDLE);

  constructor(private _router: Router, private _accountService: AccountService, private _activatedRoute: ActivatedRoute, private _toastWrapper: ToastrWrapperService) {
    super();
    this._activatedRoute.queryParamMap.subscribe((queryParams) => this._activation(queryParams));
  }

  private _activation(queryParams: ParamMap) {
    const email = queryParams.get('email');
    const code = queryParams.get('code');

    if (email && code) {
      this._accountService.accountActivation(email, code).subscribe(
        () => (this.activatedAccount = true),
        (e) => {
          if (e.status === 500) {
            this._buildResendActivationSection();
          } else {
            this._toastWrapper.error('error', 'ACCOUNT_ACTIVATION_defaultError');
            this.goToLogin();
          }
        },
      );
    } else {
      this._buildResendActivationSection();
    }
  }

  private _buildResendActivationSection() {
    this.activatedAccount = false;
    this.form = new FormGroup({ userName: new FormControl(null, [Validators.required, Validators.pattern(CUSTOM_EMAIL.pattern)]) });
  }

  goToLogin() {
    this._router.navigate([`${PATHS.LOGIN}`]);
  }

  resendActivation() {
    this.stateResendButton$.next(ASYNC_BUTTON_STATE.SENDING);
    const userName = this.form.get('userName').value;
    this._accountService
      .resendActivationEmail(userName)
      .pipe(finalize(() => this.stateResendButton$.next(ASYNC_BUTTON_STATE.IDLE)))
      .subscribe(
        (val) => {
          this._toastWrapper.success('ACCOUNT_ACTIVATION_resendSuccess');
        },
        (e) => this._toastWrapper.error('error', 'ACCOUNT_ACTIVATION_resendError'),
      );
  }
}
