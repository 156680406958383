import { Inject, Injectable } from '@angular/core';
import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { OrUndef, UserModel } from '@foxeet/domain';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  GoogleAnalyticsInitializer,
  GoogleAnalyticsRouterInitializer,
  GoogleAnalyticsService,
  GtagFn,
  IGoogleAnalyticsSettings,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NGX_GTAG_FN,
} from '@foxeet/analytics';
import { hotjarInitializer, HotjarRouterInitializer, IHotjarSettings, NGX_HOTJAR_SETTINGS_TOKEN, NgxHotjarService } from '@foxeet/hotjar';
import { DOCUMENT } from '@angular/common';
import { isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from '@foxeet/data-access';
import { takeUntil } from 'rxjs/operators';
import { CookiesManagementComponent } from './cookies-management.component';
import { DynamicOverlay } from '../../../spinner-overlay/services/dynamic-overlay.service';
import { UnsuscriptionHandler } from '@foxeet/utils/components';

@Injectable({ providedIn: 'root' })
export class CookiesManagementService extends UnsuscriptionHandler {
  private _bodyRef: HTMLElement;
  public overlayRef: OrUndef<OverlayRef>;
  user$: BehaviorSubject<UserModel> = new BehaviorSubject(null);

  constructor(
    private dynamicOverlay: DynamicOverlay,
    private _hotjarService: NgxHotjarService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _accountService: AccountService,
    @Inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN) private analyticsConfig: IGoogleAnalyticsSettings,
    @Inject(NGX_HOTJAR_SETTINGS_TOKEN) private hotjarConfig: IHotjarSettings,
    @Inject(NGX_GTAG_FN) private gtagFn: GtagFn,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
    this._bodyRef = document.body;
  }

  showCookies() {
    const cookiesConsent = localStorage.getItem('haveAcceptedCookies');

    if (isNil(cookiesConsent) || !cookiesConsent || !JSON.parse(cookiesConsent)) {
      this._createOverlayRef();
      const overlayPortal = new ComponentPortal(CookiesManagementComponent);
      const overlayRef = this.overlayRef?.attach(overlayPortal);
    }
  }

  updateUser() {
    localStorage.setItem('haveAcceptedCookies', 'true');
    const user = { ...this.user$.value, haveAcceptedCookies: true };
    this._accountService
      .put('', user)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe(() => console.log('exito'));
  }

  closeCookies() {
    this.overlayRef?.detach();
    this.checkCookiesConsent(); // dynamic include modules
  }

  public checkCookiesConsent() {
    const cookiesConsent = localStorage.getItem('haveAcceptedCookies');
    if (cookiesConsent && JSON.parse(cookiesConsent)) {
      this._initializeGoogleAnalytics();
      this._initializeHotjar();
    }
  }

  private _createOverlayRef() {
    const positionStrategy = this.dynamicOverlay
      .position()
      .flexibleConnectedTo(this._bodyRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);

    const scrollStrategy = this.dynamicOverlay.scrollStrategies.block();

    const config: OverlayConfig = {
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      maxWidth: '1px',
      maxHeight: '1px',
    };

    this.overlayRef = this.dynamicOverlay.createWithConfig(this._bodyRef, config);
  }

  private _initializeGoogleAnalytics() {
    console.log('initAnalytics');
    // Initialize google Analytics
    GoogleAnalyticsInitializer(this.analyticsConfig, this.gtagFn, this.document)();
    GoogleAnalyticsRouterInitializer({}, this._googleAnalyticsService);
  }

  private _initializeHotjar() {
    console.log('init hotjar');
    // Initialize hotjar
    hotjarInitializer(this.hotjarConfig, this.document, window)();
    HotjarRouterInitializer(this._hotjarService);
  }
}
