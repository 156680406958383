export const enLang = {
  languageKey: 'en',
  translates: {
    // language selector:
    english: 'English',
    spanish: 'Spanish',
    portuguese: 'Portuguese',

    // TRANSLATES RELATED DIRECTLY WITH URL PATH, DON'T TOUCH THEM.
    dashboard: 'Dashboard',
    profile: 'Profile',
    my_profile: 'My Profile',
    detail: 'Detail',
    edit: 'Edit',
    'billing-info': 'Billinf information',
    'requester-companies': 'Prescribers',
    'supplioer-companies': 'Appraisal companies',
    list: 'List',
    entities: 'Entities',
    // END - TRANSLATES RELATED DIRECTLY WITH URL PATH, DON'T TOUCH THEM.

    // ERRORS
    required: 'Required field',
    not_valid_email: 'Not a valid email',
    // END - ERRORS

    'code-management': 'Code Management',
    'commercial-manager-user': 'Commercial manager user',
    'payment-summary': 'Payment Summary',
    'process-manager-user': 'Process manager user',
    'technical-manager-user': 'Validator user',
    'users-credentials': 'Users with credentials',
    ACADEMY_COURSES_cancelled: 'Cancelled',
    ACADEMY_COURSES_continue_test: 'Continue test',
    ACADEMY_COURSES_finished: 'Complete',
    ACADEMY_COURSES_go_to_detail: 'Test details',
    ACADEMY_COURSES_pending: 'Test not started',
    ACADEMY_COURSES_progress: 'In progress',
    ACADEMY_COURSES_start_test: 'Start the test',
    ACADEMY_CURSES_title: 'My courses',
    ACADEMY_WELCOME_columnDescription1: 'Enter the <b>Academy</b> section available in the upper menu and take the online initiation course to learn about the basic features of Tasafy',
    ACADEMY_WELCOME_columnDescription2: "In the course, you'll find the <b>'Start test'</b> button, that will take you to your first report, where you can apply all the knowledge you have learned",
    ACADEMY_WELCOME_columnDescription3: "When you finish your report, sending it to the <b>'Deliver to customer'</b> step, an <b>expert</b> will review your exercise and leave feedback in the <b>'message board'</b> section",
    ACADEMY_WELCOME_columnDescription4: 'You can correct your appraisal considering the feedback obtained and convince our expert to obtain your Tasafy certification as a property appraiser.',
    ACADEMY_WELCOME_columnTitle1: 'Initiation course',
    ACADEMY_WELCOME_columnTitle2: 'Practical Case',
    ACADEMY_WELCOME_columnTitle3: 'Correction',
    ACADEMY_WELCOME_columnTitle4: 'Qualification',
    ACADEMY_WELCOME_startButton: 'Get started!',
    ACADEMY_WELCOME_subtitle: 'In just 4 steps, you can become a professional appraiser',
    ACADEMY_WELCOME_title: 'Welcome to Tasafy Academy!',
    academy: 'Academy',
    ACCOUNT_ACTIVATION_button: 'Go to Tasafy',
    ACCOUNT_ACTIVATION_message: 'Your account has been activated.',
    ACCOUNT_ACTIVATION_title: 'thank you',
    account_number_for_payments: 'Bank account number for payment',
    ADD_NEW_ASSET_FORM_add: 'Add',
    ADD_NEW_ASSET_FORM_addManualy: 'Add manually',
    ADD_NEW_ASSET_FORM_Block: 'Block',
    ADD_NEW_ASSET_FORM_book: 'Book',
    ADD_NEW_ASSET_FORM_cadastralReference: 'Cadastral reference',
    ADD_NEW_ASSET_FORM_ConstructionYear: 'Age',
    ADD_NEW_ASSET_FORM_Floor: 'Floor',
    ADD_NEW_ASSET_FORM_Gate: 'Door',
    ADD_NEW_ASSET_FORM_idufir: 'C.R.U (IDUFIR)',
    ADD_NEW_ASSET_FORM_inscription: 'Inscription',
    ADD_NEW_ASSET_FORM_location: 'Location',
    ADD_NEW_ASSET_FORM_municipality: 'Municipality',
    ADD_NEW_ASSET_FORM_number: 'Number',
    ADD_NEW_ASSET_FORM_paper: 'Folio',
    ADD_NEW_ASSET_FORM_postalCode: 'Postal Code',
    ADD_NEW_ASSET_FORM_propertyRegister: 'Property Record',
    ADD_NEW_ASSET_FORM_propertyRegisterNumber: 'Registered Estate Number',
    ADD_NEW_ASSET_FORM_province: 'Province',
    ADD_NEW_ASSET_FORM_realState: 'Finca',
    ADD_NEW_ASSET_FORM_Stairs: 'Stair',
    ADD_NEW_ASSET_FORM_streeDoseNotExist: 'Street does not exist',
    ADD_NEW_ASSET_FORM_streetName: 'Path name',
    ADD_NEW_ASSET_FORM_Surface: 'Surface',
    ADD_NEW_ASSET_FORM_Typologies: 'Typology',
    ADD_NEW_ASSET_FORM_Use: 'Use',
    ADD_NEW_ASSET_FORM_volume: 'Tome',
    add_new_prescriptor: '+ Add new prescriptor',
    add_prescriber: 'Add Prescriber',
    addAppraisalToInvoiceWarningAutoGlobal: 'The amount of the invoice will be the one indicated after the selection of the valuations.',
    addAppraisalToInvoiceWarningAutoIndividual: 'The invoice amount will be the sum of the confirmed amounts of the selected valuations.',
    addAppraisalToInvoiceWarningManual: 'The billing data will be included manually after the selection of the valuations',
    AddedDateTime: 'Creation date',
    address: 'Address',
    Address: 'Address',
    addValorationToRequestInvoice: 'Add Appraisal to Invoice',
    All: 'All',
    allInvoiced: 'All invoiced',
    amountsSummary: 'Amounts Summary',
    an_error_has_ocurred: 'An error has occurred',
    APPRAISAL_DASHBOARD_resolvedByAppraiser: 'resolved by the appraiser',
    APPRAISALS_appraiser: 'Appraiser',
    APPRAISALS_externalTech: 'Appraiser',
    APPRAISALS_isExecutedByTasafy: 'Run with Tasafy',
    APPRAISALS_processManager: 'Process manager',
    APPRAISALS_reasonToChooseTech: 'Please tell us your reasons for choosing this appraiser:',
    APPRAISALS_saveInfo: '*When you click to save, the file will be charged automatically.',
    APPRAISALS_selectManager: 'Select process manager',
    APPRAISALS_selectProfessional: 'Select appraiser',
    APPRAISALS_selectValidator: 'Select validator',
    APPRAISALS_techManager: 'Validator',
    appraisals: 'Appraisals',
    AppraisalStatus_1: '1 - REGISTER',
    AppraisalStatus_10: '10 - VALIDATED',
    AppraisalStatus_11: '11 - DELIVERED TO CLIENT',
    AppraisalStatus_2: '2 - PENDING TO ASSIGN TECHNICIAN',
    AppraisalStatus_3: '3 - ASSIGNED TO TECHNICIAN',
    AppraisalStatus_4: '4 - VISIT SCHEDULED',
    AppraisalStatus_5: '5 - VISIT STARTED',
    AppraisalStatus_6: '6 - VISITED',
    AppraisalStatus_7: '7 - REPORT DELIVERED',
    AppraisalStatus_8: '8 - ASSIGNED TO VALIDATOR',
    AppraisalStatus_9: '9 - IN VALIDATION PROCESS',
    appraisalsWithInvoice: 'Appraisals with invoice',
    appraiser: 'Appraiser',
    Appraiser: 'Professionals',
    assessments: 'Appraisals',
    bank_account_number_for_payment: 'Bank Account Number for payment',
    billing_info: 'Billing information',
    billingStatus: 'Billing status',
    BillingTaxType: 'Tax Type',
    billingType: 'Billing type',
    cancel: 'Cancel',
    cancelledDate: 'Cancelled date',
    change_picture: 'Change Picture',
    cif_nif: 'NIF/NIE/Passport/CIF',
    cif: 'CIF',
    close: 'Close',
    code: 'Code',
    Code: 'Code',
    commercial_managers: 'Commercial manager users',
    CommercialManager: 'Commercial Managers',
    confirm: 'Confirm',
    skip: 'skip',
    confirmInvoice: 'Confirm invoice',
    CurrentWorkflowStep: 'Status',
    Customer: 'Customer',
    customers: 'Customers',
    DELIVERABLE_MODAL_deliverable: 'Deliverable',
    DELIVERABLE_MODAL_documentName: 'Document name',
    DELIVERABLE_MODAL_documentVersion: 'Document version',
    DELIVERABLE_MODAL_downloadAll: 'Download all',
    DELIVERABLE_MODAL_downloading: 'Downloading',
    DELIVERABLE_MODAL_emissionDate: 'Issue date',
    DELIVERABLE_MODAL_expirationDate: 'Expiration date',
    DELIVERABLE_MODAL_external: 'External',
    DELIVERABLE_MODAL_generationDate: 'Generation date',
    DELIVERABLE_MODAL_internal: 'Internal',
    DELIVERABLE_REPOSITORY_addRow: '+ Add new deliverable',
    DELIVERABLE_REPOSITORY_deliverable: 'Deliverable',
    DELIVERABLE_REPOSITORY_orderType: 'Order type',
    DELIVERABLE_REPOSITORY_prupose: 'Purpose',
    DELIVERABLE_REPOSITORY_source: 'Origin',
    deliverables: 'Deliverables',
    description: 'Description',
    email: 'Email',
    Email: 'Email',
    ENTITIES_add_entity: 'Add account',
    ENTITIES_createEntity: 'Create account',
    ENTITIES_entities: 'Accounts',
    ENTITIES_entityType: 'Account type',
    entitiesLabel: 'Accounts',
    entrance: 'Entrance',
    ExternalRecordId: 'External record number',
    externalTech: 'Appraiser',
    filesAverageTime: 'Average files time',
    filesVolume: 'Files volume',
    filter: 'Filter',
    financial: 'Financial',
    finishedAppraisals: 'Finished Appraisals',
    finishedDate: 'Finished date',
    FirstName: 'Name',
    floor: 'Plant',
    from: 'From',
    gate: 'Gate',
    generalData: 'General data',
    generate_password: 'Generate password',
    GestasaId: 'No. Gestasa',
    GlobalToOrder: 'Global',
    how_does_it_works_side_menu: 'How does it work?',
    incomeOrExpensesInvoiced: 'Invoiced Income/Expenses',
    incomeOrExpensesProvision: 'Estimated Income/Expenses',
    IndividualByAsset: 'For each asset',
    INVOICES_technical_mannager_users: 'Validator users',
    invoices: 'Invoices',
    InvoiceStatus_0: 'Pending request for confirmation',
    InvoiceStatus_1: 'Confirmation sent',
    InvoiceStatus_2: 'Confirmed',
    InvoiceStatus_3: 'Rejected',
    IrpfPercentage: 'IRPF retention percentage (%)',
    IsActive: 'Active User',
    isActiveLicense: 'Active License',
    LastName: 'Surname',
    Locality: 'Locality',
    log_in: 'Log In',
    log_out: 'Log Out',
    logging_in: 'Logging In...',
    logout: 'Log Out',
    MENU_ASSESSMENTS_add: 'Add Assessment',
    MENU_ASSESSMENTS_claims: 'Claims',
    MENU_ASSESSMENTS_incidents: 'Incidents',
    MENU_ASSESSMENTS_massive_load: 'Massive Load',
    MENU_ASSESSMENTS_orders_alive: 'Appraisals Actives',
    MENU_ASSESSMENTS_orders_all: 'All',
    MENU_ASSESSMENTS_orders_cancelled: 'Cancelled',
    MENU_ASSESSMENTS_orders_deliveredByAppraiser: 'Delivered by technician',
    MENU_ASSESSMENTS_orders_deliveredToClient: 'Delivered to Client',
    MENU_ASSESSMENTS_orders_inProcess: 'In process',
    MENU_ASSESSMENTS_orders_pendingToAssignManager: 'Pending to Assign a Manager',
    MENU_ASSESSMENTS_orders_pendingToSchedule: 'Pending to Schedule',
    MENU_ASSESSMENTS_orders_scheduledByTec: 'Schudle by technical',
    MENU_ASSESSMENTS_orders_toAssign: 'Pending to Assign a technician',
    MENU_ASSESSMENTS_orders_validated: 'Validated',
    MENU_ASSESSMENTS_orders_validationProcess: 'Validation in Progress',
    MENU_ASSESSMENTS_orders: 'Orders',
    MENU_ASSESSMENTS_portfolio: 'Portfolio',
    MENU_ASSESSMENTS: 'Assessments',
    MENU_ASSETS_add: 'Add',
    MENU_ASSETS_assets: 'Assets',
    MENU_ASSETS_list: 'List',
    MENU_CLAIMS: 'Claims',
    MENU_CLIENTS: 'Clients',
    MENU_dashboard: 'Dashboard',
    MENU_ENTITIES_appraisal_companies: 'Appraisal companies',
    MENU_ENTITIES_customers: 'Customers',
    MENU_ENTITIES_prescribers: 'Prescribers',
    MENU_ENTITIES_users: 'Users',
    MENU_ENTITIES: 'Entities',
    MENU_FINANCIAL_paymentSummary_allInvoices: 'Todas las facturas',
    MENU_FINANCIAL_paymentSummary_confirmedByInvoicer: 'Confirmed',
    MENU_FINANCIAL_paymentSummary_pendingToSendToInvoicer: 'Pending confirmation request',
    MENU_FINANCIAL_paymentSummary_rejectedByInvoicer: 'Rejected',
    MENU_FINANCIAL_paymentSummary_sendToRevisionToInvoicer: 'Confirmation sent',
    MENU_FINANCIAL_paymentSummary: 'Payment Summary',
    MENU_FINANCIAL: 'Financial',
    MENU_ORDERS_list: 'List',
    MENU_ORDERS_requests: 'Requests',
    MENU_QUALITYCONTROL_createOrder: 'Create order',
    MENU_QUALITYCONTROL_orders_alive: 'Appraisals Actives',
    MENU_QUALITYCONTROL_orders_all: 'All',
    MENU_QUALITYCONTROL_orders_cancelled: 'Cancelled',
    MENU_QUALITYCONTROL_orders_deliveredToClient: 'Delivered to Client',
    MENU_QUALITYCONTROL_orders_pending2AssignTechnical: 'Pending to Assign Technical',
    MENU_QUALITYCONTROL_orders_pendingToAssignProcessManager: 'Pending to Assign Process Manager',
    MENU_QUALITYCONTROL_orders_pendingToAssignTechnicalManager: 'Pending to Assign Technical Manager',
    MENU_QUALITYCONTROL_orders_pendingToStartValidation: 'Pending to start Validation',
    MENU_QUALITYCONTROL_orders_validating: 'Validation in progress',
    MENU_QUALITYCONTROL_orders_validationFinished: 'Validation finished',
    MENU_QUALITYCONTROL_orders: 'Orders',
    MENU_QUALITYCONTROL: 'Quality control',
    MENU_USERS_processManagers_list: 'List',
    MENU_USERS_processManagers_statistics: 'Statistics',
    MENU_USERS_processManagers: 'Process Manager Users',
    MENU_USERS_professionals: 'Professionals',
    MENU_USERS_techManagers_list: 'List',
    MENU_USERS_techManagers: 'Validator Users',
    MENU_USERS: 'Users',
    MonthsEnum_1: 'January',
    MonthsEnum_10: 'October',
    MonthsEnum_11: 'November',
    MonthsEnum_12: 'December',
    MonthsEnum_2: 'February',
    MonthsEnum_3: 'March',
    MonthsEnum_4: 'April',
    MonthsEnum_5: 'May',
    MonthsEnum_6: 'June',
    MonthsEnum_7: 'July',
    MonthsEnum_8: 'August',
    MonthsEnum_9: 'September',
    MY_PROFILE_MODAL_cancel: 'Cancel',
    MY_PROFILE_MODAL_cancelling: 'Cancelling',
    MY_PROFILE_MODAL_confirm: 'Confirm',
    MY_PROFILE_MODAL_confirming: 'Confirming',
    MY_PROFILE_MODAL_my_profile: 'My profile',
    MY_PROFILE_MODAL_save: 'Save',
    my_work: 'My Work',
    name_or_business_name: 'Name or business name',
    name: 'Name',
    Name: 'Name',
    nationalIdentityDocument: 'National Identity Document',
    nif: 'NIF/NIE/Passport',
    no_documents: 'There are no documents',
    notInvoiced: 'Not invoiced',
    number: 'Number',
    observations: 'observations',
    ORDER_REQUEST_DETAIL_addNewAsset: '+ Add new asset',
    ORDER_REQUEST_DETAIL_cancel: '- Cancel',
    ORDER_REQUEST_DETAIL_euro: 'EUROS (without taxes).',
    ORDER_REQUEST_DETAIL_registerData: 'Registry data',
    ORDER_REQUEST_DETAIL_showDetail: 'See detail',
    partialInvoiced: 'Partial invoiced',
    phone: 'Phone Number',
    postalCode: 'Postal Code',
    prescribers: 'Prescribers',
    PrescriptorType: 'Prescriptor Type',
    PROCESS_MANAGER_USER: 'Process manager user',
    PROCESS_MANAGER_USERS_add_process_manager_user: 'Add process manager',
    PROCESS_MANAGER_USERS: 'Process Manager Users',
    process_managers: 'Process managers',
    processDept: 'Process manager',
    ProcessManager: 'Process managers',
    ProcessManagerUser: 'Process Manager',
    PROFESSIONALS_appraisers: 'Appraisers',
    PROFESSIONALS_NEW_add_professional: 'Add appraiser',
    PROFFESIONALS_aditionalInfoTooltip: 'The external code is used to conserve a unique numbering system. The remarks may be read by the professional on the app. While internal remarks can only be read from this administration panel, the professional cannot read them on the app.',
    PROPERTY_DATA_TABLE_adress: 'Address',
    PROPERTY_DATA_TABLE_antiquity: 'Antiquity',
    PROPERTY_DATA_TABLE_CadastralReference: 'Cadastral reference',
    PROPERTY_DATA_TABLE_estimatedValueBand: 'Estimated value range',
    PROPERTY_DATA_TABLE_prupsedProperty: 'Proposed rate',
    PROPERTY_DATA_TABLE_refCatastral: 'Cadastral Ref.',
    PROPERTY_DATA_TABLE_RegistryInfoBook: 'Book',
    PROPERTY_DATA_TABLE_RegistryInfoIdufir: 'C.R.U. (IDUFIR) ',
    PROPERTY_DATA_TABLE_RegistryInfoInscription: 'Inscription',
    PROPERTY_DATA_TABLE_RegistryInfoRealEstateNumber: 'Registered property',
    PROPERTY_DATA_TABLE_RegistryInfoRegister: 'Property Record',
    PROPERTY_DATA_TABLE_RegistryInfoRegisterNumber: 'Registry property number',
    PROPERTY_DATA_TABLE_RegistryInfoSection: 'Folio',
    PROPERTY_DATA_TABLE_RegistryInfoVolume: 'Tome',
    PROPERTY_DATA_TABLE_surface: 'Surface',
    PROPERTY_DATA_TABLE_tipologyAndUse: 'Use and typology',
    PROPERTY_DATA_TABLE_total: 'Total:',
    Province: 'Province',
    QUALITY_appraiser: 'Appraiser',
    RecordId: 'Record number',
    relatedAppraisals: 'Related appraisals (Not cancelled)',
    Requester: 'Prescriber',
    REQUESTS_billing: 'Billing',
    REQUESTS_createNewInvoice: 'Create new invoice',
    restart: 'restart',
    roadType: 'Type of road',
    save: 'Save',
    search: 'Search',
    selectAmountToIncludeInInvoice: 'Select amount to include in the invoice',
    stairs: 'Stairs',
    streetName: 'Street name',
    successfully_completed: 'Successfully completed',
    tab_information_updated_please_reload_tab: 'Tab information updated. Reload tab',
    tax_type: 'Tax type',
    tax: 'Tax',
    TECHNICAL_MANAGER_USER: 'Validator User',
    TECHNICAL_MANAGER_USERS_add_technical_manager_user: 'Add validator',
    TECHNICAL_MANAGER_USERS: 'Validator Users',
    technical_managers: 'Validators',
    technicalDept: 'Validator',
    TechnicalManager: 'Validators',
    TechnicalManagerUser: 'Technical Manager',
    the_action_was_successful: 'The action was successful',
    toDate: 'To',
    TRIALPERIOD_activeFreeTrial: 'Trial period active',
    TRIALPERIOD_btn: 'Contract Tasafy',
    TRIALPERIOD_expiredFreeTrial: 'Trial period expired',
    TRIALPERIOD_nonAdminUser: 'Speak to your administrator to contract the plan',
    TRIALPERIOD_upTo: 'Until',
    TRIALPERIOD: '<p>You can continue enjoying the Tasafy universe for free with one of our plans. If you have any questions, call us at <a href="tel:+34603718313">603 718 313</a></p>',
    typology: 'Typology',
    User: 'Users',

    ACCOUNT_ACTIVATION_noActivationTitle: "Don't forget to activate your account!",
    ACCOUNT_ACTIVATION_noActivationMessage: 'Your account has not been activated yet. Check your inbox where you will find the email to do so. If you have not received it or you have exceeded the activation time of one day, you can request it again from here.',
    ACCOUNT_ACTIVATION_resend: 'Resend',
    ACCOUNT_ACTIVATION_resending: 'Resending...',
    ACCOUNT_ACTIVATION_goToLogin: 'Go back to login',
    ACCOUNT_ACTIVATION_defaultError: 'Error activating account. Please get in touch with us',
    ACCOUNT_ACTIVATION_resendSuccess: 'Email resend successfully',
    ACCOUNT_ACTIVATION_resendError: 'Error resending email',
    users: 'Users',
    WELCOME_STEP1_paragraph1: 'Congratulations! You now form part of <b>Tasafy</b>, the property appraisal management and execution software.',
    WELCOME_STEP1_paragraph2: 'Everything you need in a single space, designed to be efficient and effective.',
    WELCOME_STEP1_paragraph3: '<b>What happens now?</b>',
    WELCOME_STEP1_title: 'Hi!',
    WELCOME_STEP2_section1_paragraph1: 'Access the <b>Academy</b> section available from the top menu.',
    WELCOME_STEP2_section1_paragraph2: "In this section, you'll find all the information needed to take the <b>initiation course</b> online, divided into two steps: basic features of the tool and performing the appraisal. Become a veritable expert!",
    WELCOME_STEP2_section2_paragraph1: 'If you would prefer to get working straight away, you can create your first report in the <b>Appraisals</b> section. The <b>Add</b> button is in the side menu.',
    WELCOME_STEP3_button1: "Let's get started!",
    WELCOME_STEP3_paragraph1: 'Furthermore, you can access the Tasafy manual at any time from the upper right menu <b>W</b>, where you will find all the information you need to use the tool.',
    WELCOME_STEP3_paragraph2: 'Remember that you can revise your plan and adapt it to your requirements in the profile settings section.',
    WELCOME_STEP4_title: 'Do you have any questions?',
    WELCOME_STEP4_paragraph1: '<b>TAFY</b> is here to guide you through the completion of the files.',
    WELCOME_STEP4_paragraph2: 'You can find him at the top of each review detail.',
    PLANS_BASIC_CHANGE_title1: 'We continue working to improve your Tasafy experience',
    PLANS_BASIC_CHANGE_title2: 'We have improved your license!',
    PLANS_BASIC_CHANGE_title3: 'Or level up and become PREMIUM',
    PLANS_BASIC_CHANGE_paragraph1: 'We present the latest news in our subscription plans, where we have introduced new functionalities and advantages that improve your work as an appraiser.',
    PLANS_BASIC_CHANGE_paragraph2: 'So that you can enjoy a <b>better experience</b> with our tool, we have <b>transformed</b> your current BASIC plan, adding <b>new features</b> and advantages, under the name <b>COMFORT</b>.',
    PLANS_BASIC_CHANGE_paragraph3: "If you don't know him, what are you waiting for? <br> Includes access to <b>Idealista</b> witnesses.",
    PLANS_BASIC_CHANGE_paragraphButton1: 'DISCOVER THE COMFORT PLAN',
    PLANS_BASIC_CHANGE_paragraphButton2: 'DISCOVER THE PREMIUM PLAN',
    PLANS_EXPERT_CHANGE_title1: 'Your current plan is now COMFORT!',
    PLANS_EXPERT_CHANGE_title2: 'Or level up and become PREMIUM',
    PLANS_EXPERT_CHANGE_title3: 'LAST NEWS',
    PLANS_EXPERT_CHANGE_subTitle1: 'COMFORT PLAN ',
    PLANS_EXPERT_CHANGE_paragraph1: "Don't worry!",
    PLANS_EXPERT_CHANGE_paragraph2: 'It is only a change in the name of your subscription plan, you will continue to maintain the same functionalities at the same price as until now, under the name:',
    PLANS_EXPERT_CHANGE_paragraph3: "If you don't know him, what are you waiting for?",
    PLANS_EXPERT_CHANGE_paragraph4: 'Includes access to <b>Idealista</b> witnesses.',
    PLANS_CHANGE_paragraphButton2: '¡Yes, I would like to see the new plans!',
    PLANS_INFO_CURRENT_PLAN: 'Your current plan is...',
    PLANS_INFO_CURRENT_PLAN_button: 'Continue',
    PLANS_NOT_AVAILABLE_title: 'Your plan is no longer available',
    PLANS_NOT_AVAILABLE_paragraph: 'Take the opportunity to find the plan that best suits your needs',
    PLANS_NOT_AVAILABLE_button: 'Review new plans',
    PLANS_BASIC_NOT_AVAILABLE_paragraph: 'The plan you were using is no longer available',
    PLANS_BASIC_NOT_AVAILABLE_title: '¡Choose your new plan!',
    PLANS_NOT_AVAILABLE: 'To keep your Tasafy account active, please select a new plan',
    PLANS_CHOOSE_NEW_PLAN: 'Select your new Tasafy plan now!',
    PLANS_Basic_info1: 'You have not selected any plan',
    PLANS_COMFORT_info1: '<p><b>Automatic generation of reports</b> and PDF certificates adjusted to regulatory standards (ECO, RICS OR IVS)</p>',
    PLANS_COMFORT_info2: '<p><b>All valuation methods: </b>finished, unfinished, land and economic holdings.</p>',
    PLANS_COMFORT_info3: '<p><b>Data collection during the visit</b>, from any device, <b>synchronized with appraisal report</b></p>',
    PLANS_COMFORT_info4: '<p><b>Control Panel</b> for real-time monitoring of your work</p>',
    PLANS_COMFORT_info5: '<p><b>Generation of official data report (Reports to the Bank of Spain)</b></p>',
    PLANS_COMFORT_info6: '<p><b>Report customization:</b> logo, footer and signatures</p>',
    PLANS_COMFORT_info7: '<p>Databases with <b>automatic information on location, environment and market</b></p>',
    PLANS_COMFORT_info8: '<p>Storage of <b>own witness database</b>.</p>',
    PLANS_COMFORT_info9: '<p>Work agenda for <b>planning visits and deliveries</b>.</p>',
    PLANS_COMFORT_info10: '<p><b>Version history</b> of reports and modification log.</p>',
    PLANS_PREMIUM_info1: '<p>All the features of the Comfort Plan</p>',
    PLANS_PREMIUM_info2: '<span> It also includes:</span><ul><li><b>Integrated witness module:</b> import of comparables from the location or cadastral reference.</li><li> <b>Possibility of access to the database of idealista.com</b></li>',

    PLANS_noIVAPlans: '*The indicated prices do not include VAT',
    PLANS_pricePerReport: '{{pricePlan}}{{currency}} per report',
    withholding: 'Withholding of %IRPF',
    ACADEMY_COURSES_basicConcepts: 'Basic Concepts',
    ACADEMY_COURSES_reportExecution: 'Report Execution',
    MENU_dataReport: 'Data Report',

    myComparables: 'Own comparables',

    SUPPORT_title: 'Do you need help?',
    SUPPORT_faq1: 'Do you have any questions regarding the application? <br> Check out our <b><a href="{{link_1}}" target="_blank"> video tutorials</a></b>.',
    SUPPORT_faq2: 'Have you been lost in the execution of a report? <br>Review all the points in the <b><a href="{{link_1}}" target="_blank">explanatory video</a></b>.',
    SUPPORT_faq3: 'Would you like to sign up for one of our courses? <br> <b><a href="{{link_1}}" target="_blank">More information</a></b>.',
    SUPPORT_footer: 'If you still need help you can contact us by writing an email to <b><a href="{{link_1}}">info@tasafy.com</a></b>.',

    //Overrides
    CUSTOMER_PRESCRIBER_customer_modal_tooltip_message: "You can create a customer from the top menu 'Accounts'",
    CUSTOMER_PRESCRIBER_prescriber_modal_tooltip_message: "You can create a prescriber from the top menu 'Accounts'",
    APPRAISAL_OWNER_DATA_customer_tooltip_message: "You can create a customer from the top menu 'Accounts'",
    REQUESTER_COMPANY_prescriber_tooltip_message: "You can create a prescriber from the top menu 'Accounts'",
  },
};
