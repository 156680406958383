import { TasafyAccountActivationComponent } from './modules/tasafy-account-activation/tasafy-account-activation.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PATHS } from '@foxeet/n-core';
import { TasafyLoginComponent } from './modules/login/login.component';
import {
  AccountCanLoad,
  AuthGuardService,
  ChangeTemporalPasswordComponent,
  ChangeTemporalPasswordGuardService,
  PasswordRestoreComponent,
  RequirePasswordChangeComponent,
} from '@foxeet/feature/auth';
import { PageNotFoundComponent } from '@foxeet/ui';

const routes: Routes = [
  {
    path: `${PATHS.LOGIN}`,
    component: TasafyLoginComponent,
  },
  {
    path: `${PATHS.ACCOUNT_ACTIVATION}`,
    component: TasafyAccountActivationComponent,
  },
  {
    path: `${PATHS.CHANGE_TEMPORAL_PASSWORD}`,
    component: ChangeTemporalPasswordComponent,
    canActivate: [ChangeTemporalPasswordGuardService],
  },
  {
    path: `${PATHS.REQUIRE_PASSWORD_CHANGE}`,
    component: RequirePasswordChangeComponent,
  },
  {
    path: `${PATHS.PASSWORD_RESTORE}`,
    component: PasswordRestoreComponent,
  },
  {
    path: '',
    loadChildren: () => import('./modules/modules.module').then((module) => module.ModulesModule),
    canLoad: [AccountCanLoad],
    canActivate: [AuthGuardService],
    data: { platform: 'tasafy' },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
