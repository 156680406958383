<div class="cookies">
  <div class="container">
    <div class="privacy-container base_container" [ngClass]="{ opened: moreInfoPanelIsOpen }">
      <div class="header-section">
        <header>
          <h2>{{ 'COOKIES_headerTitle' | translate }}</h2>
        </header>
        <p>{{ 'COOKIES_headerDescription' | translate }}</p>
      </div>
      <div class="body-section">
        <div class="box" *ngFor="let cookiesGroup of cookiesConfig">
          <span class="icon-container">
            <span class="icon">
              <img [src]="cookiesGroup.icon" />
            </span>
          </span>
          <span class="text">{{ cookiesGroup.label | translate }}</span>
          <span class="arrow" (click)="toggleMoreInfoPanel(cookiesGroup)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </span>
        </div>
      </div>
      <div class="d-flex align-items-end">
        <div class="img-section">
          <img src="assets/images/tafy_cookies.svg" />
        </div>
        <div class="button-section">
          <button (click)="close()">{{ 'BUTTON_ACTION_accept' | translate }}</button>
        </div>
      </div>
    </div>
    <div class="side-detail-container base_container" *ngIf="moreInfoPanelIsOpen">
      <div class="header-section">
        <header>
          <h2>{{ selectedCookiesGroup.headerTitle | translate }}</h2>
        </header>
        <p>{{ selectedCookiesGroup.headerDescription | translate }}</p>
      </div>
      <div class="body-section">
        <div class="header">
          <h3>{{ selectedCookiesGroup.bodyTitle | translate }}</h3>
          <p>{{ selectedCookiesGroup.bodyDescription | translate }}</p>
        </div>
        <div class="providers">
          <div *ngFor="let provider of selectedCookiesGroup.bodyItems">
            <div class="box" (click)="toggleMoreInfoProvider(provider)" [ngClass]="{ opened: provider.moreInfoIsOpened }">
              <span class="text">{{ provider.label | translate }}</span>
              <span class="toggle" *ngIf="false">
                <ui-form-input-switch [form]="form" [controlName]="provider.controlName" [label]="''"></ui-form-input-switch>
              </span>
              <span class="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </span>
            </div>
            <div class="provider-info" *ngIf="provider.moreInfoIsOpened">
              <header>{{ provider.bodyText | translate }}</header>
              <div class="privacy-link">
                <a [href]="provider.link" class="privacy-policy" target="_blank">{{ provider.linkText | translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
