export const esLang = {
  languageKey: 'es',
  translates: {
    MENU_USERS_processManagers: 'Usuarios Gestores',
    MENU_USERS_techManagers: 'Usuarios Validadores',
    PROCESS_MANAGER_USERS: 'Usuarios Gestores',
    PROCESS_MANAGER_USER: 'Usuario gestor',
    PROCESS_MANAGER_USERS_add_process_manager_user: 'Añadir gestor',
    TECHNICAL_MANAGER_USERS: 'Usuarios Validadores',
    TECHNICAL_MANAGER_USER: 'Usuario Validador',
    APPRAISALS_externalTech: 'Profesional',
    APPRAISALS_saveInfo: '*Al darle a guardar se realizará automáticamente el cobro del expediente.',
    PROFESSIONALS_NEW_add_professional: 'Añadir profesional',
    academy: 'Academy',
    my_work: 'Mi Trabajo',
    'process-manager-user': 'Usuario gestor',
    'technical-manager-user': 'Usuario validador',
    processDept: 'Gestor',
    externalTech: 'Profesional',
    technicalDept: 'Validador',
    TECHNICAL_MANAGER_USERS_add_technical_manager_user: 'Añadir validador',
    relatedAppraisals: 'Valoraciones relacionadas (No anuladas)',
    ACADEMY_WELCOME_title: '¡Bienvenido a Tasafy Academy!',
    ACADEMY_WELCOME_subtitle: 'En solo 4 pasos te convertirás en tasador profesional',
    ACADEMY_WELCOME_columnTitle1: 'Curso de iniciación',
    ACADEMY_WELCOME_columnDescription1: 'Entra en el apartado <b>Academy</b> disponible en el menú superior y realiza online el curso inicial para aprender los conceptos básicos de Tasafy',
    ACADEMY_WELCOME_columnTitle2: 'Caso Práctico',
    ACADEMY_WELCOME_columnDescription2: 'En el curso encontrarás un botón de <b>“Empezar prueba”</b> que te llevará a tu primer informe donde podrás aplicar todos los conocimientos aprendidos',
    ACADEMY_WELCOME_columnTitle3: 'Corrección',
    ACADEMY_WELCOME_columnDescription3: 'Cuando termines tu informe llevándolo hasta el paso <b>“entrega a cliente”</b>, un <b>experto</b> revisará tu ejercicio dejándote sus comentarios en el apartado <b>“tablón”</b>',
    ACADEMY_WELCOME_columnTitle4: 'Calificación',
    ACADEMY_WELCOME_columnDescription4: 'Podrás corregir tu valoración teniendo en cuenta los comentarios obtenidos y convence a nuestro experto para obtener tu certificación la certificación Tasafy como valorador de inmuebles.',
    ACADEMY_WELCOME_startButton: '¡Empezar!',
    ACADEMY_COURSES_progress: 'En progreso',
    ACADEMY_COURSES_pending: 'Prueba no iniciada',
    ACADEMY_CURSES_title: 'Mis cursos',
    ACADEMY_COURSES_cancelled: 'Cancelado',
    ACADEMY_COURSES_finished: 'Finalizado',
    ACADEMY_COURSES_start_test: 'Empezar la prueba',
    ACADEMY_COURSES_continue_test: 'Continuar prueba',
    ACADEMY_COURSES_go_to_detail: 'Detalle de la prueba',
    ACCOUNT_ACTIVATION_title: 'gracias',
    ACCOUNT_ACTIVATION_message: 'Tu cuenta ha sido activada.',
    ACCOUNT_ACTIVATION_button: 'Ir a Tasafy',
    WELCOME_STEP1_title: '¡Hola!',
    WELCOME_STEP1_paragraph1: '¡Enhorabuena! Ya formas parte del universo <b>Tasafy</b>, el software de gestión y ejecución de valoraciones.',
    WELCOME_STEP1_paragraph2: 'Todo lo que necesitas en un único espacio diseñado para ser eficiente y eficaz.',
    WELCOME_STEP1_paragraph3: '<b>¿Qué ocurre ahora?</b>',
    WELCOME_STEP2_section1_paragraph1: 'Entra en el apartado <b>Academy</b> disponible en el menú superior.',
    WELCOME_STEP2_section1_paragraph2: ' En este apartado encontrarás toda la información necesaria para realizar online el <b>curso inicial</b> dividido en dos pasos: conceptos básicos de la herramienta y ejecución de la valoración. ¡Conviértete en todo un experto!',
    WELCOME_STEP2_section2_paragraph1: 'Si prefieres empezar a trabajar directamente, crea tu primer informe desde el apartado de <b>Valoraciones</b>. En el menú lateral encontrarás el botón <b>Añadir</b>.',
    WELCOME_STEP3_paragraph1: 'Además, siempre podrás acceder al manual de Tasafy desde el menú lateral en la parte de abajo "¿Cómo funciona?", donde encontrarás toda la información que necesitas para el uso de la herramienta.',
    WELCOME_STEP3_paragraph2: 'Recuerda que desde la sección de configuración de tu perfil podrás revisar tu plan y adaptarlo a tus necesidades.',
    WELCOME_STEP3_button1: '¡Empecemos!',
    WELCOME_STEP4_title: '¿Tienes alguna duda?',
    WELCOME_STEP4_paragraph1: '<b>TAFY</b> está aquí para guiarte con la realización de los expedientes.',
    WELCOME_STEP4_paragraph2: 'Puedes encontrarle en la parte superior del detalle de cada una de las valoraciones.',
    PLANS_BASIC_CHANGE_title1: 'Continuamos trabajando para mejorar tu experiencia Tasafy',
    PLANS_BASIC_CHANGE_title2: '¡Hemos mejorado tu licencia!',
    PLANS_BASIC_CHANGE_title3: 'O sube de nivel y hazte PREMIUM',
    PLANS_BASIC_CHANGE_paragraph1: 'Te presentamos las últimas novedades en nuestros planes de suscripción, donde hemos introducido nuevas funcionalidades y ventajas que mejoran tu trabajo como tasador.',
    PLANS_BASIC_CHANGE_paragraph2: 'Para que puedas disfrutar de una <b>mejor experiencia</b> con nuestra herramienta, hemos <b>transformado</b> tu plan BASIC actual, añadiendo <b>nuevas funcionalidades</b> y ventajas, bajo el nombre <b>COMFORT</b>.',
    PLANS_BASIC_CHANGE_paragraph3: 'Si no lo conoces, ¿a qué estás esperando? <br>Incluye acceso a testigos de <b>Idealista<b>.',
    PLANS_BASIC_CHANGE_paragraphButton1: 'DESCUBRE EL PLAN COMFORT',
    PLANS_BASIC_CHANGE_paragraphButton2: 'DESCUBRE EL PLAN PREMIUM',
    PLANS_EXPERT_CHANGE_title1: '¡Tu plan actual ahora es COMFORT!',
    PLANS_EXPERT_CHANGE_title2: 'O sube de nivel y hazte PREMIUM',
    PLANS_EXPERT_CHANGE_title3: 'ÚLTIMAS NOTICIAS',
    PLANS_EXPERT_CHANGE_subTitle1: 'PLAN COMFORT',
    PLANS_EXPERT_CHANGE_paragraph1: '¡No te preocupes!',
    PLANS_EXPERT_CHANGE_paragraph2: 'Solo se trata de un cambio en el nombre de tu plan de suscripción, seguirás manteniendo las mismas funcionalidades al mismo precio que hasta ahora, bajo el nombre:',
    PLANS_EXPERT_CHANGE_paragraph3: 'Si no lo conoces, ¿a qué estás esperando?',
    PLANS_EXPERT_CHANGE_paragraph4: 'Incluye acceso a testigos de <b>Idealista</b>.',
    PLANS_CHANGE_paragraphButton2: '¡Sí, me gustaría ver los planes nuevos!',
    PLANS_INFO_CURRENT_PLAN: 'Su plan actual es...',
    PLANS_INFO_CURRENT_PLAN_button: 'Continuar',
    PLANS_NOT_AVAILABLE_title: 'Su plan ya no está disponible',
    PLANS_NOT_AVAILABLE_paragraph: 'Aprovecha para buscar el plan que mejor se adapte a tus necesidades',
    PLANS_NOT_AVAILABLE_button: 'Revisar los planes nuevos',
    PLANS_BASIC_NOT_AVAILABLE_paragraph: 'El plan que utilizabas ya no está disponible',
    PLANS_BASIC_NOT_AVAILABLE_title: '¡Elige tu nuevo plan!',
    PLANS_NOT_AVAILABLE: 'Para mantener tu cuenta Tasafy activa, por favor selecciona un nuevo plan',
    PLANS_CHOOSE_NEW_PLAN: '¡Selecciona ahora tu nuevo plan Tasafy!',
    PLANS_Basic_info1: 'No has seleccionado ningún plan',
    PLANS_COMFORT_info1: '<p><b>Generación automática de informes</b> y certificados en PDF ajustados a los estándares normativos (ECO, RICS O IVS)</p>',
    PLANS_COMFORT_info2: '<p><b>Todos lo métodos de valoración: </b>terminados, no terminados, terrenos y explotaciones económicas.</p>',
    PLANS_COMFORT_info3: '<p><b>Recogida de datos durante la visita,</b> desde cualquier dispositivo, <b>sincronizado con el informe de tasación</b></p>',
    PLANS_COMFORT_info4: '<p><b>Panel de control</b> para la monitorización a tiempo real de tu trabajo</p>',
    PLANS_COMFORT_info5: '<p><b>Generación de informe de datos oficiales (Reportes al Banco de España)</b></p>',
    PLANS_COMFORT_info6: '<p><b>Personalización del informe:</b> logo, pie de página y firmas</p>',
    PLANS_COMFORT_info7: '<p>Bases de datos con <b>información automática de localidad, entorno y mercado</b></p>',
    PLANS_COMFORT_info8: '<p>Almacenamiento de <b>base de datos propias de testigos</b>.</p>',
    PLANS_COMFORT_info9: '<p>Agenda de trabajo para la <b>planificación de visitas y entregas</b>.</p>',
    PLANS_COMFORT_info10: '<p><b>Historial de versiones</b> de informes y log de modificaciones.</p>',
    PLANS_PREMIUM_info1: '<p>Todas las funcionalidades del Plan Comfort</p>',
    PLANS_PREMIUM_info2: '<span>Además incluye:</span><ul><li><b>Módulo de testigos integrados:</b> importacion de comparables a partir de la localización o referencia catastral.</li><li><b>Posibilidad de acceso a la base de datos de idealista.com</b></li>',
    PLANS__select: 'Seleccionar PLAN ',
    PLANS_PREMIUM_select: 'Seleccionar PLAN PREMIUM',
    PLANS_noIVAPlans: '*Los precios indicados no incluyen IVA',
    PLANS_pricePerReport: '{{pricePlan}}{{currency}} por informe',
    appraiser: 'Profesional',
    APPRAISALS_appraiser: 'Profesional',
    APPRAISALS_reasonToChooseTech: 'Dinos el motivo de la elección de este profesional:',
    APPRAISAL_DASHBOARD_resolvedByAppraiser: 'resueltas por el profesional',
    PROFESSIONALS_appraisers: 'Profesionales',
    PROFFESIONALS_aditionalInfoTooltip: 'El código externo se utiliza para conservar alguna numeración propia. Las observaciones podrán ser leídas por el profesional desde la app. Mientras que las observaciones internas sólo podrán ser leídas desde este panel de administración, el profesional no podrá leerlo desde la app.',
    QUALITY_appraiser: 'Profesional',
    INVOICES_technical_mannager_users: 'Usuarios validadores',
    APPRAISALS_isExecutedByTasafy: 'Es ejecutado con Tasafy',
    APPRAISALS_selectManager: 'Seleccionar gestor',
    APPRAISALS_selectProfessional: 'Seleccionar profesional',
    APPRAISALS_selectValidator: 'Seleccionar validador',
    TRIALPERIOD: '<p>Puedes seguir disfrutando del universo Tasafy de forma gratuita con alguno de nuestros planes. Si tienes cualquier duda llámanos al <a href="tel:+34603718313">603 718 313</a></p>',
    TRIALPERIOD_activeFreeTrial: 'Periodo de prueba activo',
    TRIALPERIOD_expiredFreeTrial: 'Periodo de prueba caducado',
    TRIALPERIOD_upTo: 'Hasta el',
    TRIALPERIOD_btn: 'Contrata Tasafy',
    TRIALPERIOD_nonAdminUser: 'Habla con tu administrador para contratar el plan',
    CommercialManager: 'Responsables Comerciales',

    entitiesLabel: 'Cuentas',
    ENTITIES_entityType: 'Tipo de cuenta',
    ENTITIES_entities: 'Cuentas',
    ENTITIES_createEntity: 'Crear cuenta',
    ENTITIES_add_entity: 'Añadir cuenta',

    how_does_it_works_side_menu: '¿Cómo funciona?',

    ACCOUNT_ACTIVATION_noActivationTitle: '¡No olvides activar tu cuenta!',
    ACCOUNT_ACTIVATION_noActivationMessage: 'Tu cuenta no ha sido activada todavía. Revisa tu bandeja de entrada donde encontrarás el email para poder hacerlo. Si no lo has recibido o has excedido el tiempo de activación de un día puedes solicitarlo de nuevo desde aquí.',
    ACCOUNT_ACTIVATION_resend: 'Reenviar',
    ACCOUNT_ACTIVATION_resending: 'Reenviando...',
    ACCOUNT_ACTIVATION_goToLogin: 'Volver al inicio',
    ACCOUNT_ACTIVATION_defaultError: 'Error al activar la cuenta. Por favor, ponte en contacto con nosotros',
    ACCOUNT_ACTIVATION_resendSuccess: 'Email reenviado correctamente',
    ACCOUNT_ACTIVATION_resendError: 'Error al reenviar el email',
    ACADEMY_COURSES_basicConcepts: 'Conceptos básicos',
    ACADEMY_COURSES_reportExecution: 'Ejecución de informes',
    MENU_dataReport: 'Informe de datos',

    myComparables: 'Comparables propios',

    SUPPORT_title: '¿Necesitas ayuda?',
    SUPPORT_faq1: '¿Tienes alguna duda referente a la aplicación? <br> Echa un vistazo a nuestros <b><a href="{{link_1}}" target="_blank"> vídeos tutoriales</a></b>.',
    SUPPORT_faq2: '¿Te has perdido en la ejecución de un informe? <br>Revisa todos los puntos en el <b><a href="{{link_1}}" target="_blank">vídeo explicativo</a></b>.',
    SUPPORT_faq3: '¿Te gustaría apuntarte a alguno de nuestros cursos? <br><b><a href="{{link_1}}" target="_blank">Más información</a></b>.',
    SUPPORT_footer: 'Si sigues necesitando ayuda puedes contactar con nosotros escribiendo un email a <b><a href="{{link_1}}">info@tasafy.com</a></b>.',

    // Overrides
    CUSTOMER_PRESCRIBER_customer_modal_tooltip_message: "Puedes crear un cliente desde el menu superior 'Cuentas'",
    CUSTOMER_PRESCRIBER_prescriber_modal_tooltip_message: "Puedes crear un prescriptor desde el menu superior 'Cuentas'",
    APPRAISAL_OWNER_DATA_customer_tooltip_message: "Puedes crear un cliente desde el menu superior 'Cuentas'",
    REQUESTER_COMPANY_prescriber_tooltip_message: "Puedes crear un prescriptor desde el menu superior 'Cuentas'",
  },
};
