import { ObjectTS } from '@foxeet/utils/functions/javascript.functions';

export enum LicenseeUserRole {
  BasicProcessManager = 'Basic_LicenseeUserRole_ProcessManager', //
  BasicTechnicalManager = 'Basic_LicenseeUserRole_TechnicalManager', //
  BasicLicenseeAdmin = 'Basic_LicenseeUserRole_LicenseeAdmin', //
  ComfortProcessManager = 'Comfort_LicenseeUserRole_ProcessManager', //
  ComfortTechnicalManager = 'Comfort_LicenseeUserRole_TechnicalManager', //
  ComfortLicenseeAdmin = 'Comfort_LicenseeUserRole_LicenseeAdmin', //
  PremiumProcessManager = 'Premium_LicenseeUserRole_ProcessManager', //
  PremiumTechnicalManager = 'Premium_LicenseeUserRole_TechnicalManager', //
  PremiumLicenseeAdmin = 'Premium_LicenseeUserRole_LicenseeAdmin', //
  TrialProcessManager = 'Trial_LicenseeUserRole_ProcessManager', //
  TrialTechnicalManager = 'Trial_LicenseeUserRole_TechnicalManager', //
  TrialLicenseeAdmin = 'Trial_LicenseeUserRole_LicenseeAdmin', //
  BasicClientLicenseeAdmin = 'BasicClient_LicenseeUserRole_LicenseeAdmin', //
}

export enum LicenseeGeneralUserRole {
  ProcessManager = 'LicenseeUserRole_ProcessManager', //
  TechnicalManager = 'LicenseeUserRole_TechnicalManager', //
  CommercialManager = 'LicenseeUserRole_CommercialManager', //
  User = 'LicenseeUserRole_User', //
  Root = 'LicenseeUserRole_Root', //
  ExternalManager = 'LicenseeUserRole_ExternalTechnicalManager', //
  SuperAdmin = 'LicenseeUserRole_SuperAdmin', //
}

export const ALL_LICENSEE_ROLES = ObjectTS.values(LicenseeUserRole);
export const ALL_GENERAL_ROLES = ObjectTS.values(LicenseeGeneralUserRole);

export const ALL_ROLES = [...ALL_LICENSEE_ROLES, ...ALL_GENERAL_ROLES];

export const authoritiesDefinition = {
  '': {
    access: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    enable: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
  },
  entities: {
    access: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    enable: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    pages: {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
      ],
      list: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
        ],
      },
      add: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
        ],
        form: {
          showAuthomaticInvoicingCheckbox: [],
        },
      },
      detail: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
        ],
        actions: {
          canEditImage: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
        },
        profile: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          actions: {
            canEdit: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
          },
          form: {
            showAuthomaticInvoicingCheckbox: [],
          },
        },
        'allowed-roles': {
          access: [],
          enable: [],
        },
        'billing-info': {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          actions: {
            canEdit: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
          },
        },
        deliverables: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
          ],
          actions: {
            canEdit: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
            ],
          },
        },
        appraisals: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
        },
        collaborator: {
          access: [],
          enable: [],
          actions: {
            canEdit: [],
          },
        },
        users: {
          access: [],
          enable: [],
          actions: {
            canAddNewUser: [],
            canEditUsers: [],
            canChangeUsersPassword: [],
          },
        },
      },
    },
  },
  'account-management': {
    access: [
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    enable: [
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    pages: {
      access: [
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
      ],
      enable: [
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
      ],
      detail: {
        access: [
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
        ],
        enable: [
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
        ],
        actions: {
          canEditImage: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
        },
        fastActionButtons: {
          canAddPaymentMethod: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
          ],
        },
        'payment-history': {
          access: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
          ],
          actions: {
            canEditBillingData: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
            ],
            canDeletePaymentMethod: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
            ],
            canSetDefaultPaymentMethod: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
            ],
          },
        },
        profile: {
          access: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          actions: {
            canEdit: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
          },
        },
        'billing-info': {
          access: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          actions: {
            canShowWarningPaymentMethodsMessage: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
            ],
            canShowPaymentMethodsList: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
            ],
            canEditBillingData: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
            ],
            canDeletePaymentMethod: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
            ],
            canSetDefaultPaymentMethod: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
            ],
          },
        },
        invoices: {
          access: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          actions: {
            canDownload: [
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
            ],
          },
        },
        license: {
          access: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
        },
        'connections-network': {
          access: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: [
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          actions: {
            canShowLicenseeConnectionCard: [
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_User',
            ],
            canShowBasicClientConnectionCard: ['BasicClient_LicenseeUserRole_LicenseeAdmin'],
            canShowLicenseeUserConnectionCard: [
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_User',
            ],
            canShowBasicClientConnectionTab: [
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            canShowLicenseeUserConnectionTab: [
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_User',
            ],
          },
        },
        'report-customization': {
          access: ['Comfort_LicenseeUserRole_LicenseeAdmin', 'Premium_LicenseeUserRole_LicenseeAdmin', 'Trial_LicenseeUserRole_LicenseeAdmin'],
          enable: ['Comfort_LicenseeUserRole_LicenseeAdmin', 'Premium_LicenseeUserRole_LicenseeAdmin', 'Trial_LicenseeUserRole_LicenseeAdmin'],
          actions: {
            canEdit: ['Comfort_LicenseeUserRole_LicenseeAdmin', 'Premium_LicenseeUserRole_LicenseeAdmin', 'Trial_LicenseeUserRole_LicenseeAdmin'],
          },
        },
        'invoice-customization': {
          access: ['BillingModule'],
          enable: ['BillingModule'],
          actions: {
            canEdit: ['BillingModule'],
          },
        },
      },
    },
  },
  orders: {
    access: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_User',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
    enable: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_User',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
    'simple-notes': {
      access: [],
      enable: [],
      pages: {
        access: [],
        enable: [],
        list: {
          access: [],
          enable: [],
        },
        add: {
          access: [],
          enable: [],
        },
        detail: {
          access: [],
          enable: [],
          fastActions: {
            canSentToRegister: [],
            canSetSuccessReceivedFromRegister: [],
            canSetDeliveredToClient: [],
          },
          form: {
            access: [],
            enable: [],
          },
          documents: {
            access: [],
            enable: [],
            add: [],
          },
        },
        create: {
          access: [],
          enable: [],
        },
      },
    },
    requests: {
      access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
      enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
      pages: {
        access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
        enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
        dashboard: {
          access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          fileVolume: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          fileAverageTime: [],
          summaryOfUnfinishedItems: [],
          filters: {
            orderTypes: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            purpose: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            customer: [],
            supplierCompany: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            prescriber: [],
            portfolios: [],
            advanced: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            hasVinculation: [],
            vinculations: [],
          },
          clickStepper: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
        },
        list: {
          access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          filters: {
            paymentFilters: ['LicenseeUserRole_SuperAdmin'],
          },
          topButtonActions: {
            canAddRequestButton: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            canDownloadExcel: ['LicenseeUserRole_SuperAdmin'],
          },
        },
        add: {
          access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          addAsset: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            enable: [],
          },
        },
        detail: {
          access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          fastActions: {
            canConfirmExpenses: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            canCancel: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            canCopy: ['BasicClient_LicenseeUserRole_LicenseeAdmin'],
            canResendEmail: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAcceptManually: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canCreateAppraisal: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAddInvoice: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canSendToClient: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
          },
          actions: {
            canAssignResolverUser: ['BasicClient_LicenseeUserRole_LicenseeAdmin'],
            canShowResolverUser: ['BasicClient_LicenseeUserRole_LicenseeAdmin'],
          },
          detail: {
            access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            actions: {
              canEdit: ['LicenseeUserRole_SuperAdmin'],
              canShowCopySelector: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
              canEditBillingData: [],
            },
            externalReference: ['BasicClient_LicenseeUserRole_LicenseeAdmin'],
            paymentOption: ['LicenseeUserRole_SuperAdmin'],
            feeOption: ['LicenseeUserRole_SuperAdmin'],
            incomeOutComeProvision: ['LicenseeUserRole_SuperAdmin'],
            selectPrescriber: ['LicenseeUserRole_SuperAdmin'],
            selectCustomer: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            addManualCustomer: [],
            exchange: ['LicenseeUserRole_SuperAdmin'],
            conditionsCheck: [],
          },
          information: {
            access: ['BasicClient_LicenseeUserRole_LicenseeAdmin'],
            enable: ['BasicClient_LicenseeUserRole_LicenseeAdmin'],
          },
          documents: {
            access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            actions: {
              canDownload: ['LicenseeUserRole_SuperAdmin'],
            },
          },
          'payment-history': {
            access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          },
          'message-board': {
            access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            actions: {
              canAdd: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canReply: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          history: {
            access: [
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            records: {
              access: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              enable: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
            shipments: {
              access: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              enable: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
            communications: {
              access: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              enable: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          appraisals: {
            access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          },
          'management-orders': {
            access: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin', 'BasicClient_LicenseeUserRole_LicenseeAdmin'],
          },
          billing: {
            access: ['LicenseeUserRole_SuperAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin'],
          },
        },
        massive: {
          access: ['LicenseeUserRole_SuperAdmin'],
          enable: ['LicenseeUserRole_SuperAdmin'],
          topButtonActions: {
            canAddMassiveLoadButton: ['LicenseeUserRole_SuperAdmin'],
          },
          detail: {
            access: ['LicenseeUserRole_SuperAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin'],
            actions: {
              canDownloadFile: ['LicenseeUserRole_SuperAdmin'],
            },
          },
          add: {
            access: ['LicenseeUserRole_SuperAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin'],
          },
        },
        communications: {
          access: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
          enable: ['LicenseeUserRole_Root', 'LicenseeUserRole_ProcessManager', 'LicenseeUserRole_TechnicalManager', 'LicenseeUserRole_CommercialManager'],
        },
      },
    },
    'quality-control': {
      access: [],
      enable: [],
      pages: {
        access: [],
        enable: [],
        list: {
          access: [],
          enable: [],
          actions: {
            canCreateOrder: [],
            canShowTopFilterBar: [],
          },
        },
        add: {
          access: [],
          enable: [],
        },
        massive: {
          access: [],
          enable: [],
        },
        detail: {
          access: [],
          enable: [],
          actions: {
            canAssignManagerProcessUser: [],
            canAssignTechnicalUser: [],
            canAssignManagerTechnicalUser: [],
          },
          fastActions: {
            canCancel: [],
            canFinishValidation: [],
            canStartValidation: [],
            canReject: [],
            canMarkAsDelivered: [],
          },
          detail: {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
          documents: {
            access: [],
            enable: [],
            actions: {
              canDownload: [],
              canUpload: [],
            },
          },
          'registral-plat': {
            access: [],
            enable: [],
            actions: {
              canSave: [],
              canSaveAndFinish: [],
            },
          },
        },
      },
    },
    appraisals: {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
        'LicenseeUserRole_User',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ExternalTechnicalManager',
        'Premium_LicenseeUserRole_ExternalTechnicalManager',
        'Basic_LicenseeUserRole_ExternalTechnicalManager',
        'Trial_LicenseeUserRole_ExternalTechnicalManager',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
        'LicenseeUserRole_User',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ExternalTechnicalManager',
        'Premium_LicenseeUserRole_ExternalTechnicalManager',
        'Basic_LicenseeUserRole_ExternalTechnicalManager',
        'Trial_LicenseeUserRole_ExternalTechnicalManager',
      ],
      pages: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
          'LicenseeUserRole_User',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ExternalTechnicalManager',
          'Premium_LicenseeUserRole_ExternalTechnicalManager',
          'Basic_LicenseeUserRole_ExternalTechnicalManager',
          'Trial_LicenseeUserRole_ExternalTechnicalManager',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
          'LicenseeUserRole_User',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ExternalTechnicalManager',
          'Premium_LicenseeUserRole_ExternalTechnicalManager',
          'Basic_LicenseeUserRole_ExternalTechnicalManager',
          'Trial_LicenseeUserRole_ExternalTechnicalManager',
        ],
        dashboard: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileVolume: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileAverageTime: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          summaryOfUnfinishedItems: [
            'LicenseeUserRole_SuperAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          filters: {
            orderTypes: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            purpose: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            customer: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            supplierCompany: ['BasicClient_LicenseeUserRole_LicenseeAdmin', 'LicenseeUserRole_SuperAdmin'],
            prescriber: [],
            portfolios: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            advanced: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            hasVinculation: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            vinculations: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
          },
          clickStepper: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
        },
        list: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          actions: {
            canDownloadExcel: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canFilter: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowTopFilterBar: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignMultipleProcessManagers: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignMultipleTechnicalManagers: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
        },
        add: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          addAsset: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [],
          },
        },
        massive: {
          access: ['LicenseeUserRole_SuperAdmin'],
          enable: ['LicenseeUserRole_SuperAdmin'],
          pages: {
            access: ['LicenseeUserRole_SuperAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin'],
            list: {
              access: ['LicenseeUserRole_SuperAdmin'],
              enable: ['LicenseeUserRole_SuperAdmin'],
              actions: {
                canFilter: ['LicenseeUserRole_SuperAdmin'],
              },
            },
            add: {
              access: ['LicenseeUserRole_SuperAdmin'],
              enable: ['LicenseeUserRole_SuperAdmin'],
            },
            detail: {
              access: ['LicenseeUserRole_SuperAdmin'],
              enable: ['LicenseeUserRole_SuperAdmin'],
              actions: {
                canDownloadFile: ['LicenseeUserRole_SuperAdmin'],
              },
            },
          },
        },
        portfolios: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          list: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canFilter: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          add: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          detail: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canDeleteValuations: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canAddValuations: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
        },
        incidents: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: ['LicenseeUserRole_SuperAdmin'],
          actions: {
            canDownloadExcel: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canFilter: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
            ],
          },
        },
        claims: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: ['LicenseeUserRole_SuperAdmin'],
          actions: {
            canFilter: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
        },
        communications: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: ['LicenseeUserRole_SuperAdmin'],
        },
        comparables: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Expert_LicenseeUserRole_ProcessManager',
            'Expert_LicenseeUserRole_TechnicalManager',
            'Expert_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Expert_LicenseeUserRole_ProcessManager',
            'Expert_LicenseeUserRole_TechnicalManager',
            'Expert_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          list: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Expert_LicenseeUserRole_ProcessManager',
              'Expert_LicenseeUserRole_TechnicalManager',
              'Expert_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Expert_LicenseeUserRole_ProcessManager',
              'Expert_LicenseeUserRole_TechnicalManager',
              'Expert_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canFilter: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Expert_LicenseeUserRole_ProcessManager',
                'Expert_LicenseeUserRole_TechnicalManager',
                'Expert_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          detail: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Expert_LicenseeUserRole_ProcessManager',
              'Expert_LicenseeUserRole_TechnicalManager',
              'Expert_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Expert_LicenseeUserRole_ProcessManager',
              'Expert_LicenseeUserRole_TechnicalManager',
              'Expert_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            detail: {
              access: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Expert_LicenseeUserRole_ProcessManager',
                'Expert_LicenseeUserRole_TechnicalManager',
                'Expert_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              enable: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Expert_LicenseeUserRole_ProcessManager',
                'Expert_LicenseeUserRole_TechnicalManager',
                'Expert_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
            'market-studies': {
              access: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Expert_LicenseeUserRole_ProcessManager',
                'Expert_LicenseeUserRole_TechnicalManager',
                'Expert_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              enable: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Expert_LicenseeUserRole_ProcessManager',
                'Expert_LicenseeUserRole_TechnicalManager',
                'Expert_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
        },
        detail: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          addAsset: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [],
          },
          actions: {
            showTour: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canAssignResolverUser: [],
            canAssignManagerProcessUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignTechnicalUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignManagerTechnicalUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canShowResolverUser: ['BasicClient_LicenseeUserRole_LicenseeAdmin'],
            canShowManagerProcessUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowTechnicalUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowManagerTechnicalUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          fastActions: {
            canCancel: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canDuplicate: [
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canStartValidation: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canValidate: [
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canReject: [
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canSendToClient: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canMarkAsDeliveredToClient: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canValidateExpenses: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAdvanceVisit: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          detail: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canShowBtnViewCustomer: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'BasicClient_LicenseeUserRole_LicenseeAdmin',
              ],
            },
            references: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            request: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            showRequestDataBtn: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            requestData: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            customer: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            prescriber: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            orderFeatures: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            relatedAppraisals: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            incomeExpensesProvision: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            incomeExpensesConfirmed: ['BillingModule'],
            exchange: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          information: {
            access: [],
            enable: [],
          },
          documents: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
                'BasicClient_LicenseeUserRole_LicenseeAdmin',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
              ],
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
              ],
              canSetAsPrintable: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
              ],
              canDownload: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
                'BasicClient_LicenseeUserRole_LicenseeAdmin',
              ],
            },
            appraisalDocTable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
            ],
            appraisalAssetDocTable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          incidents: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canResolve: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
                'BasicClient_LicenseeUserRole_LicenseeAdmin',
              ],
            },
          },
          revisions: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          claims: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canResolve: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          history: {
            access: [
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            records: {
              access: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            shipments: {
              access: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            communications: {
              access: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          'message-board': {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canReply: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          invoicing: {
            access: ['BillingModule'],
            enable: ['BillingModule'],
          },
          'appraisal-relationship': {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
          },
          report: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            list: {
              access: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              actions: {
                canGenerateDraft: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                canImportReport: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                canSetDeliverables: [],
              },
            },
            detail: {
              access: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              addAsset: {
                access: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [],
              },
              commonAssets: {
                access: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                locationAndSurroundings: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canGetAutomaticData: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'market-studies': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canGetAutomaticData: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  detail: {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    actions: {
                      canEdit: [
                        'LicenseeUserRole_Root',
                        'LicenseeUserRole_ProcessManager',
                        'LicenseeUserRole_TechnicalManager',
                        'LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_CommercialManager',
                      ],
                    },
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  list: {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    actions: {
                      canEdit: [
                        'LicenseeUserRole_Root',
                        'LicenseeUserRole_ProcessManager',
                        'LicenseeUserRole_TechnicalManager',
                        'LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_CommercialManager',
                      ],
                    },
                  },
                },
                terrainFeatures: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                landDescription: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'exploitation-description': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'rustic-estate-features': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                buildingInfo: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                urbanisticSituation: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                urbanisticSituationTerrain: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                },
                checkValidations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                conditionings: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                buildingState: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                valuations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canAdd: [],
                    canEdit: [],
                    canDelete: [],
                    canRecalculate: [],
                  },
                  'comparison-method': {
                    access: [],
                    enable: [],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'cost-method': {
                    access: [],
                    enable: [],
                  },
                  'discount-cashflow-method': {
                    access: [],
                    enable: [],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'contract-cashflow-method': {
                    access: [],
                    enable: [],
                  },
                  'legal-maximum-method': {
                    access: [],
                    enable: [],
                  },
                  'other-method': {
                    access: [],
                    enable: [],
                  },
                  'dynamic-residual-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'static-residual-method': {
                    access: [],
                    enable: [],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'crawled-method': {
                    access: [],
                    enable: [],
                  },
                  'economic-exploitation-method': {
                    access: [],
                    enable: [],
                  },
                  'rustic-estate-exploitation-method': {
                    access: [],
                    enable: [],
                  },
                },
              },
              assets: {
                access: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                actions: {
                  canMultipleEdit: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canCreateCluster: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canValuateTerrains: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canModifyStructure: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canImportFromRequest: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canMultipleRecalculate: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canAdd: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canDelete: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canDuplicate: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canAddAnnex: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canMakeMainAsset: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canExtractAsset: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                },
                identification: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                location: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                typology: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                surfaces: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                locationAndSurroundings: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canGetAutomaticData: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                landDescription: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'exploitation-description': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'rustic-estate-features': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                buildingInfo: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                propertyCharacteristics: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                terrainFeatures: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                urbanisticSituation: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                urbanisticSituationTerrain: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                tenures: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                checkValidations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                conditionings: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                photos: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canSetAsPrintable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canDelete: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canMakeItMain: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canMultipleSelect: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canAdd: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                documents: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canSetAsPrintable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canDownload: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canAdd: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canDelete: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                appraisalAssetLogChanges: {
                  access: [
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    null,
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  enable: [
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    null,
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  actions: {
                    canEdit: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                buildingState: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                assetExtraInformation: {
                  access: [
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                  ],
                  enable: [
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                  ],
                  actions: {
                    canEdit: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                    ],
                  },
                },
                valuations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canAdd: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canDelete: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canRecalculate: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'comparison-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'cost-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'discount-cashflow-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'contract-cashflow-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'legal-maximum-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'other-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'dynamic-residual-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'static-residual-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'crawled-method': {
                    access: [],
                    enable: [],
                  },
                  'economic-exploitation-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'rustic-estate-exploitation-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
              },
              clusters: {
                access: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                actions: {
                  canDelete: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                },
                pages: {
                  detail: {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    actions: {
                      canEdit: [
                        'Basic_LicenseeUserRole_ProcessManager',
                        'Basic_LicenseeUserRole_TechnicalManager',
                        'Basic_LicenseeUserRole_LicenseeAdmin',
                        'Comfort_LicenseeUserRole_ProcessManager',
                        'Comfort_LicenseeUserRole_TechnicalManager',
                        'Comfort_LicenseeUserRole_LicenseeAdmin',
                        'Premium_LicenseeUserRole_ProcessManager',
                        'Premium_LicenseeUserRole_TechnicalManager',
                        'Premium_LicenseeUserRole_LicenseeAdmin',
                        'Trial_LicenseeUserRole_ProcessManager',
                        'Trial_LicenseeUserRole_TechnicalManager',
                        'Trial_LicenseeUserRole_LicenseeAdmin',
                        'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                        'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        'Basic_LicenseeUserRole_ExternalTechnicalManager',
                        'Trial_LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_SuperAdmin',
                      ],
                    },
                  },
                },
                detail: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                },
                valuations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  actions: {
                    canAdd: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    canDelete: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    canRecalculate: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'comparison-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'cost-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'discount-cashflow-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'contract-cashflow-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'legal-maximum-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'other-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'dynamic-residual-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'static-residual-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'crawled-method': {
                    access: [],
                    enable: [],
                  },
                  'economic-exploitation-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'rustic-estate-exploitation-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                },
              },
            },
          },
          precedents: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
        },
      },
    },
    'management-orders': {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
        'LicenseeUserRole_User',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ExternalTechnicalManager',
        'Premium_LicenseeUserRole_ExternalTechnicalManager',
        'Basic_LicenseeUserRole_ExternalTechnicalManager',
        'Trial_LicenseeUserRole_ExternalTechnicalManager',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
        'LicenseeUserRole_User',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ExternalTechnicalManager',
        'Premium_LicenseeUserRole_ExternalTechnicalManager',
        'Basic_LicenseeUserRole_ExternalTechnicalManager',
        'Trial_LicenseeUserRole_ExternalTechnicalManager',
      ],
      pages: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
          'LicenseeUserRole_User',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ExternalTechnicalManager',
          'Premium_LicenseeUserRole_ExternalTechnicalManager',
          'Basic_LicenseeUserRole_ExternalTechnicalManager',
          'Trial_LicenseeUserRole_ExternalTechnicalManager',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
          'LicenseeUserRole_User',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ExternalTechnicalManager',
          'Premium_LicenseeUserRole_ExternalTechnicalManager',
          'Basic_LicenseeUserRole_ExternalTechnicalManager',
          'Trial_LicenseeUserRole_ExternalTechnicalManager',
        ],
        dashboard: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileVolume: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          fileAverageTime: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          summaryOfUnfinishedItems: [
            'LicenseeUserRole_SuperAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          filters: {
            orderTypes: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            purpose: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            customer: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            supplierCompany: ['BasicClient_LicenseeUserRole_LicenseeAdmin', 'LicenseeUserRole_SuperAdmin'],
            prescriber: [],
            portfolios: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            advanced: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            hasVinculation: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            vinculations: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
          },
          clickStepper: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
        },
        list: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          actions: {
            canDownloadExcel: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canFilter: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowTopFilterBar: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignMultipleProcessManagers: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignMultipleTechnicalManagers: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
        },
        add: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          addAsset: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [],
          },
        },
        massive: {
          access: ['LicenseeUserRole_SuperAdmin'],
          enable: ['LicenseeUserRole_SuperAdmin'],
          pages: {
            access: ['LicenseeUserRole_SuperAdmin'],
            enable: ['LicenseeUserRole_SuperAdmin'],
            list: {
              access: ['LicenseeUserRole_SuperAdmin'],
              enable: ['LicenseeUserRole_SuperAdmin'],
              actions: {
                canFilter: ['LicenseeUserRole_SuperAdmin'],
              },
            },
            add: {
              access: ['LicenseeUserRole_SuperAdmin'],
              enable: ['LicenseeUserRole_SuperAdmin'],
            },
            detail: {
              access: ['LicenseeUserRole_SuperAdmin'],
              enable: ['LicenseeUserRole_SuperAdmin'],
              actions: {
                canDownloadFile: ['LicenseeUserRole_SuperAdmin'],
              },
            },
          },
        },
        portfolios: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          list: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canFilter: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          add: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          detail: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canDeleteValuations: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canAddValuations: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
        },
        incidents: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
          ],
          enable: ['LicenseeUserRole_SuperAdmin'],
          actions: {
            canDownloadExcel: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canFilter: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
            ],
          },
        },
        claims: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: ['LicenseeUserRole_SuperAdmin'],
          actions: {
            canFilter: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
        },
        detail: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'LicenseeUserRole_User',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          addAsset: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [],
          },
          actions: {
            showTour: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canAssignResolverUser: [],
            canAssignManagerProcessUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignTechnicalUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignManagerTechnicalUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canShowResolverUser: ['BasicClient_LicenseeUserRole_LicenseeAdmin'],
            canShowManagerProcessUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowTechnicalUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canShowManagerTechnicalUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          fastActions: {
            canCancel: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canDuplicate: [
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canStartValidation: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canValidate: [
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canReject: [
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canSendToClient: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canMarkAsDeliveredToClient: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canValidateExpenses: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAdvanceVisit: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          detail: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canShowBtnViewCustomer: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'BasicClient_LicenseeUserRole_LicenseeAdmin',
              ],
            },
            references: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            request: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            showRequestDataBtn: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            requestData: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            customer: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            prescriber: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            orderFeatures: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            relatedAppraisals: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            incomeExpensesProvision: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            incomeExpensesConfirmed: ['BillingModule'],
            exchange: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          information: {
            access: [],
            enable: [],
          },
          documents: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
                'BasicClient_LicenseeUserRole_LicenseeAdmin',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
              ],
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
              ],
              canSetAsPrintable: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
              ],
              canDownload: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'LicenseeUserRole_User',
                'BasicClient_LicenseeUserRole_LicenseeAdmin',
              ],
            },
            appraisalDocTable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
            ],
            appraisalAssetDocTable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          incidents: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'LicenseeUserRole_User',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canResolve: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
                'BasicClient_LicenseeUserRole_LicenseeAdmin',
              ],
            },
          },
          revisions: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
          claims: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canResolve: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          history: {
            access: [
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            records: {
              access: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            shipments: {
              access: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
            communications: {
              access: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          'message-board': {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            actions: {
              canAdd: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canReply: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          invoicing: {
            access: ['BillingModule'],
            enable: ['BillingModule'],
          },
          'appraisal-relationship': {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
            ],
          },
          report: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            list: {
              access: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              actions: {
                canGenerateDraft: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                canImportReport: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                canSetDeliverables: [],
              },
            },
            detail: {
              access: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              enable: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
              addAsset: {
                access: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [],
              },
              commonAssets: {
                access: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                locationAndSurroundings: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canGetAutomaticData: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'market-studies': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canGetAutomaticData: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  detail: {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    actions: {
                      canEdit: [
                        'LicenseeUserRole_Root',
                        'LicenseeUserRole_ProcessManager',
                        'LicenseeUserRole_TechnicalManager',
                        'LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_CommercialManager',
                      ],
                    },
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  list: {
                    access: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    enable: [
                      'LicenseeUserRole_Root',
                      'LicenseeUserRole_ProcessManager',
                      'LicenseeUserRole_TechnicalManager',
                      'LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_CommercialManager',
                    ],
                    actions: {
                      canEdit: [
                        'LicenseeUserRole_Root',
                        'LicenseeUserRole_ProcessManager',
                        'LicenseeUserRole_TechnicalManager',
                        'LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_CommercialManager',
                      ],
                    },
                  },
                },
                terrainFeatures: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                landDescription: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'exploitation-description': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'rustic-estate-features': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                buildingInfo: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                urbanisticSituation: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                urbanisticSituationTerrain: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                },
                checkValidations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                conditionings: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                buildingState: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                valuations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canAdd: [],
                    canEdit: [],
                    canDelete: [],
                    canRecalculate: [],
                  },
                  'comparison-method': {
                    access: [],
                    enable: [],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'cost-method': {
                    access: [],
                    enable: [],
                  },
                  'discount-cashflow-method': {
                    access: [],
                    enable: [],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'contract-cashflow-method': {
                    access: [],
                    enable: [],
                  },
                  'legal-maximum-method': {
                    access: [],
                    enable: [],
                  },
                  'other-method': {
                    access: [],
                    enable: [],
                  },
                  'dynamic-residual-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'static-residual-method': {
                    access: [],
                    enable: [],
                    createWitness: {
                      access: [],
                      enable: [],
                      createExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                    showWitnessList: {
                      access: [],
                      enable: [],
                      showExternSourceWitness: {
                        access: [
                          'Premium_LicenseeUserRole_ProcessManager',
                          'Premium_LicenseeUserRole_TechnicalManager',
                          'Premium_LicenseeUserRole_LicenseeAdmin',
                          'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        ],
                        enable: [],
                      },
                    },
                  },
                  'crawled-method': {
                    access: [],
                    enable: [],
                  },
                  'economic-exploitation-method': {
                    access: [],
                    enable: [],
                  },
                  'rustic-estate-exploitation-method': {
                    access: [],
                    enable: [],
                  },
                },
              },
              assets: {
                access: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                actions: {
                  canMultipleEdit: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canCreateCluster: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canValuateTerrains: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canModifyStructure: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canImportFromRequest: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canMultipleRecalculate: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canAdd: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canDelete: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canDuplicate: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canAddAnnex: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canMakeMainAsset: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  canExtractAsset: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                },
                identification: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                location: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                typology: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                surfaces: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                locationAndSurroundings: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canGetAutomaticData: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                landDescription: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'exploitation-description': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                'rustic-estate-features': {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                buildingInfo: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                propertyCharacteristics: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                terrainFeatures: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                urbanisticSituation: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                urbanisticSituationTerrain: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                tenures: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                checkValidations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                conditionings: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                photos: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canSetAsPrintable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canDelete: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canMakeItMain: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canMultipleSelect: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canAdd: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                documents: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canSetAsPrintable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canDownload: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canAdd: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canDelete: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                appraisalAssetLogChanges: {
                  access: [
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    null,
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  enable: [
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    null,
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  actions: {
                    canEdit: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                buildingState: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
                assetExtraInformation: {
                  access: [
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                  ],
                  enable: [
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                  ],
                  actions: {
                    canEdit: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                    ],
                  },
                },
                valuations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                  actions: {
                    canAdd: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canDelete: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    canRecalculate: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'comparison-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'cost-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'discount-cashflow-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'contract-cashflow-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'legal-maximum-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'other-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'dynamic-residual-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'static-residual-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'crawled-method': {
                    access: [],
                    enable: [],
                  },
                  'economic-exploitation-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'rustic-estate-exploitation-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                },
              },
              clusters: {
                access: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                enable: [
                  'Basic_LicenseeUserRole_ProcessManager',
                  'Basic_LicenseeUserRole_TechnicalManager',
                  'Basic_LicenseeUserRole_LicenseeAdmin',
                  'Comfort_LicenseeUserRole_ProcessManager',
                  'Comfort_LicenseeUserRole_TechnicalManager',
                  'Comfort_LicenseeUserRole_LicenseeAdmin',
                  'Premium_LicenseeUserRole_ProcessManager',
                  'Premium_LicenseeUserRole_TechnicalManager',
                  'Premium_LicenseeUserRole_LicenseeAdmin',
                  'Trial_LicenseeUserRole_ProcessManager',
                  'Trial_LicenseeUserRole_TechnicalManager',
                  'Trial_LicenseeUserRole_LicenseeAdmin',
                  'LicenseeUserRole_SuperAdmin',
                  'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                  'Premium_LicenseeUserRole_ExternalTechnicalManager',
                  'Basic_LicenseeUserRole_ExternalTechnicalManager',
                  'Trial_LicenseeUserRole_ExternalTechnicalManager',
                ],
                actions: {
                  canDelete: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                  ],
                },
                pages: {
                  detail: {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                    actions: {
                      canEdit: [
                        'Basic_LicenseeUserRole_ProcessManager',
                        'Basic_LicenseeUserRole_TechnicalManager',
                        'Basic_LicenseeUserRole_LicenseeAdmin',
                        'Comfort_LicenseeUserRole_ProcessManager',
                        'Comfort_LicenseeUserRole_TechnicalManager',
                        'Comfort_LicenseeUserRole_LicenseeAdmin',
                        'Premium_LicenseeUserRole_ProcessManager',
                        'Premium_LicenseeUserRole_TechnicalManager',
                        'Premium_LicenseeUserRole_LicenseeAdmin',
                        'Trial_LicenseeUserRole_ProcessManager',
                        'Trial_LicenseeUserRole_TechnicalManager',
                        'Trial_LicenseeUserRole_LicenseeAdmin',
                        'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                        'Premium_LicenseeUserRole_ExternalTechnicalManager',
                        'Basic_LicenseeUserRole_ExternalTechnicalManager',
                        'Trial_LicenseeUserRole_ExternalTechnicalManager',
                        'LicenseeUserRole_SuperAdmin',
                      ],
                    },
                  },
                },
                detail: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  actions: {
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                },
                valuations: {
                  access: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  enable: [
                    'Basic_LicenseeUserRole_ProcessManager',
                    'Basic_LicenseeUserRole_TechnicalManager',
                    'Basic_LicenseeUserRole_LicenseeAdmin',
                    'Comfort_LicenseeUserRole_ProcessManager',
                    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                    'Basic_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    'Comfort_LicenseeUserRole_TechnicalManager',
                    'Comfort_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ProcessManager',
                    'Premium_LicenseeUserRole_TechnicalManager',
                    'Premium_LicenseeUserRole_LicenseeAdmin',
                    'Premium_LicenseeUserRole_ExternalTechnicalManager',
                    'Trial_LicenseeUserRole_ProcessManager',
                    'Trial_LicenseeUserRole_TechnicalManager',
                    'Trial_LicenseeUserRole_LicenseeAdmin',
                    'LicenseeUserRole_SuperAdmin',
                  ],
                  actions: {
                    canAdd: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    canEdit: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    canDelete: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    canRecalculate: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'comparison-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'cost-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'discount-cashflow-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'contract-cashflow-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'legal-maximum-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'other-method': {
                    access: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Basic_LicenseeUserRole_ProcessManager',
                      'Basic_LicenseeUserRole_TechnicalManager',
                      'Basic_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'dynamic-residual-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'LicenseeUserRole_SuperAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                    ],
                  },
                  'static-residual-method': {
                    access: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'crawled-method': {
                    access: [],
                    enable: [],
                  },
                  'economic-exploitation-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                  'rustic-estate-exploitation-method': {
                    access: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                    enable: [
                      'Comfort_LicenseeUserRole_ProcessManager',
                      'Comfort_LicenseeUserRole_TechnicalManager',
                      'Comfort_LicenseeUserRole_LicenseeAdmin',
                      'Trial_LicenseeUserRole_ProcessManager',
                      'Trial_LicenseeUserRole_TechnicalManager',
                      'Trial_LicenseeUserRole_LicenseeAdmin',
                      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                      'Basic_LicenseeUserRole_ExternalTechnicalManager',
                      'Trial_LicenseeUserRole_ExternalTechnicalManager',
                      'Premium_LicenseeUserRole_ProcessManager',
                      'Premium_LicenseeUserRole_TechnicalManager',
                      'Premium_LicenseeUserRole_LicenseeAdmin',
                      'Premium_LicenseeUserRole_ExternalTechnicalManager',
                      'LicenseeUserRole_SuperAdmin',
                    ],
                  },
                },
              },
            },
          },
          precedents: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
        },
      },
    },
  },
  financial: {
    access: ['BillingModule'],
    enable: ['BillingModule'],
    'payment-summary': {
      access: ['BillingModule'],
      enable: ['BillingModule'],
      pages: {
        access: ['BillingModule'],
        enable: ['BillingModule'],
        list: {
          access: ['BillingModule'],
          enable: ['BillingModule'],
          actions: {
            canDownloadInvoices: ['BillingModule'],
            canAskForConfirmation: ['BillingModule'],
            canResetInvoices: ['BillingModule'],
          },
        },
        detail: {
          access: ['BillingModule'],
          enable: ['BillingModule'],
          fastActions: {
            canAskForConfirmation: ['BillingModule'],
            canDownloadDraft: ['BillingModule'],
            canResetInvoice: ['BillingModule'],
          },
          detail: {
            access: ['BillingModule'],
            enable: ['BillingModule'],
          },
        },
      },
    },
    'code-management': {
      access: [],
      enable: [],
      pages: {
        access: [],
        enable: [],
        list: {
          access: [],
          enable: [],
          actions: {
            addNewCode: [],
          },
        },
        detail: {
          access: [],
          enable: [],
          actions: {
            edit: [],
          },
          form: {
            access: [],
            enable: [],
          },
        },
        add: {
          access: [],
          enable: [],
        },
      },
    },
  },
  participants: {
    access: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
    ],
    enable: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
    ],
    customers: {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      pages: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        list: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            canAddNewClient: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
        },
        add: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
        },
        detail: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            addProfilePhoto: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          profile: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          'billing-info': {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          appraisals: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          users: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canAddNewUser: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canEditUser: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canEditPassword: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          deliverables: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
        },
      },
    },
    professionals: {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      pages: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        list: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            addNewProfessional: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
        },
        add: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
        },
        detail: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            addProfilePhoto: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          fastActions: {
            change_password: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            addIncompatibilities: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          profile: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          'billing-info': {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
                'Comfort_LicenseeUserRole_ExternalTechnicalManager',
                'Premium_LicenseeUserRole_ExternalTechnicalManager',
                'Basic_LicenseeUserRole_ExternalTechnicalManager',
                'Trial_LicenseeUserRole_ExternalTechnicalManager',
              ],
            },
          },
          collaborator: {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
          appraisals: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          invoices: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          schedule: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          incompatibilities: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          preferences: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canSetDistance: ['LicenseeUserRole_SuperAdmin'],
            },
          },
          documents: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
        },
      },
    },
    'requester-companies': {
      access: [],
      enable: [],
      pages: {
        access: [],
        enable: [],
        list: {
          access: [],
          enable: [],
          actions: {
            canAddNewPrescriptorComapy: [],
          },
        },
        add: {
          access: [],
          enable: [],
        },
        detail: {
          access: [],
          enable: [],
          actions: {
            canEditImage: [],
          },
          profile: {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
          'billing-info': {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
          appraisals: {
            access: [],
            enable: [],
          },
          users: {
            access: [],
            enable: [],
            actions: {
              canAddNewUser: [],
              canEditUsers: [],
              canChangeUsersPassword: [],
            },
          },
          deliverables: {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
          collaborator: {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
        },
      },
    },
  },
  'users-credentials': {
    access: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
    ],
    enable: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
    ],
    'process-manager-user': {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      pages: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        list: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            canAddNewUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
        },
        add: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
        },
        detail: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            canChangeImage: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          fastActions: {
            canChange_password: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignRoles: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAddIncompatibilities: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          profile: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          incompatibilities: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
        },
      },
    },
    'technical-manager-user': {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      pages: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        list: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            canAddNewUser: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
        },
        add: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
        },
        detail: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            canChangeImage: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          fastActions: {
            canChange_password: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAssignRoles: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAddIncompatibilities: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          profile: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          incompatibilities: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
        },
      },
    },
    'commercial-manager-user': {
      access: [],
      enable: [],
      pages: {
        access: [],
        enable: [],
        list: {
          access: [],
          enable: [],
          actions: {
            canAddNewUser: [],
          },
        },
        add: {
          access: [],
          enable: [],
        },
        detail: {
          access: [],
          enable: [],
          actions: {
            canChangeImage: [],
          },
          fastActions: {
            canChange_password: [],
            canAssignRoles: [],
            canAddIncompatibilities: [],
          },
          profile: {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
          incompatibilities: {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
              canDelete: [],
            },
          },
        },
      },
    },
    users: {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      pages: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
        ],
        list: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            canAddNewUser: [
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
        },
        add: {
          access: [
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          showWarningPopup: [
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
        },
        detail: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
          ],
          actions: {
            canChangeImage: [
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          fastActions: {
            canChange_password: [
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            canAddIncompatibilities: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
          },
          profile: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          incompatibilities: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          training: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canSetDistance: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          preferences: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canSetDistance: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          documents: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canDownload: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          collaborator: {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
              canDelete: [],
            },
          },
          appraisals: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          invoices: {
            access: ['BillingModule'],
            enable: ['BillingModule'],
            actions: {
              canEdit: ['BillingModule'],
              canDelete: ['LicenseeUserRole_SuperAdmin'],
            },
          },
          schedule: {
            access: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            enable: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
            ],
            actions: {
              canEdit: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
              canDelete: [
                'Basic_LicenseeUserRole_ProcessManager',
                'Basic_LicenseeUserRole_TechnicalManager',
                'Basic_LicenseeUserRole_LicenseeAdmin',
                'Comfort_LicenseeUserRole_ProcessManager',
                'Comfort_LicenseeUserRole_TechnicalManager',
                'Comfort_LicenseeUserRole_LicenseeAdmin',
                'Premium_LicenseeUserRole_ProcessManager',
                'Premium_LicenseeUserRole_TechnicalManager',
                'Premium_LicenseeUserRole_LicenseeAdmin',
                'Trial_LicenseeUserRole_ProcessManager',
                'Trial_LicenseeUserRole_TechnicalManager',
                'Trial_LicenseeUserRole_LicenseeAdmin',
                'LicenseeUserRole_SuperAdmin',
              ],
            },
          },
          history: {
            access: [],
            enable: [],
          },
          'billing-info': {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
        },
      },
    },
    incompatibilities: {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
      ],
    },
  },
  actives: {
    access: [],
    enable: [],
  },
  wiki: {
    access: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
    ],
    enable: [],
  },
  'global-search': {
    access: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
    ],
    enable: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
    ],
  },
  'my-profile': {
    access: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
    enable: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
    pages: {
      access: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ExternalTechnicalManager',
        'Premium_LicenseeUserRole_ExternalTechnicalManager',
        'Basic_LicenseeUserRole_ExternalTechnicalManager',
        'Trial_LicenseeUserRole_ExternalTechnicalManager',
      ],
      enable: [
        'Basic_LicenseeUserRole_ProcessManager',
        'Basic_LicenseeUserRole_TechnicalManager',
        'Basic_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ProcessManager',
        'Comfort_LicenseeUserRole_TechnicalManager',
        'Comfort_LicenseeUserRole_LicenseeAdmin',
        'Premium_LicenseeUserRole_ProcessManager',
        'Premium_LicenseeUserRole_TechnicalManager',
        'Premium_LicenseeUserRole_LicenseeAdmin',
        'Trial_LicenseeUserRole_ProcessManager',
        'Trial_LicenseeUserRole_TechnicalManager',
        'Trial_LicenseeUserRole_LicenseeAdmin',
        'LicenseeUserRole_SuperAdmin',
        'BasicClient_LicenseeUserRole_LicenseeAdmin',
        'Comfort_LicenseeUserRole_ExternalTechnicalManager',
        'Premium_LicenseeUserRole_ExternalTechnicalManager',
        'Basic_LicenseeUserRole_ExternalTechnicalManager',
        'Trial_LicenseeUserRole_ExternalTechnicalManager',
      ],
      detail: {
        access: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ExternalTechnicalManager',
          'Premium_LicenseeUserRole_ExternalTechnicalManager',
          'Basic_LicenseeUserRole_ExternalTechnicalManager',
          'Trial_LicenseeUserRole_ExternalTechnicalManager',
        ],
        enable: [
          'Basic_LicenseeUserRole_ProcessManager',
          'Basic_LicenseeUserRole_TechnicalManager',
          'Basic_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ProcessManager',
          'Comfort_LicenseeUserRole_TechnicalManager',
          'Comfort_LicenseeUserRole_LicenseeAdmin',
          'Premium_LicenseeUserRole_ProcessManager',
          'Premium_LicenseeUserRole_TechnicalManager',
          'Premium_LicenseeUserRole_LicenseeAdmin',
          'Trial_LicenseeUserRole_ProcessManager',
          'Trial_LicenseeUserRole_TechnicalManager',
          'Trial_LicenseeUserRole_LicenseeAdmin',
          'LicenseeUserRole_SuperAdmin',
          'BasicClient_LicenseeUserRole_LicenseeAdmin',
          'Comfort_LicenseeUserRole_ExternalTechnicalManager',
          'Premium_LicenseeUserRole_ExternalTechnicalManager',
          'Basic_LicenseeUserRole_ExternalTechnicalManager',
          'Trial_LicenseeUserRole_ExternalTechnicalManager',
        ],
        fastActions: {
          canChange_password: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          canAddIncompatibilities: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
        },
        profile: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'BasicClient_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          actions: {
            canEdit: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'BasicClient_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
        },
        incompatibilities: {
          access: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          enable: [
            'Basic_LicenseeUserRole_ProcessManager',
            'Basic_LicenseeUserRole_TechnicalManager',
            'Basic_LicenseeUserRole_LicenseeAdmin',
            'Comfort_LicenseeUserRole_ProcessManager',
            'Comfort_LicenseeUserRole_TechnicalManager',
            'Comfort_LicenseeUserRole_LicenseeAdmin',
            'Premium_LicenseeUserRole_ProcessManager',
            'Premium_LicenseeUserRole_TechnicalManager',
            'Premium_LicenseeUserRole_LicenseeAdmin',
            'Trial_LicenseeUserRole_ProcessManager',
            'Trial_LicenseeUserRole_TechnicalManager',
            'Trial_LicenseeUserRole_LicenseeAdmin',
            'LicenseeUserRole_SuperAdmin',
            'Comfort_LicenseeUserRole_ExternalTechnicalManager',
            'Premium_LicenseeUserRole_ExternalTechnicalManager',
            'Basic_LicenseeUserRole_ExternalTechnicalManager',
            'Trial_LicenseeUserRole_ExternalTechnicalManager',
          ],
          actions: {
            canEdit: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
            canDelete: [
              'Basic_LicenseeUserRole_ProcessManager',
              'Basic_LicenseeUserRole_TechnicalManager',
              'Basic_LicenseeUserRole_LicenseeAdmin',
              'Comfort_LicenseeUserRole_ProcessManager',
              'Comfort_LicenseeUserRole_TechnicalManager',
              'Comfort_LicenseeUserRole_LicenseeAdmin',
              'Premium_LicenseeUserRole_ProcessManager',
              'Premium_LicenseeUserRole_TechnicalManager',
              'Premium_LicenseeUserRole_LicenseeAdmin',
              'Trial_LicenseeUserRole_ProcessManager',
              'Trial_LicenseeUserRole_TechnicalManager',
              'Trial_LicenseeUserRole_LicenseeAdmin',
              'LicenseeUserRole_SuperAdmin',
              'Comfort_LicenseeUserRole_ExternalTechnicalManager',
              'Premium_LicenseeUserRole_ExternalTechnicalManager',
              'Basic_LicenseeUserRole_ExternalTechnicalManager',
              'Trial_LicenseeUserRole_ExternalTechnicalManager',
            ],
          },
        },
        'billing-info': {
          access: [],
          enable: [],
          canGetAutomaticData: {
            access: [],
            enable: [],
          },
        },
      },
    },
  },
  academy: {
    access: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
    enable: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
  },
  'my-work': {
    access: [
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
    ],
    enable: [
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
    ],
  },
  admin: {
    access: ['LicenseeUserRole_SuperAdmin'],
    enable: ['LicenseeUserRole_SuperAdmin'],
    pages: {
      access: ['LicenseeUserRole_SuperAdmin'],
      enable: ['LicenseeUserRole_SuperAdmin'],
      dashboard: {
        access: ['LicenseeUserRole_SuperAdmin'],
        enable: ['LicenseeUserRole_SuperAdmin'],
      },
    },
  },
  ClusterTypes: {
    FinishedElements: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
    Terrains: [
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
    EconomicExploitation: [
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
  },
  AssetUses: {
    Residential: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    Commercial: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    Tertiary: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    Industrial: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    Annexed: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    RusticEstate: [
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    Terrain: [
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
    Dotational: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
      'BasicClient_LicenseeUserRole_LicenseeAdmin',
    ],
  },
  AssetStates: {
    Finished: [
      'Basic_LicenseeUserRole_ProcessManager',
      'Basic_LicenseeUserRole_TechnicalManager',
      'Basic_LicenseeUserRole_LicenseeAdmin',
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
    Unfinished: [
      'Comfort_LicenseeUserRole_ProcessManager',
      'Comfort_LicenseeUserRole_TechnicalManager',
      'Comfort_LicenseeUserRole_LicenseeAdmin',
      'Premium_LicenseeUserRole_ProcessManager',
      'Premium_LicenseeUserRole_TechnicalManager',
      'Premium_LicenseeUserRole_LicenseeAdmin',
      'Trial_LicenseeUserRole_ProcessManager',
      'Trial_LicenseeUserRole_TechnicalManager',
      'Trial_LicenseeUserRole_LicenseeAdmin',
      'LicenseeUserRole_SuperAdmin',
      'LicenseeUserRole_User',
      'Comfort_LicenseeUserRole_ExternalTechnicalManager',
      'Premium_LicenseeUserRole_ExternalTechnicalManager',
      'Basic_LicenseeUserRole_ExternalTechnicalManager',
      'Trial_LicenseeUserRole_ExternalTechnicalManager',
    ],
  },
  isEconomicExploitationCheckbox: [
    'Comfort_LicenseeUserRole_ProcessManager',
    'Comfort_LicenseeUserRole_TechnicalManager',
    'Comfort_LicenseeUserRole_LicenseeAdmin',
    'Premium_LicenseeUserRole_ProcessManager',
    'Premium_LicenseeUserRole_TechnicalManager',
    'Premium_LicenseeUserRole_LicenseeAdmin',
    'Trial_LicenseeUserRole_ProcessManager',
    'Trial_LicenseeUserRole_TechnicalManager',
    'Trial_LicenseeUserRole_LicenseeAdmin',
    'LicenseeUserRole_SuperAdmin',
    'Comfort_LicenseeUserRole_ExternalTechnicalManager',
    'Premium_LicenseeUserRole_ExternalTechnicalManager',
    'Basic_LicenseeUserRole_ExternalTechnicalManager',
    'Trial_LicenseeUserRole_ExternalTechnicalManager',
  ],
};
