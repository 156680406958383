import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CookiesGroupItemModel, CookiesGroupModel } from './cookies-management.types';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookiesManagementService } from './cookies-management.service';

@Component({
  selector: 'ui-cookies-management',
  templateUrl: 'cookies-management.component.html',
  styleUrls: ['cookies-management.component.scss'],
})
export class CookiesManagementComponent {
  public moreInfoPanelIsOpen = false;
  public selectedCookiesGroup: CookiesGroupModel | undefined;
  public form: FormGroup;

  public cookiesConfig: CookiesGroupModel[] = [
    {
      label: 'COOKIES_analyticsCookies',
      icon: 'assets/images/icons/pie_chart.svg',
      headerTitle: 'COOKIES_analyticsCookies',
      headerDescription: 'COOKIES_analyticsCookiesDescription',
      bodyTitle: 'COOKIES_analyticsPurposeCookiesProviders',
      bodyDescription: 'COOKIES_analyticsPurposeCookiesProvidersDescription',
      bodyItems: [
        {
          label: 'Google Analytics',
          controlName: 'analytics',
          bodyText: 'COOKIES_googleAnalyticsDescription',
          linkText: 'COOKIES_urlText',
          link: 'https://policies.google.com/privacy',
          moreInfoIsOpened: false,
        },
        {
          label: 'Hotjar Ltd.',
          controlName: 'hotjar',
          bodyText: 'COOKIES_hotjarDescription',
          linkText: 'COOKIES_urlText',
          link: 'https://www.hotjar.com/legal/policies/privacy/',
          moreInfoIsOpened: false,
        },
      ],
    },
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _translateService: TranslateService, private _cookiesManagementService: CookiesManagementService) {
    this.form = new FormGroup(this._getFormControlsFromCookiesConfig());
  }

  public toggleMoreInfoPanel(selectedCookiesGroup: CookiesGroupModel) {
    this.selectedCookiesGroup = selectedCookiesGroup;
    this.moreInfoPanelIsOpen = !this.moreInfoPanelIsOpen;
  }

  public close() {
    this._cookiesManagementService.updateUser();
    this._cookiesManagementService.closeCookies();
  }

  public toggleMoreInfoProvider(provider: CookiesGroupItemModel) {
    provider.moreInfoIsOpened = !provider.moreInfoIsOpened;
  }

  private _getFormControlsFromCookiesConfig() {
    return this.cookiesConfig.reduce(
      (acc, group) => ({
        ...acc,
        ...group.bodyItems.reduce(
          (acc, item) => ({
            ...acc,
            [item.controlName]: new FormControl(true),
          }),
          {},
        ),
      }),
      {},
    );
  }
}
