import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { Observable } from 'rxjs';
import {
  AppraisalAssetEnvironmentInformationModel,
  AppraisalAssetLogItemIndexModel,
  AppraisalAssetMultiplePhotosModel,
  AppraisalAssetVisitFormSurfacesFacilitiesAndStaysModel,
  AppraisalFileIndexModel,
  AppraisalReportAssetFileIndexModel,
  AssetChangesModel,
  AssetCheckDataModel,
  AssetCheckInformation,
  AssetConditionalsWarningsObservationsEditModel,
  AssetConditionalsWarningsObservationsItemModel,
  AssetDataSourceTypes,
  AssetExploitationDescriptionModel,
  AssetIdentificationDataModel,
  AssetLocalizationInformationModel,
  AssetRusticEstateInformationModel,
  AssetStayAndFacilityInformationModel,
  AssetStayInformationModel,
  AssetSurfacesInformationModel,
  AssetTabModel,
  AssetTenureInformationModel,
  AssetTerrainInformationDTO,
  AssetTypologyAndStateInformationDto,
  AssetTypologyAndStateInformationEditModelBase,
  AssetUrbanisticSituationInformationEditModel,
  AssetUrbanisticSituationModel,
  AssetValidationModel,
  AssetVisitFormBuildingInformationModel,
  AssetVisitFormEnvironmentModel,
  AssetVisitFormSurfacesFacilitiesAndStaysBase,
  AssetWorkPlanStateModel,
  BuildingInformation,
  BuildingTerrainDescriptionDataModel,
  CheckSurfacesForWorkPlanModel,
  CondWarnObserTypes,
  FileTypes,
  FoxeetKeyValueModel,
  FoxeetValidationFilterModel,
  GenericTableData,
  KeyValueModel,
  OrNull,
  WorkflowItemAssetFileModelBase,
} from '@foxeet/domain';
import { isNil } from '../to-delete/bucket';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class AppraisalReportAssetService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalReportAsset', cache);
  }

  duplicate(appraisalAssetId: number, numberOfCopies: number = 1) {
    return this.put<{ numberOfCopies: number }, number[]>(`${appraisalAssetId}/Duplicate`, { numberOfCopies });
  }

  public getCompareChangesWithAsset(workflowItemReportAssetId: number): Observable<AssetChangesModel[]> {
    return this.get<AssetChangesModel[]>(`${workflowItemReportAssetId}/CompareChangesWithAsset`);
  }

  extractAssetFromTree(appraisalAssetId: number) {
    return this.put<null, void>(`${appraisalAssetId}/ExtractAssetFromTree`, null);
  }

  setAssetAsTreeParent(workflowItemAssetId: number) {
    return this.put<null, void>(`${workflowItemAssetId}/AssetAsTreeParent`, null);
  }

  getInitialDetails<T>(id: number): Observable<T> {
    return this.get<T>(`${id}/InitialDetails`);
  }

  // Tabs endpoints

  getDetailsAssetIdentification(assetId: number, dataSource?: AssetDataSourceTypes): Observable<AssetTabModel<AssetIdentificationDataModel>> {
    return this.get<AssetTabModel<AssetIdentificationDataModel>>(`${assetId}/Identification`, { params: this.setParams({ dataSource }) });
  }

  editAssetIdentification(id: number, assetIdentificationModel: AssetTabModel<AssetIdentificationDataModel>): Observable<AssetTabModel<AssetIdentificationDataModel>> {
    return this.put<AssetTabModel<AssetIdentificationDataModel>, AssetTabModel<AssetIdentificationDataModel>>(`${id}/Identification`, assetIdentificationModel);
  }

  getDetailsAssetLocation(assetId: number | null): Observable<AssetTabModel<AssetLocalizationInformationModel>> {
    return this.get<AssetTabModel<AssetLocalizationInformationModel>>(`${assetId}/Localization`);
  }

  downloadFileCadastral(id: number, refCadastral: number) {
    const params: HttpParams = new HttpParams().append('cadastralReference', refCadastral);
    return this.post(`${id}/Identification/DownloadAndCreateCadastralFile`, null, { params });
  }

  editAssetLocalization(
    appraisalAssetId: number,
    assetLocalizationInformationModel: AssetTabModel<AssetLocalizationInformationModel>,
  ): Observable<AssetTabModel<AssetLocalizationInformationModel>> {
    return this.put<AssetTabModel<AssetLocalizationInformationModel>, AssetTabModel<AssetLocalizationInformationModel>>(
      `${appraisalAssetId}/Localization`,
      assetLocalizationInformationModel,
    );
  }

  getDetailsAssetTypology(assetId: number): Observable<AssetTabModel<AssetTypologyAndStateInformationDto>> {
    return this.get<AssetTabModel<AssetTypologyAndStateInformationDto>>(`${assetId}/Typologies`);
  }

  editAssetTypology(assetId: number, assetTypologyInformationModel: AssetTabModel<AssetTypologyAndStateInformationEditModelBase>) {
    const body = { ...assetTypologyInformationModel };
    return this.put<typeof body, AssetTabModel<AssetTypologyAndStateInformationEditModelBase>>(`${assetId}/Typologies`, body);
  }

  getDetailsAssetSurfaces(appraisalAssetId: number): Observable<AssetTabModel<AssetSurfacesInformationModel>> {
    // TODO: it should be called Surfaces BACK CHANGE
    return this.get<AssetTabModel<AssetSurfacesInformationModel>>(`${appraisalAssetId}/Surface`);
  }

  editAssetSurfaces(appraisalAssetId: number, assetSurfacesInformation: AssetTabModel<AssetSurfacesInformationModel>): Observable<AssetTabModel<AssetSurfacesInformationModel>> {
    // TODO: it should be called Surfaces BACK CHANGE
    const body = { ...assetSurfacesInformation };
    return this.put<typeof body, AssetTabModel<AssetSurfacesInformationModel>>(`${appraisalAssetId}/Surface`, body);
  }

  calculateAssetSurfaces(
    appraisalAssetId: number,
    assetSurfacesInformation: AssetTabModel<AssetSurfacesInformationModel>,
  ): Observable<AssetTabModel<AssetSurfacesInformationModel>> {
    // TODO: it should be called Surfaces BACK CHANGE
    const body = { ...assetSurfacesInformation };
    return this.post<typeof body, AssetTabModel<AssetSurfacesInformationModel>>(`${appraisalAssetId}/SurfaceRCalculate`, body);
  }

  getDetailsEnvironmentAndMarketInformation(
    appraisalAssetId: number | null,
    dataSource?: AssetDataSourceTypes,
  ): Observable<AssetTabModel<AppraisalAssetEnvironmentInformationModel>> {
    return this.get<AssetTabModel<AppraisalAssetEnvironmentInformationModel>>(`${appraisalAssetId}/EnvironmentAndMarket`, { params: this.setParams({ dataSource }) });
  }

  editEnvironmentInformation(
    appraisalAssetId: number,
    assetEnvironmentInformation: AssetTabModel<AppraisalAssetEnvironmentInformationModel>,
  ): Observable<AssetTabModel<AppraisalAssetEnvironmentInformationModel>> {
    return this.put<AssetTabModel<AppraisalAssetEnvironmentInformationModel>, AssetTabModel<AppraisalAssetEnvironmentInformationModel>>(
      `${appraisalAssetId}/EnvironmentAndMarket`,
      assetEnvironmentInformation,
    );
  }

  getDetailsBuildingInformation(appraisalAssetId: number, dataSource?: AssetDataSourceTypes): Observable<AssetTabModel<BuildingInformation>> {
    return this.get<AssetTabModel<BuildingInformation>>(`${appraisalAssetId}/BuildingInformation` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  editAssetBuildingInformationModel(appraisalAssetId: number, assetBuildingInformationModel: AssetTabModel<BuildingInformation>): Observable<AssetTabModel<BuildingInformation>> {
    const body = { ...assetBuildingInformationModel };
    return this.put<typeof body, AssetTabModel<BuildingInformation>>(`${appraisalAssetId}/BuildingInformation`, body);
  }

  getDetailsTerrainInformation(id: number, dataSource?: AssetDataSourceTypes) {
    return this.get<AssetTabModel<AssetTerrainInformationDTO>>(`${id}/TerrainInformation` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  editTerrainInformation(body: AssetTabModel<AssetTerrainInformationDTO>): Observable<AssetTabModel<AssetTerrainInformationDTO>> {
    return this.put<typeof body, AssetTabModel<AssetTerrainInformationDTO>>(`${body.id}/TerrainInformation`, body);
  }

  getPropertyCharacteristicsDetail(assetId: string): Observable<AssetTabModel<AssetStayAndFacilityInformationModel>> {
    return this.get<AssetTabModel<AssetStayAndFacilityInformationModel>>(`${assetId}/StayAndFacilities`);
  }

  editPropertyCharacteristicsDetail(
    assetId: number,
    assetStayAndFacility: AssetTabModel<AssetStayAndFacilityInformationModel>,
  ): Observable<AssetTabModel<AssetStayAndFacilityInformationModel>> {
    const body = { ...assetStayAndFacility };
    return this.put<typeof body, AssetTabModel<AssetStayAndFacilityInformationModel>>(`${assetId}/StayAndFacilities`, body);
  }

  getDistribution(appraisalAssetId: number, assetStayInformationModel: AssetTabModel<AssetStayInformationModel>): Observable<string> {
    return this.post(`${appraisalAssetId}/Distribution`, assetStayInformationModel, {
      observe: 'response',
      responseType: 'text',
    });
  }

  getLandDescription(id: number, dataSource?: AssetDataSourceTypes): Observable<AssetTabModel<BuildingTerrainDescriptionDataModel>> {
    return this.get<AssetTabModel<BuildingTerrainDescriptionDataModel>>(`${id}/BuildingTerrainDescription` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  editLandDescription(appraisalAssetId: number, body: AssetTabModel<BuildingTerrainDescriptionDataModel>): Observable<AssetTabModel<BuildingTerrainDescriptionDataModel>> {
    return this.put<typeof body, AssetTabModel<BuildingTerrainDescriptionDataModel>>(`${appraisalAssetId}/BuildingTerrainDescription`, body);
  }

  getDetailsAssetUrbanisticSituation(assetId: number, dataSource?: AssetDataSourceTypes): Observable<AssetTabModel<AssetUrbanisticSituationModel>> {
    return this.get<AssetTabModel<AssetUrbanisticSituationModel>>(`${assetId}/UrbanisticSituation` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  editUrbanisticSituation(
    assetId: number,
    body: AssetTabModel<AssetUrbanisticSituationInformationEditModel>,
  ): Observable<AssetTabModel<AssetUrbanisticSituationInformationEditModel>> {
    return this.put<typeof body, AssetTabModel<AssetUrbanisticSituationInformationEditModel>>(`${assetId}/UrbanisticSituation`, body);
  }

  getDetailsAssetTenure(assetId: number, dataSource: OrNull<AssetDataSourceTypes> = null): Observable<AssetTabModel<AssetTenureInformationModel>> {
    return this.get<AssetTabModel<AssetTenureInformationModel>>(`${assetId}/Tenures`, { params: this.setParams({ dataSource }) });
  }

  editTenures(body: AssetTabModel<AssetTenureInformationModel>): Observable<AssetTabModel<AssetTenureInformationModel>> {
    return this.put<typeof body, AssetTabModel<AssetTenureInformationModel>>(`${body.id}/Tenures`, body);
  }

  public getDetailsAssetCheck(assetId: number, dataSource: AssetDataSourceTypes): Observable<AssetTabModel<AssetCheckInformation[]>> {
    return this.get<AssetTabModel<AssetCheckInformation[]>>(`${assetId}/Checks` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  public editAssetChecks(assetId: number, data: AssetTabModel<AssetCheckDataModel>): Observable<AssetTabModel<AssetCheckDataModel>> {
    return this.put<AssetTabModel<AssetCheckDataModel>, AssetTabModel<AssetCheckDataModel>>(`${assetId}/Checks`, data);
  }

  public getConditionalWarningsObservations(assetId: number, dataSource?: AssetDataSourceTypes): Observable<AssetTabModel<AssetConditionalsWarningsObservationsItemModel>> {
    return this.get<AssetTabModel<AssetConditionalsWarningsObservationsItemModel>>(
      `${assetId}/ConditionalsWarningObservations` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''),
    );
  }

  public editConditionalWarningsObservations(
    assetId: number,
    data: AssetTabModel<AssetConditionalsWarningsObservationsEditModel[]>,
  ): Observable<AssetTabModel<AssetConditionalsWarningsObservationsEditModel[]>> {
    const body = data;
    return this.put<typeof body, AssetTabModel<AssetConditionalsWarningsObservationsEditModel[]>>(`${assetId}/ConditionalsWarningObservations`, body);
  }

  public getItemDescription(type: number, descriptionType: CondWarnObserTypes) {
    return this.get<string>('CondWarnObserDescription', {
      params: this.setParams({
        type,
        descriptionType,
      }),
      responseType: 'text',
    });
  }

  public getAppraisalAssetsLogs(appraisalAssetId: number, page: number, pagesize: number, filter: any) {
    const body = filter;

    return this.post<typeof body, GenericTableData<AppraisalAssetLogItemIndexModel>>(`${appraisalAssetId}/Logs/ListPaged`, body, {
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }

  public getDocumentsById(id: number) {
    return this.get<AppraisalReportAssetFileIndexModel[]>(`${id}/Documents`);
  }

  public getPicturesById(id: number, params: { [p: string]: any }) {
    return this.get<AppraisalFileIndexModel[]>(`${id}/Pictures`, {
      params: this.setParams(params),
    });
  }

  public uploadMultiplePhotos(data: AppraisalAssetMultiplePhotosModel) {
    const body: FormData = new FormData();
    body.append('AssetId', `${data.assetId}`);
    body.append('FileType', `${data.fileType}`);

    data.items.forEach((item, i) => {
      body.append(`Items[${i}].Name`, `${item.name}`);
      body.append(`Items[${i}].Description`, `${item.description}`);
      body.append(`Items[${i}].IsPrintable`, `${isNil(item.isPrintable) ? item.isPrintable : true}`);
      item.filename ? body.append(`Items[${i}].File`, item.file, item.filename) : body.append(`Items[${i}].File`, item.file);
    });

    return this.postMultipartFile(`${data.assetId}/Pictures`, body);
  }

  public uploadPhoto(file: Blob, data: WorkflowItemAssetFileModelBase): Observable<any> {
    const body: FormData = new FormData();
    body.append('AssetId', `${data.assetId !== undefined ? data.assetId : null}`);
    body.append('FileType', `${FileTypes.Pictures}`);
    body.append('Name', `${data.name !== undefined ? data.name : null}`);
    body.append('Description', `${data.description}`);
    body.append('IsPrintable', `${!isNil(data.isPrintable) ? data.isPrintable : true}`);
    data.fileName ? body.append('File', file, data.fileName) : body.append('File', file);

    return this.postMultipartFile(`${data.assetId}/Picture`, body);
  }

  public getWorkPlanStateById(id: number, dataSource?: AssetDataSourceTypes): Observable<AssetTabModel<AssetWorkPlanStateModel>> {
    return this.get<AssetTabModel<AssetWorkPlanStateModel>>(`${id}/WorkPlanState` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  public editWorkPlanState(id: number, data: AssetTabModel<AssetWorkPlanStateModel>): Observable<AssetTabModel<AssetWorkPlanStateModel>> {
    return this.put<typeof data, AssetTabModel<AssetWorkPlanStateModel>>(`${id}/WorkPlanState`, data);
  }

  public calculateWorkPlanState(assetId: number, data: AssetTabModel<AssetWorkPlanStateModel>): Observable<AssetTabModel<AssetWorkPlanStateModel>> {
    return this.post<typeof data, AssetTabModel<AssetWorkPlanStateModel>>(`${assetId}/CalculateWorkPlanState`, data);
  }

  public calculateWorkPlanStateFromForm(assetId: number, data: AssetTabModel<AssetWorkPlanStateModel>): Observable<AssetTabModel<AssetWorkPlanStateModel>> {
    return this.post<typeof data, AssetTabModel<AssetWorkPlanStateModel>>(`${assetId}/CalculateWorkPlanStateFromForm`, data);
  }

  getDetailsAssetExploitationDescription(assetId: number, dataSource?: AssetDataSourceTypes): Observable<AssetTabModel<AssetExploitationDescriptionModel>> {
    return this.get<AssetTabModel<AssetExploitationDescriptionModel>>(`${assetId}/ExploitationDescription` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  editAssetExploitationDescription(
    id: number,
    assetExploitationDescription: AssetTabModel<AssetExploitationDescriptionModel>,
  ): Observable<AssetTabModel<AssetExploitationDescriptionModel>> {
    const body = { ...assetExploitationDescription };
    return this.put<typeof body, AssetTabModel<AssetExploitationDescriptionModel>>(`${id}/ExploitationDescription`, body);
  }

  public calculateExploitationDescription(assetId: number, data: AssetTabModel<AssetExploitationDescriptionModel>): Observable<AssetTabModel<AssetExploitationDescriptionModel>> {
    return this.post<typeof data, AssetTabModel<AssetExploitationDescriptionModel>>(`${assetId}/ExploitationDescriptionCalculate`, data);
  }

  // MOBILE PURPOSES
  // TODO: esto funciona ? sólo existe un put
  public editVisitFormTypologyAndState(
    id: number,
    data: AssetTabModel<AssetTypologyAndStateInformationEditModelBase>,
  ): Observable<AssetTabModel<AssetTypologyAndStateInformationEditModelBase>> {
    return this.put<typeof data, AssetTabModel<AssetTypologyAndStateInformationEditModelBase>>(`${id}/VisitFormTypologyAndState`, data);
  }

  public getVisitAssetSurfacesFacilitiesStays(id: number): Observable<AssetTabModel<AssetVisitFormSurfacesFacilitiesAndStaysBase>> {
    return this.get<AssetTabModel<AssetVisitFormSurfacesFacilitiesAndStaysBase>>(`${id}/VisitFormSurfacesFacilitiesAndStays`);
  }

  // TODO: CAMBIAR FUNCIONAMIENTO PARA USAR EL MODELO DEL GET
  public editVisitAssetSurfacesFacilitiesStays(
    id: number,
    data: AssetTabModel<AppraisalAssetVisitFormSurfacesFacilitiesAndStaysModel>,
  ): Observable<AssetTabModel<AppraisalAssetVisitFormSurfacesFacilitiesAndStaysModel>> {
    return this.put<typeof data, AssetTabModel<AppraisalAssetVisitFormSurfacesFacilitiesAndStaysModel>>(`${id}/VisitFormSurfacesFacilitiesAndStays`, data);
  }

  public getVisitFormBuildingInformation(id: number, datasource: AssetDataSourceTypes): Observable<AssetTabModel<AssetVisitFormBuildingInformationModel>> {
    return this.get<AssetTabModel<AssetVisitFormBuildingInformationModel>>(`${id}/VisitFormBuildingInformation`, {
      params: this.setParams({ datasource: !isNil(datasource) ? `${datasource}` : null }),
    });
  }

  public editVisitFormBuildingInformation(
    id: number,
    data: AssetTabModel<AssetVisitFormBuildingInformationModel>,
  ): Observable<AssetTabModel<AssetVisitFormBuildingInformationModel>> {
    return this.put<typeof data, AssetTabModel<AssetVisitFormBuildingInformationModel>>(`${id}/VisitFormBuildingInformation`, data);
  }

  public getVisitFormEnvironment(id: number, datasource: AssetDataSourceTypes): Observable<AssetTabModel<AssetVisitFormEnvironmentModel>> {
    return this.get(`${id}/VisitFormEnvironment`, { params: this.setParams({ datasource: !isNil(datasource) ? `${datasource}` : null }) });
  }

  public editVisitFormEnvironment(id: number, data: AssetTabModel<AssetVisitFormEnvironmentModel>): Observable<AssetTabModel<AssetVisitFormEnvironmentModel>> {
    return this.put<typeof data, AssetTabModel<AssetVisitFormEnvironmentModel>>(`${id}/VisitFormEnvironment`, data);
  }

  public checkSurfacesForWorkPlan(assetId: number, dataSource: number): Observable<CheckSurfacesForWorkPlanModel> {
    const queryParams = (!isNil(dataSource) && `?dataSource=${dataSource}`) || '';
    return this.get<CheckSurfacesForWorkPlanModel>(`${assetId}/CheckSurfacesForWorkPlan${queryParams}`);
  }

  public isOutOfUsefulLife(assetId: number, queryParams: { [key: string]: any }): Observable<CheckSurfacesForWorkPlanModel> {
    return this.post<null, CheckSurfacesForWorkPlanModel>(`${assetId}/BuildingInformation/IsOutOfUsefulLife`, null, { params: this.setParams(queryParams) });
  }

  public getRusticEstateInformation(assetId: number, dataSource: AssetDataSourceTypes) {
    return this.get<AssetTabModel<AssetRusticEstateInformationModel>>(`${assetId}/RusticEstateInformation` + (typeof dataSource === 'number' ? `?dataSource=${dataSource}` : ''));
  }

  public editRusticEstateInformation(body: AssetTabModel<AssetRusticEstateInformationModel>) {
    return this.put<typeof body, AssetTabModel<AssetRusticEstateInformationModel>>(`${body.id}/RusticEstateInformation`, body);
  }

  public getAssetExtraInformationPaged(page: number, pageSize: number, id: number): Observable<HttpResponse<GenericTableData<FoxeetKeyValueModel>>> {
    return this.http.post<GenericTableData<FoxeetKeyValueModel>>(
      `${this.apiBranch}/${id}/KeyValues/ListPaged`,
      {},
      {
        observe: 'response',
        params: this.setParams({
          page,
          pageSize,
          id,
        }),
      },
    );
  }

  getAssetAllowedKeys(id: number, licenseeId: number | undefined) {
    const url = `${id}/KeyValues/AllowedToCreate`;
    return this.get<KeyValueModel[]>(url, { params: this.setParams({ licenseeId }) });
  }

  addNewDataToAsset(id: number, body: any) {
    const url = `${id}/KeyValues`;
    return this.post<unknown, number>(url, body, { params: this.setParams({ id }) });
  }

  removeDataAsset(assetId: number, keyValueId: number) {
    return this.delete(`${assetId}/KeyValues/${keyValueId}`);
  }

  /**
   * @Todo Circular dependency Filter @foxeet/utils -> @foxeet/data-access -> @foxeet/utils
   * De momento no se usa, si se necesitase habría que pasar el filtro desde fuera o mover Filter a data-access
   */
  // public getFilteredValidations(id: string | number, filter: Filter) {
  //   const { page = 1, size: pagesize = 10, data = {} } = filter?.getCurrentFilter() ?? {};
  //   const body = removeNullsAndUndefined(data);
  //   return this.post<typeof body, AssetValidationModel[]>(`${id}/Validations/ListPaged`, body, {
  //     params: this.setParams({
  //       page,
  //       pagesize,
  //       id,
  //     }),
  //   });
  // }

  public getAllValidations(id: string | number, body?: Partial<FoxeetValidationFilterModel>) {
    return this.post<typeof body, AssetValidationModel[]>(`${id}/Validations/ListAll`, body ?? {});
  }

  public downloadPdfConditional() {
    return this.get<string>('ConditionalWarningObservationsInformationDocument', { observe: 'response', responseType: 'text' });
  }
}
